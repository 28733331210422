import React from 'react';
import LinkedinButton from 'components/LinkedinButton/LinkedinButton';
import styles from './LinkedinVerification.module.scss';

const LinkedinVerification = ({ onSubmit }) => {
  return (
    <div className={styles.linkedinVerification}>
      <img src="assets/images/checkmate_illustration.png" alt="illustration" />
      <p className={styles.title}>Before we start, let’s confirm your identity</p>
      <p className={styles.subtitle}>We offer a convenient option to verify using LinkedIn.</p>
      <p className={styles.note}>*We do not use your information for any other purposes</p>
      <LinkedinButton />
      <a onClick={() => onSubmit('')}>Continue without LinkedIn</a>
    </div>
  );
};

export default LinkedinVerification;
