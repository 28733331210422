import styles from './ResetPassword.module.scss';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from 'components/Loading/Loading';
import { useState, useEffect } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { resetUserPassword } from 'api/user-slice';
import { MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { messagePopUp } from 'api/app-slice';
import { useDispatch } from 'react-redux';
import { Input, InputGroup, Button } from 'components/FormComponents';
import cn from 'classnames';
import { useDocumentTitle } from 'hooks/document-title';

const ResetPassword = ({ invitation }) => {
  useDocumentTitle('Reset Password');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState();

  const [error, setError] = useState();
  const [scorePass, setScorePass] = useState(false);

  const { token } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const watchPassword = useWatch({ control, name: 'password' });
  const watchConfirmPassword = useWatch({ control, name: 'confirm_password' });

  useEffect(() => {
    if (!watchPassword || !watchConfirmPassword) return;
    if (watchPassword !== watchConfirmPassword) setError('Passwords need to match');
    else if (!scorePass) setError('Password is not strong enough');
    else setError(null);
  }, [watchConfirmPassword, watchPassword, scorePass]);

  const onSubmit = async data => {
    if (error) return;
    setLoading(true);
    await dispatch(resetUserPassword({ password: data.password, token, invitation }));
    dispatch(
      messagePopUp({
        text: 'Password successfully updated',
        state: MESSAGE_STATE_SUCCESS,
        hide: true,
      }),
    );
    setLoading(false);
    navigate('/sign_in');
  };

  return (
    <div className={styles.root}>
      <Loading active={loading} />
      <div className={cn('card u-margin-top', 'card-with-shadow', styles.card)}>
        <div className={cn('card_content', styles.content)}>
          <img
            onClick={() => window.location.replace('https://www.checkmate.tech')}
            className={cn(styles.logo, 'u-margin-bottom--large')}
            src="/assets/images/checkmate/icon-label.svg"
            alt="KnowMy Logo"
          />
          <h2 className="design-marker title-2 u-margin-bottom--large">
            Welcome{!invitation && <span> back</span>}!
          </h2>
          <form className="u-width-100" onSubmit={handleSubmit(onSubmit)}>
            <InputGroup title={invitation ? 'Create password' : 'New password'}>
              <Input
                name="password"
                icon="lock"
                type="password"
                placeholder="Enter your password"
                register={register}
                validators={{ required: true }}
                error={errors?.password?.type}
                inputProps={{ tabIndex: 2 }}
              />
              <PasswordStrengthBar
                password={watchPassword}
                onChangeScore={score => {
                  score > 2 ? setScorePass(true) : setScorePass(false);
                }}
              />
            </InputGroup>
            <InputGroup title={invitation ? 'Confirm password' : 'Confirm New password'}>
              <Input
                name="confirm_password"
                icon="lock"
                type="password"
                placeholder="Re-Enter your password"
                register={register}
                validators={{ required: true }}
                error={error}
                inputProps={{ tabIndex: 2 }}
              />
            </InputGroup>
            <Button
              submit
              buttonProps={{ tabIndex: 3 }}
              className="u-width-100 u-margin-top--large">
              Reset Password
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
