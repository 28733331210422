import { getTextWithReferences } from 'helpers/form';
import styles from './CombinedQuestionAnswers.module.scss';
import cn from 'classnames';

export const CombinedQuestionAnswers = ({ order, field, answers, candidateName }) => (
  <div className={styles.root}>
    <div className={cn(styles.row, 'u-flex-box', 'u-flex-justify-start')}>
      <div className={styles.order}>{order}</div>
      <div className={styles.question}>{getTextWithReferences(field?.text, candidateName)}</div>
    </div>
    <div className={styles.answers}>
      {answers.map((answer, index) => (
        <div key={index} className={styles.answer}>
          <b>{answer.name}:</b> {answer.answer}
        </div>
      ))}
    </div>
  </div>
);
