import { RefereeReport } from 'components/RefereeReport/RefereeReport/RefereeReport';
import { CombinedReport } from 'components/RefereeReport/CombinedReport/CombinedReport';
import { Loading } from 'components/Loading/Loading';
import { useLazyGetQuestionUploadByTokenQuery } from 'api/referees-api';
import {
  useLazyGetCandidateByTokenQuery,
  useLazyGetCandidateRefereesByTokenQuery,
  useLazyGetCandidateCompanyByTokenQuery,
} from 'api/candidates-api';
import { useLazyGetLastCheckOfTypeForCandidateByTokenQuery } from 'api/candidate-checks-api';
import {
  useLazyGetTypeformAnswersByTokenQuery,
  useLazyGetFormByTypeformIdByTokenQuery,
} from 'api/forms-api';
import { useEffect, useState } from 'react';
import { useDocumentTitle } from 'hooks/document-title';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyGetReportDataQuery } from 'api/referees-api';
import styles from './ViewReport.module.scss';

const ViewReport = () => {
  useDocumentTitle('Report | Checkmate');

  const navigate = useNavigate();

  const { type, token } = useParams();

  const [getReportData] = useLazyGetReportDataQuery();
  const [getCandidate] = useLazyGetCandidateByTokenQuery();
  const [getCandidateReferees] = useLazyGetCandidateRefereesByTokenQuery();
  const [getCandidateCompany] = useLazyGetCandidateCompanyByTokenQuery();
  const [getTypeformAnswers] = useLazyGetTypeformAnswersByTokenQuery();
  const [getLastCheckOfTypeForCandidate] = useLazyGetLastCheckOfTypeForCandidateByTokenQuery();
  const [getQuestionUpload] = useLazyGetQuestionUploadByTokenQuery();
  const [getFormByTypeformId] = useLazyGetFormByTypeformIdByTokenQuery();

  const [loading, setLoading] = useState(true);
  const [combined, setCombined] = useState(false);

  const [data, setData] = useState();
  const [form, setForm] = useState();
  const [candidate, setCandidate] = useState();
  const [referees, setReferees] = useState([]);
  const [company, setCompany] = useState();
  const [responses, setResponses] = useState();
  const [check, setCheck] = useState();

  useEffect(() => {
    if (type === 'combined') {
      Promise.all([
        getCandidate(token).unwrap(),
        getCandidateReferees(token).unwrap(),
        getCandidateCompany(token).unwrap(),
        getTypeformAnswers(token).unwrap(),
        getLastCheckOfTypeForCandidate({ token, type: 'reference' }).unwrap(),
      ]).then(
        results => {
          setCandidate(results[0]);
          setReferees(results[1]);
          setCompany(results[2]);
          setResponses(results[3]);
          setCheck(results[4]);
          setCombined(true);

          const typeformId = results[4].details?.typeform_id;
          getFormByTypeformId({ token, typeformId })
            .unwrap()
            .then(
              result => {
                setForm(result);
                setLoading(false);
              },
              error => navigate('/not_found_error'),
            );
        },
        error => navigate('/not_found_error'),
      );
    } else {
      getReportData(token)
        .unwrap()
        .then(
          result => {
            setData(result);
            setLoading(false);
          },
          error => navigate('/not_found_error'),
        );
    }
  }, []);

  const downloadQuestionFile = async url => {
    setLoading(true);
    await getQuestionUpload({ token, url });
    setLoading(false);
  };

  return (
    <div className={styles.root}>
      <Loading active={loading}></Loading>
      {!loading && (
        <>
          {combined ? (
            <CombinedReport
              check={check}
              candidate={candidate}
              referees={referees}
              company={company}
              form={form}
              responses={responses}
              isStatic
            />
          ) : (
            <RefereeReport
              company={data.company}
              referee={data.referee}
              candidate={data.candidate}
              form={data.form}
              answers={data.answers}
              downloadQuestionFile={downloadQuestionFile}
              isStatic
            />
          )}
        </>
      )}
    </div>
  );
};

export default ViewReport;
