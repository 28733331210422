import { Input, InputGroup, Button } from 'components/FormComponents';
import { IntegrationCard } from '..';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export const PageUpIntegration = ({
  config,
  integration,
  onCompanyUpdate = () => {},
  setLoading = () => {},
}) => {
  const [enabled, setEnabled] = useState(integration.enabled);

  const { register, handleSubmit } = useForm({ defaultValues: integration });

  const onSave = async data => {
    setLoading(true);
    await onCompanyUpdate(data);
    setLoading(false);
  };

  useEffect(() => {
    if (enabled) return;

    setLoading(true);
    onCompanyUpdate({ page_up_client_id: null }).then(() => {
      setLoading(false);
    });
  }, [enabled]);

  return (
    <IntegrationCard
      {...config}
      subtitle={
        <a
          className="u-link dark"
          target="_blank"
          href="https://www.pageuppeople.com/marketplace/checkmate/">
          Learn more about our PageUp integration
        </a>
      }
      enabled={enabled}
      onToggle={e => setEnabled(e.target.checked)}>
      {enabled && (
        <form className="u-margin-top u-width-100" onSubmit={handleSubmit(onSave)}>
          <InputGroup title="Client Id">
            <Input name="page_up_client_id" register={register} placeholder="Page Up Client ID" />
          </InputGroup>
          <InputGroup title="Client Secret">
            <Input
              name="page_up_client_secret"
              register={register}
              placeholder="Page Up Client Secret"
            />
          </InputGroup>
          <InputGroup title="Instance ID">
            <Input
              name="page_up_instance_id"
              register={register}
              placeholder="Page Up Instance ID"
            />
          </InputGroup>
          <Button type="secondary" submit className="u-width-100 u-margin-top--large">
            Save
          </Button>
        </form>
      )}
    </IntegrationCard>
  );
};
