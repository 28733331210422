import { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import {
  InputGroup,
  Input,
  Select,
  PhoneNumberPicker,
  ToggleSwitchInput,
} from 'components/FormComponents';
import { CustomFields } from 'views/Account/tabs/CustomFields/components/CustomFields/CustomFields';
import cn from 'classnames';

export const CandidateDetailsForm = forwardRef(
  (
    {
      company = {},
      defaultValues = {},
      teams = [],
      customFields = [],
      className,
      users,
      onSubmit = () => {},
      setInvalid = () => {},
    },
    ref,
  ) => {
    const {
      register,
      watch,
      control,
      setValue,
      handleSubmit,
      formState: { errors },
    } = useForm({ defaultValues, shouldUnregister: true });

    const [ccNotificationEmailsEnabled, setCcNotificationEmailsEnabled] = useState(false);

    const team_id = useWatch({ control, name: 'team_id' });

    // Nullify cc emails if toggled off
    useEffect(() => {
      if (!ccNotificationEmailsEnabled) setValue('cc_notification_emails', '');
    }, [ccNotificationEmailsEnabled]);

    // Set validation
    useEffect(() => {
      setInvalid(Object.keys(errors).length > 0);
    }, [errors]);

    // Expose functions
    useImperativeHandle(ref, () => ({
      submit: () => {
        handleSubmit(onSubmit)();
      },
    }));

    return (
      <form className={cn({ [className]: className })}>
        <div className={cn('card', 'card-with-border', 'u-padding', 'u-margin-bottom')}>
          <div className="inline u-margin-bottom">
            <InputGroup title="First Name *">
              <Input
                name="first_name"
                placeholder="e.g. Brad"
                register={register}
                validators={{ required: true }}
                error={errors?.first_name?.type}
                inputProps={{ tabIndex: 1 }}
              />
            </InputGroup>
            <InputGroup title="Last Name *">
              <Input
                name="last_name"
                placeholder="e.g. Pitt"
                register={register}
                validators={{ required: true }}
                error={errors?.last_name?.type}
                inputProps={{ tabIndex: 2 }}
              />
            </InputGroup>
          </div>
          <InputGroup title="Email Address *" className="u-margin-bottom--large">
            <Input
              name="email"
              placeholder="e.g. brad@checkmate.tech"
              register={register}
              validators={{ required: true }}
              error={errors?.email?.type}
              inputProps={{ tabIndex: 3 }}
            />
          </InputGroup>
          <InputGroup title="Contact Number" className="u-margin-bottom--large">
            <Controller
              control={control}
              name="contact_number"
              render={({ field: { onChange, value } }) => (
                <PhoneNumberPicker
                  value={value}
                  onChange={onChange}
                  tabIndex={4}
                  defaultCountry={company?.country}
                />
              )}
            />
          </InputGroup>
          <InputGroup title="Job Title" className="u-margin-bottom--large">
            <Input
              name="job_role"
              placeholder="Mastermind"
              register={register}
              inputProps={{ tabIndex: 5 }}
            />
          </InputGroup>
          <InputGroup title="Department" className="u-margin-bottom--large">
            <Input
              name="team_name"
              placeholder="Oceans 11"
              register={register}
              inputProps={{ tabIndex: 6 }}
            />
          </InputGroup>
          <InputGroup title="Reference Code" className="u-margin-bottom--large">
            <Input
              name="reference_code"
              placeholder="REFCODE12"
              register={register}
              inputProps={{ tabIndex: 7 }}
            />
          </InputGroup>
          {teams.length > 1 && (
            <InputGroup title="Team" className="u-margin-bottom--large">
              <Select
                name="team_id"
                placeholder="Team"
                register={register}
                value={team_id}
                useDefault>
                {teams.map(team => (
                  <Select.Item key={team.id} value={team.id}>
                    {team.name}
                  </Select.Item>
                ))}
              </Select>
            </InputGroup>
          )}
        </div>
        <div className={cn('card', 'card-with-border', 'u-padding', 'u-margin-bottom')}>
          <div className="u-width-100">
            <div className={cn('u-bold')}>Candidate Assignment</div>
            <InputGroup title="The user who is assigned to manage the candidate and receive notifications. This can be updated at any time.">
              <Select
                name="user_id"
                placeholder="User"
                register={register}
                value={watch('user_id')}
                useDefault
                useSearch>
                {users.map(user => (
                  <Select.Item key={user.id} value={user.id}>
                    {user.first_name} {user.last_name} • {user.email}
                  </Select.Item>
                ))}
              </Select>
            </InputGroup>
          </div>
        </div>
        {customFields.length > 0 && (
          <div className={cn('card', 'card-with-border', 'u-padding', 'u-margin-bottom')}>
            <div className="u-width-100">
              <CustomFields
                fields={customFields}
                register={register}
                control={control}
                watch={watch}
              />
            </div>
          </div>
        )}
        <ToggleSwitchInput
          text="Notification List"
          subtext="Add external emails to get notified about status updates"
          onChange={e => setCcNotificationEmailsEnabled(e.target.checked)}
          value={JSON.parse(ccNotificationEmailsEnabled)}>
          <InputGroup title="Emails">
            <Input
              name="cc_notification_emails"
              placeholder="email_1@example.com,email_2@example.com"
              register={register}
            />
          </InputGroup>
        </ToggleSwitchInput>
      </form>
    );
  },
);
