import styles from './ActionsMenu.module.scss';
import { useState, createContext, useContext } from 'react';
import { IconButton } from 'components/IconButton/IconButton';
import { useClickOutside } from 'hooks/click-outside';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import { useRef } from 'react';

const ActionsMenuContext = createContext(null);

const useActionsMenuContext = () => {
  const context = useContext(ActionsMenuContext);
  if (!context)
    throw new Error(
      `ActionsMenu compound components cannot be rendered outside the ActionsMenu component`,
    );
  return context;
};

export const ActionsMenu = ({ children, className, small = false }) => {
  const ref = useRef(null);

  const [opened, setOpened] = useState(false);

  useClickOutside(ref, () => setOpened(false));

  return (
    <ActionsMenuContext.Provider value={{ setOpened }}>
      <div
        ref={ref}
        className={cn(styles.root, 'position-relative', 'd-flex', {
          [styles.rootOpened]: opened,
          [className]: className,
        })}>
        <IconButton
          small={small}
          icon="more-vertical"
          className="u-margin-left"
          onClick={() => setOpened(!opened)}
        />
        <ul className={cn(styles.menu, 'card', 'card-with-shadow', 'card-with-border')}>
          {children}
        </ul>
      </div>
    </ActionsMenuContext.Provider>
  );
};

const Item = ({ icon, title, disabled = false, onClick = () => {} }) => {
  const { setOpened } = useActionsMenuContext();

  const handleClick = () => {
    if (disabled) return;
    onClick();
    setOpened(false);
  };

  return (
    <li className={cn(styles.item, { [styles.itemDisabled]: disabled })} onClick={handleClick}>
      <FeatherIcon className="u-margin-right--small" size={15} icon={icon} /> {title}
    </li>
  );
};

ActionsMenu.Item = Item;
