import { useState, useEffect } from 'react';
import { QuestionAnswerVerificationRow } from '../QuestionAnswerVerificationRow/QuestionAnswerVerificationRow';
import { QuestionAnswerRow } from '../QuestionAnswerRow/QuestionAnswerRow';
import { QuestionAnswerSkillRow } from '../QuestionAnswerSkillRow/QuestionAnswerSkillRow';
import styles from './QuestionsAnswers.module.scss';

export const QuestionsAnswers = ({
  form,
  candidate,
  referee,
  answers,
  downloadQuestionFile = () => {},
}) => {
  const [validationQuestions, setValidationQuestions] = useState([]);
  const [performanceQuestions, setPerformanceQuestions] = useState([]);
  const [skillsQuestions, setSkillsQuestions] = useState([]);
  const [complianceQuestions, setComplianceQuestions] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]);

  const getLogicParams = () => {
    let ignoreQuestions = [];
    let topLinkQuestions = [];
    const logicJumps = form?.logic_jumps;

    if (logicJumps.length > 0) {
      const expression_jumps = logicJumps.filter(logicJump => logicJump.operator !== 'always');
      const expression_else = logicJumps.filter(logicJump => logicJump.operator === 'always');
      expression_jumps.forEach(expression_jump => {
        let true_expression = false;
        switch (expression_jump.operator) {
          case 'is':
            true_expression = expression_jump.value === answers[expression_jump.field];
            break;
          case 'lower_than':
            true_expression = answers[expression_jump.field] < expression_jump.value;
            break;
          case 'greater_equal_than':
            true_expression = answers[expression_jump.field] >= expression_jump.value;
            break;
          default:
            break;
        }
        let else_order = expression_else.find(
          logic_jump => logic_jump.from === expression_jump.from,
        );
        if (true_expression) {
          for (let i = expression_jump.from; i < else_order.to - 1; i++) {
            topLinkQuestions.push(i);
          }
          for (let i = expression_jump.to; i < else_order.to; i++) {
            // In case there are multiple conditions for the same question
            if (ignoreQuestions.includes(i)) {
              let indexToRemove = ignoreQuestions.indexOf(i);
              ignoreQuestions.splice(indexToRemove, 1);
            }
          }
        } else {
          for (let i = expression_jump.from + 1; i < else_order.to; i++) {
            ignoreQuestions.push(i);
          }
        }
      });
    }
    return {
      ignoreQuestions,
      topLinkQuestions,
    };
  };

  useEffect(() => {
    if (!form || !referee || !answers) return;

    // VALIDATION
    const extraQuestion = form?.type === 'type:intelligent' && answers[5] === 'Asked to leave';
    let validationQuestions = [];
    validationQuestions.push(
      <QuestionAnswerVerificationRow
        key={1}
        text="Where did you work together?"
        number={1}
        candidateAnswer={referee.company_name}
        refereeAnswer={answers[1]}
        candidateName={candidate.first_name}
      />,
    );
    validationQuestions.push(
      <QuestionAnswerVerificationRow
        key={2}
        text="What was your relationship?"
        number={2}
        candidateAnswer={referee.role}
        refereeAnswer={answers[2]}
        candidateName={candidate.first_name}
      />,
    );
    validationQuestions.push(
      <QuestionAnswerVerificationRow
        key={3}
        text="How long did you work together?"
        number={3}
        candidateAnswer={referee.working_period}
        refereeAnswer={answers[3]}
        candidateName={candidate.first_name}
      />,
    );
    validationQuestions.push(
      <QuestionAnswerVerificationRow
        key={4}
        text="What was {{candidate}}'s role?"
        number={4}
        candidateAnswer={referee.candidate_role}
        refereeAnswer={answers[4]}
        candidateName={candidate.first_name}
      />,
    );
    validationQuestions.push(
      <QuestionAnswerVerificationRow
        key={5}
        text="Why did {{candidate}} finish their employment?"
        number={5}
        candidateAnswer={referee.reason_for_leaving}
        refereeAnswer={answers[5]}
        logicChild={extraQuestion}
        candidateName={candidate.first_name}
      />,
    );

    let order = 5;

    if (extraQuestion) {
      order += 1;
      validationQuestions.push(
        <QuestionAnswerVerificationRow
          key={6}
          text="Could you please explain why was asked to leave?"
          number={6}
          candidateAnswer=""
          refereeAnswer={answers[6]}
          candidateName={candidate.first_name}
        />,
      );
    }
    setValidationQuestions(validationQuestions);

    const logicParams = getLogicParams();

    // PERFORMANCE
    const performanceFields = form?.fields.filter(field => field.check_part === 'PERFORMANCE');
    const performanceRows = performanceFields.map(field => {
      if (!logicParams.ignoreQuestions.includes(field.order)) {
        order++;
        return (
          <QuestionAnswerRow
            candidateName={candidate.first_name}
            key={field.order}
            order={order}
            field={field}
            answer={answers[field.order]}
            answers={answers}
            logicChild={logicParams.topLinkQuestions.includes(field.order)}
            downloadQuestionFile={downloadQuestionFile}
          />
        );
      }
    });
    setPerformanceQuestions(performanceRows);

    // SKILLS
    const skillsFields = form?.fields.filter(field => field.check_part === 'SKILL');
    const skillsRows = skillsFields.map(field => {
      order++;
      return (
        <QuestionAnswerSkillRow
          key={field.order}
          order={order}
          field={field}
          answer={answers[field.order]}
        />
      );
    });
    setSkillsQuestions(skillsRows);

    // COMPLIANCE
    const complianceFields = form?.fields.filter(field => field.check_part === 'COMPLIANCE');
    const complianceRows = complianceFields.map(field => {
      if (!logicParams.ignoreQuestions.includes(field.order)) {
        order++;
        return (
          <QuestionAnswerRow
            candidate={candidate}
            key={field.order}
            order={order}
            field={field}
            answer={answers[field.order]}
            answers={answers}
            logicChild={logicParams.topLinkQuestions.includes(field.order)}
            downloadQuestionFile={downloadQuestionFile}
          />
        );
      }
    });
    setComplianceQuestions(complianceRows);

    // CUSTOM
    const customFields = form?.fields.filter(field => field.check_part === 'CUSTOM');
    const customRows = customFields.map(field => {
      if (!logicParams.ignoreQuestions.includes(field.order)) {
        order++;
        return (
          <QuestionAnswerRow
            candidate={candidate}
            key={field.order}
            order={order}
            field={field}
            answer={answers[field.order]}
            answers={answers}
            logicChild={logicParams.topLinkQuestions.includes(field.order)}
            downloadQuestionFile={downloadQuestionFile}
          />
        );
      }
    });
    setCustomQuestions(customRows);
  }, [form, referee, answers]);

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <QuestionAnswerVerificationRow
          text="verification questions"
          candidateAnswer="candidate answers"
          refereeAnswer="referee answers"
          title
          candidateName={candidate.first_name}
        />
        {validationQuestions}
      </div>
      {performanceQuestions.length > 0 && (
        <div className={styles.section}>
          <QuestionAnswerRow title="job performance" />
          {performanceQuestions}
        </div>
      )}
      {skillsQuestions.length > 0 && (
        <div className={styles.section}>
          <QuestionAnswerRow title="skills" />
          {skillsQuestions}
        </div>
      )}
      {complianceQuestions.length > 0 && (
        <div className={styles.section}>
          <QuestionAnswerRow title="compliance" />
          {complianceQuestions}
        </div>
      )}
      {customQuestions.length > 0 && (
        <div className={styles.section}>
          <QuestionAnswerRow title="custom" />
          {customQuestions}
        </div>
      )}
    </div>
  );
};
