import { getTextWithReferences } from 'helpers/form';
import styles from './CombinedQuestionAnswerVerificationRow.module.scss';
import cn from 'classnames';

export const CombinedQuestionAnswerVerificationRow = ({
  question,
  header,
  order,
  answers,
  candidateName,
  noCandidateRow,
}) => (
  <div className={cn(styles.root, { [styles.header]: header })}>
    {!header && (
      <div className={cn(styles.question, 'u-flex-box', 'u-flex-justify-start', 'u-width-50')}>
        {order && <div className={styles.order}>{order}</div>}
        <div className={styles.text}>{getTextWithReferences(question, candidateName)}</div>
      </div>
    )}
    <div className={styles.answersRows}>
      {!noCandidateRow && (
        <div className={cn(styles.candidate, 'u-flex-box')}>
          <div className={cn(styles.title, 'u-width-50')}>Candidate Answer:</div>
          <div className={cn(styles.answersColumns, 'u-width-50')}>
            {answers.map((answer, index) => (
              <div key={index}>{answer.candidate}</div>
            ))}
          </div>
        </div>
      )}
      <div className={cn(styles.referees, 'u-flex-box', 'u-flex-justify-end')}>
        <div className={cn(styles.title, 'u-width-50')}>
          {header ? 'Verification Questions' : 'Referee Answer:'}
        </div>
        <div className={cn(styles.answersColumns, 'u-width-50')}>
          {answers.map((answer, index) => (
            <div key={index}>{answer.referee}</div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
