import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  FilePicker,
  DatePicker,
  InputGroup,
  ToggleSwitch,
} from 'components/FormComponents';
import { useApiUploadActions } from 'hooks/upload-actions';
import { DynamicTypeInput } from 'components/Form/DynamicTypeInput/DynamicTypeInput';
import { now } from 'helpers/date';
import { useEffect } from 'react';

export const CandidateCompletionModal = ({
  check,
  checkType,
  visible,
  onUpdate = () => {},
  onClose = () => {},
}) => {
  const { reset, handleSubmit, control, register, watch } = useForm();

  const { getUpload, signUpload, createUpload, deleteUpload } = useApiUploadActions();

  useEffect(
    () =>
      reset({
        upload_id: check.upload_id,
        result_flag: check.result_flag,
        extra_data: check.extra_data,
        expiration_date: check.expiration_date ? check.expiration_date : '9999-12-31',
      }),
    [check],
  );

  const onSubmit = data => {
    if (!check.completed_at) data.completed_at = now();
    onUpdate(data);
  };

  return (
    <AnimatedModal visible={visible} onClose={onClose} title={`Complete ${checkType?.title}`}>
      {visible && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup title="Result file" className="u-margin-top--large">
            <Controller
              control={control}
              name="upload_id"
              render={({ field: { onChange, value } }) => (
                <FilePicker
                  onChange={onChange}
                  value={value}
                  metadata={{ result: check.type }}
                  accept={{ 'application/pdf': ['.pdf'] }}
                  modelParams={{
                    candidate_id: check.candidate_id,
                    result: true,
                    candidate_check_id: check.id,
                  }}
                  bucketFolder="background_result"
                  getUpload={getUpload}
                  signUpload={signUpload}
                  createUpload={createUpload}
                  deleteUpload={deleteUpload}
                />
              )}
            />
          </InputGroup>

          {checkType?.has_result_flag && (
            <ToggleSwitch
              name="result_flag"
              register={register}
              className="u-margin-bottom"
              label="Has result"
            />
          )}

          {checkType?.has_expiration_date && (
            <InputGroup title="Expiration date">
              <Controller
                control={control}
                name="expiration_date"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    outputFormat="YYYY-MM-DD"
                    useDefaultToday={false}
                  />
                )}
              />
            </InputGroup>
          )}

          {checkType?.extra_result_details?.map(extraDetail => (
            <DynamicTypeInput
              key={extraDetail.code}
              title={extraDetail.title}
              fieldName={`extra_data.${extraDetail.code}`}
              fieldOptions={extraDetail.options}
              type={extraDetail.type}
              control={control}
              register={register}
              watch={watch}
            />
          ))}

          <Button submit type="success" large className="u-width-100 u-margin-top--large">
            {check.completed_at ? 'Update' : 'Complete'}
          </Button>
        </form>
      )}
    </AnimatedModal>
  );
};
