import FeatherIcon from 'feather-icons-react';
import styles from './StepSelector.module.scss';
import cn from 'classnames';

export const StepSelector = ({
  activeStep,
  invalid,
  onStep1Selection = () => {},
  onStep2Selection = () => {},
  className,
}) => (
  <div
    className={cn(styles.root, styles[`root--active${activeStep}`], {
      [styles['root--invalid']]: invalid,
      [className]: className,
    })}>
    <div className={styles.step1} onClick={onStep1Selection}>
      <p>Step 1</p>
      {invalid && <FeatherIcon icon="alert-circle" />}
      {activeStep === 2 && <FeatherIcon icon="check-circle" />}
    </div>
    <div className={styles.step2} onClick={onStep2Selection}>
      <p>Step 2</p>
    </div>
  </div>
);
