import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Form } from 'components/Form/Form/Form';

export const FormPreviewModal = ({ form, visible, onClose = () => {} }) => {
  return (
    <AnimatedModal
      visible={visible}
      onClose={onClose}
      title={form.name}
      className="u-margin-y--large"
      small>
      {visible && <Form form={form} buttonTitle="Close" onSubmit={onClose} />}
    </AnimatedModal>
  );
};
