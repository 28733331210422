import { useEffect, useState } from 'react';
import { List } from 'components/List/List';
import { useForm, useWatch } from 'react-hook-form';
import styles from './RenewalsConfigurationTab.module.scss';
import { Select, InputGroup, Input, Button, ToggleSwitch } from 'components/FormComponents';
import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import cn from 'classnames';

export const RenewalsConfigurationTab = ({
  companyChecks,
  setLoading = () => {},
  updateCompanyCheck = () => {},
}) => {
  const [companyCheck, setCompanyCheck] = useState();

  return (
    <div className={styles.root}>
      <List
        title="Checks"
        subtitle="Configure renewals for your checks"
        className="u-margin-right--large card--small">
        <List.Category title="Checks">
          {companyChecks.map(companyCheck => (
            <List.Item
              key={companyCheck.id}
              title={companyCheck.check_type.title}
              value={companyCheck}
              onClick={setCompanyCheck}
              icon={companyCheck.renewal_enabled ? 'check' : false}
              iconClass={styles.itemIcon}
            />
          ))}
        </List.Category>
      </List>
      {companyCheck && (
        <RenewalCheckConfig
          companyCheck={companyCheck}
          setLoading={setLoading}
          updateCompanyCheck={updateCompanyCheck}
          onClose={() => setCompanyCheck(null)}
        />
      )}
    </div>
  );
};

const RenewalCheckConfig = ({
  companyCheck,
  setLoading = () => {},
  updateCompanyCheck = () => {},
  onClose = () => {},
}) => {
  const { register, reset, control, handleSubmit } = useForm();

  const enabled = useWatch({ control, name: 'renewal_enabled' });
  const period = useWatch({ control, name: 'period' });
  const useExpirationForRenewal = useWatch({ control, name: 'use_expiration_for_renewal' });

  useEffect(() => {
    let period, number;

    if (companyCheck.renewal_period > 364) {
      period = 'Years';
      number = companyCheck.renewal_period / 365;
    } else {
      period = 'Months';
      number = companyCheck.renewal_period / 30;
    }

    reset({
      renewal_enabled: companyCheck.renewal_enabled,
      period: period,
      number: number,
      use_expiration_for_renewal: companyCheck.use_expiration_for_renewal,
    });
  }, [companyCheck]);

  const onSubmit = async data => {
    setLoading(true);
    const renewal_period = data.period === 'Years' ? data.number * 365 : data.number * 30;
    await updateCompanyCheck(companyCheck.id, {
      renewal_period,
      renewal_enabled: data.renewal_enabled,
      use_expiration_for_renewal: data.use_expiration_for_renewal,
    });
    setLoading(false);
  };

  return (
    <form
      className={cn('card', 'card-with-border', 'card--small', 'u-width-50')}
      onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className={cn('u-padding--large', 'u-divider-bottom-border')}>
          <h1 className="title-2">{companyCheck.check_type.title}</h1>
          <p className="t-small">Configure this check renewal</p>
        </div>
        <div className="background-secondary u-padding">
          <div className={cn('card', 'card-with-border', 'u-margin-x', 'u-padding')}>
            <EditableSettings
              title={`Enable ${companyCheck.check_type.title}'s renewals`}
              open={enabled}
              toggleable
              register={register}
              name="renewal_enabled"
              className="u-margin-0">
              {companyCheck.check_type.has_expiration_date && (
                <div className="u-margin-bottom">
                  <InputGroup title="Dynamic period">
                    <ToggleSwitch
                      register={register}
                      name="use_expiration_for_renewal"
                      tooltip='This check allows dynamic expiration dates. If "Use expiration date" is enabled, we will use it for renewing your checks.'
                      label="Use expiration date"
                    />
                  </InputGroup>
                </div>
              )}
              {!useExpirationForRenewal && (
                <div className={cn('d-flex', 'justify-content-between')}>
                  <InputGroup title={period} className="u-margin-right u-margin-bottom--0">
                    <Input name="number" type="number" register={register} />
                  </InputGroup>
                  <InputGroup title="Period" className="u-margin-left u-margin-bottom--0">
                    <Select name="period" register={register} value={period} useDefault>
                      <Select.Item value="Months">Months</Select.Item>
                      <Select.Item value="Years">Years</Select.Item>
                    </Select>
                  </InputGroup>
                </div>
              )}
            </EditableSettings>
          </div>
        </div>
      </div>
      <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
        <Button onClick={onClose} type="secondary">
          Cancel
        </Button>
        <Button submit>Save</Button>
      </div>
    </form>
  );
};
