import PasswordStrengthBar from 'react-password-strength-bar';
import { MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { InputGroup, Input, Button, FormMessage } from 'components/FormComponents';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useUpdateUserPasswordMutation } from 'api/user-api';

export const ChangePassword = ({ user, setLoading = () => {}, messagePopUp = () => {} }) => {
  const [updatePassword] = useUpdateUserPasswordMutation();

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const watchPassword = useWatch({ control, name: 'new_password' });

  const [error, setError] = useState();
  const [scorePass, setScorePass] = useState(false);

  const handleUpdate = async data => {
    if (!scorePass) {
      setError('Password is not strong enough');
      return;
    }

    if (data.new_password !== data.new_password_confirm) {
      setError("Passwords don't match");
      return;
    }

    setError();
    setLoading(true);
    const { data: response } = await updatePassword({
      id: user.id,
      current_password: data.current_password,
      new_password: data.new_password,
    });
    if (response?.error) setError(response.error.message);
    else {
      messagePopUp('Password updated', MESSAGE_STATE_SUCCESS);
      reset();
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <FormMessage message={error} className="u-margin-top--0" onClose={() => setError(null)} />
      <InputGroup title="Current Password">
        <Input
          name="current_password"
          type="password"
          placeholder="Enter password"
          register={register}
          validators={{ required: true }}
          error={errors.current_password?.type}
        />
      </InputGroup>
      <div className="u-divider " />
      <InputGroup title="New Password">
        <Input
          name="new_password"
          type="password"
          placeholder="Enter password"
          register={register}
          validators={{ required: true }}
          error={errors.new_password?.type}
        />
        <PasswordStrengthBar
          password={watchPassword}
          onChangeScore={score => {
            score > 2 ? setScorePass(true) : setScorePass(false);
          }}
        />
      </InputGroup>
      <InputGroup title="Confirm New Password">
        <Input
          name="new_password_confirm"
          type="password"
          placeholder="Enter password"
          register={register}
          validators={{ required: true }}
          error={errors.new_password_confirm?.type}
        />
      </InputGroup>
      <Button className="u-width-100 u-margin-top--large" submit>
        Update Password
      </Button>
    </form>
  );
};
