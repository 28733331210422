import { createThunk } from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { revokePartnerToken } from './partner-token-version-slice';
import { PartnersEnum } from 'constants/partners';

const initialState = {
  tas: {},
  job_adder: {},
  jazzhr: {},
  teamtailor: {},
  bullhorn: {},
  onboarded: {},
  lever: {},
  ukg: {},
  account_api: {},
  knowmy: {},
  bambooHR: {},
  pageup: {},
  livehire: {},

  fetched: false,
};

export const getPartnersConfig = createThunk('getPartnersConfig', 'partners/config', 'get');

export const connectLever = createThunk('connectLever', 'partners/lever', 'post');

export const disconnectLever = createThunk('disconnectLever', 'partners/lever', 'delete');

export const connectJobAdder = createThunk('connectJobAdder', 'partners/jobadder', 'post');

export const disconnectJobAdder = createThunk('disconnectJobAdder', 'partners/jobadder', 'delete');

export const connectBullhorn = createThunk('connectBullhorn', 'partners/bullhorn', 'post');

export const disconnectBullhorn = createThunk('disconnectBullhorn', 'partners/bullhorn', 'delete');

export const connectLivehire = createThunk('connectLivehire', 'partners/livehire', 'post');

export const disconnectLivehire = createThunk('disconnectLivehire', 'partners/livehire', 'delete');

const parnerSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPartnersConfig.fulfilled, (_, { payload }) => {
        return { ...payload, fetched: true };
      })
      .addCase(revokePartnerToken.fulfilled, (state, { payload }) => {
        state[PartnersEnum[payload.partner]].api_key = payload.token;
      })
      .addCase(connectLever.fulfilled, state => {
        state.lever.enabled = true;
      })
      .addCase(disconnectLever.fulfilled, state => {
        state.lever.enabled = false;
      })
      .addCase(connectLivehire.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(disconnectLivehire.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(connectJobAdder.fulfilled, state => {
        state.partners.job_adder.enabled = true;
      })
      .addCase(disconnectJobAdder.fulfilled, state => {
        state.partners.job_adder.enabled = false;
      })
      .addCase(connectBullhorn.fulfilled, state => {
        state.partners.bullhorn.enabled = true;
      })
      .addCase(disconnectBullhorn.fulfilled, state => {
        state.partners.bullhorn.enabled = false;
      });
  },
});

export const selectPartners = state => state.partners;

export default parnerSlice.reducer;
