import { useEffect, useState } from 'react';
import { Button, Input, InputGroup } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import {
  useGetApplicationQuery,
  useGetRefereesQuery,
  useLazyGetRefereePdfQuery,
  useSendRefereesReportsByTokenMutation,
  useLazyGetCandidateCheckResultQuery,
} from 'api/partner-api';
import { Loading } from 'components/Loading/Loading';
import { Modal } from 'components/Modal/Modal';
import styles from './AssessmentResults.module.scss';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

export const AssessmentResults = () => {
  const { token } = useParams();

  const { data: application, isLoading: isLoadingApplication } = useGetApplicationQuery(token);
  const { data: referees } = useGetRefereesQuery(token);

  const [getRefereePdf] = useLazyGetRefereePdfQuery();
  const [sendRefereesReports] = useSendRefereesReportsByTokenMutation();
  const [getCandidateCheckResult] = useLazyGetCandidateCheckResultQuery();

  const [loading, setLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState('');
  const [sendRefereeId, setSendRefereeId] = useState(0);
  const [sentResult, setSentResult] = useState('');
  const [selectedReferees, setSelectedReferees] = useState([]);
  const [candidate, setCandidate] = useState({});

  const [checks, setChecks] = useState([]);
  const [companyChecks, setCompanyChecks] = useState([]);

  useEffect(() => {
    if (!application) return;

    setCompanyChecks(application.company_checks);
    setCandidate(application.candidate);
    setChecks(application.checks);
  }, [application]);

  const sendReports = async () => {
    // Return if email empty
    if (email?.length < 1) {
      setSentResult('error');
      return;
    }

    // Add referees to be sent
    let refereesToSend = [];
    if (sendRefereeId === 0)
      selectedReferees.forEach((value, id) => {
        if (value) refereesToSend.push(id);
      });
    else refereesToSend.push(sendRefereeId);

    // Send
    setLoading(true);
    setSentResult('');
    try {
      await sendRefereesReports({ token, email, referees: refereesToSend });
      setSentResult('succeed');
    } catch (error) {
      setSentResult('error');
    }
    setLoading(false);
  };

  const downloadReferenceResult = async refereeId => {
    setLoading(true);
    await getRefereePdf({ refereeId, token }).unwrap();
    setLoading(false);
  };

  const downloadCheckResult = async check => {
    setLoading(true);
    await getCandidateCheckResult({ token, candidate, check }).unwrap();
    setLoading(false);
  };

  return (
    <div className={styles.root}>
      <Loading active={loading || isLoadingApplication} />
      <Modal
        show={showEmailModal}
        onClose={() => {
          setShowEmailModal(false);
          setSentResult('');
        }}>
        <div className={styles.emailModal}>
          <InputGroup title="Email Address">
            <Input
              inputProps={{ value: email, onChange: e => setEmail(e.target.value) }}
              placeholder="brad@checkmate.tech"
              className={cn('u-width-100', 'u-margin-bottom')}
            />
          </InputGroup>
          {sentResult === 'succeed' ? (
            <p className={cn(styles.resultMsj, styles.resultMsjSucceed)}>Sent.</p>
          ) : null}
          {sentResult === 'error' ? (
            <p className={cn(styles.resultMsj, styles.resultMsjError)}>
              Error. Make sure the email is a valid one.
            </p>
          ) : null}
          <Button className={styles.button} onClick={sendReports}>
            Send
          </Button>
        </div>
      </Modal>

      <h3>{candidate.name} Checks</h3>
      {checks.map(check => {
        const checkType = companyChecks.find(
          companyCheck => companyCheck.type === check.type,
        )?.check_type;
        if (!checkType) return;

        return (
          <CheckDetails
            key={check.id}
            candidate={candidate}
            check={check}
            checkType={checkType}
            referees={check.type === 'reference' ? referees : null}
            downloadReferenceResult={downloadReferenceResult}
            downloadCheckResult={downloadCheckResult}
            sendByEmail={refereeId => {
              setShowEmailModal(true);
              setSendRefereeId(refereeId);
            }}
          />
        );
      })}
    </div>
  );
};

export default AssessmentResults;

const CheckDetails = ({
  candidate,
  check,
  checkType,
  referees,
  downloadCheckResult = () => {},
  downloadReferenceResult = () => {},
  sendByEmail = () => {},
}) => {
  return (
    <div className={styles.checkDetails}>
      <div className={styles.checkDetails_Content}>
        <div className="u-flex-box u-flex-align-center">
          <img src={checkType.logo} className={styles.checkDetails_Logo} />
          <div className={styles.checkDetails_Title}>
            <div className={styles.checkDetails_TitleName}>{checkType.title}</div>
            <div className={`${styles.checkDetails_TitleStatus} u-txt-status-${check.status}`}>
              {check.status}
            </div>
          </div>
        </div>
        <div className="u-margin-right u-flex-box u-flex-align-center">
          {check.type === 'reference' && check.status !== 'completed' && (
            <IconButton
              className={styles.checkDetails_ActionButton}
              tip="Add referee"
              icon="plus"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_WEB_URL}/check/${candidate.id}/referees`,
                  '_blank',
                )
              }
            />
          )}

          <IconButton
            className={styles.checkDetails_ActionButton}
            tip="Download result"
            icon="download"
            disabled={!check.completed_at}
            onClick={() => downloadCheckResult(check)}
          />

          <IconButton
            className={styles.checkDetails_ActionButton}
            tip="View on Checkmate"
            icon="log-in"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_WEB_URL}/check/${candidate.id}/${checkType.candidate_tab}`,
                '_blank',
              )
            }
          />
        </div>
      </div>
      {referees?.length > 0 && (
        <div className={styles.checkDetails_RefereesWrapper}>
          {referees.map(referee => (
            <div
              className={cn(styles.checkDetails_Referee, {
                [styles.checkDetails_RefereeIncomplete]: !referee.completed,
              })}
              key={referee.id}>
              <div className={styles.checkDetails_Title}>
                {referee.name}
                {referee.completed && (
                  <img
                    src="assets/images/icons/check.svg"
                    alt="completed"
                    className={styles.checkDetails_CheckIcon}
                  />
                )}
              </div>
              <div className={styles.actions}>
                <IconButton
                  className={styles.checkDetails_ActionButton}
                  tip="Send result by mail"
                  icon="send"
                  disabled={!referee.completed}
                  onClick={() => sendByEmail(referee.id)}
                />
                <IconButton
                  className={styles.checkDetails_ActionButton}
                  tip="Download result"
                  icon="download"
                  disabled={!referee.completed}
                  onClick={() => downloadReferenceResult(referee.id)}
                />
                <IconButton
                  className={styles.checkDetails_ActionButton}
                  tip="Open in checkmate"
                  icon="log-in"
                  disabled={!referee.completed}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_WEB_URL}/company/report/referee/${referee.token}`,
                      '_blank',
                    )
                  }
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
