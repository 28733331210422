import { useEffect } from 'react';
import { Error } from './Error';
import ReactGA from 'react-ga';
import { useDocumentTitle } from 'hooks/document-title';

const Error400 = () => {
  useDocumentTitle('Bad Request | Checkmate');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return <Error>400</Error>;
};

export default Error400;
