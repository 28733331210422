import { useEffect, useMemo, useState } from 'react';

export const useLocale = locale => {
  return useMemo(() => {
    switch (locale) {
      case 'en_GB':
        return { dateSlashFormat: 'DD/MM/YYYY', dateHyphenFormat: 'dd-MM-yyyy' };
      case 'en_US':
        return { dateSlashFormat: 'MM/DD/YYYY', dateHyphenFormat: 'MM-dd-yyyy' };
      default:
        return { dateSlashFormat: 'DD/MM/YYYY', dateHyphenFormat: 'dd-MM-yyyy' };
    }
  }, [locale]);
};
