import React, { useEffect, useState } from 'react';
import { useQueryParams } from 'hooks/query-params';
import {
  useCancelCandidateCheckMutation,
  useAddCandidateChecksMutation,
  useLazyGetCandidateCheckResultQuery,
  useGetCandidateQuery,
  useLazyGetSubCheckResultQuery,
  useGetFormsQuery,
  useGetCompanyChecksQuery,
  useUpdateCandidateCheckMutation,
} from 'api/partner-v1-api';
import { Loading } from 'components/Loading/Loading';
import { ChecksDetails } from '../component/ChecksDetails/ChecksDetails';
import { useParams } from 'react-router-dom';

export const AssessmentResult = () => {
  const { token } = useParams();

  const [bullhornCandidateId] = useQueryParams(['EntityID']);

  const [loading, setLoading] = useState(false);
  const [candidate, setCandidate] = useState({});

  const [getCandidateCheckResult] = useLazyGetCandidateCheckResultQuery();
  const [getSubCheckResult] = useLazyGetSubCheckResultQuery();
  const [addCandidateChecks] = useAddCandidateChecksMutation();
  const [cancelCandidateCheck] = useCancelCandidateCheckMutation();
  const [updateCandidateCheck] = useUpdateCandidateCheckMutation();

  const {
    data: candidateData,
    isFetching: loadingCandidate,
    refetch,
  } = useGetCandidateQuery({ token, applicationId: bullhornCandidateId });
  const { data: forms, isLoading: loadingForms } = useGetFormsQuery(token);
  const { data: companyChecks, isLoading: loadingCompanyChecks } = useGetCompanyChecksQuery(token);
  const { data: company, isLoading: loadingCopmpany } = useGetCompanyQuery(token);

  useEffect(() => {
    setCandidate(candidateData);
  }, [candidateData]);

  if (loading || loadingCandidate || loadingForms || loadingCopmpany || loadingCompanyChecks)
    return <Loading active={true} />;

  return (
    <ChecksDetails
      company={company}
      candidate={candidate}
      token={token}
      companyChecks={companyChecks}
      forms={forms}
      reload={refetch}
      setLoading={setLoading}
      setCandidate={setCandidate}
      updateCandidateCheck={updateCandidateCheck}
      getCandidateCheckResult={getCandidateCheckResult}
      getSubCheckResult={getSubCheckResult}
      addCandidateChecks={addCandidateChecks}
      cancelCandidateCheck={cancelCandidateCheck}
    />
  );
};
