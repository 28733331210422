import { useEffect, useState } from 'react';
import { CustomButton } from '../components/CustomButton/CustomButton';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { shouldSubmitCandidate } from 'utils/checks-utils';
import { Dialog } from 'components/Dialog/Dialog';
import styles from './CandidateBackyPortal.module.scss';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCandidateApplication,
  submitCandidateApplication,
  updateBackyCheck,
} from 'api/candidate-application-slice';
import { useUserAgent } from 'hooks/user-agent';

export const CandidateBackyPortal = () => {
  const dispatch = useDispatch();

  const { user_agent } = useUserAgent();
  const { token } = useParams();

  const {
    application: { candidate_checks, brand, check_types },
    fetched,
  } = useSelector(selectCandidateApplication);

  const [check, setCheck] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!fetched) return;

    setCheck(candidate_checks.find(check => check.type === 'afp_backy'));
  }, [fetched]);

  const verifyAndReturn = async () => {
    setLoading(true);
    await dispatch(updateBackyCheck({ token, id: check.id }));
    if (shouldSubmitCandidate(check, candidate_checks, check_types)) {
      await dispatch(submitCandidateApplication({ token, user_agent }));
      setLoading(false);
      navigate(`/form_submission/candidate/${CandidateCheckScreens.SUCCESS}/${token}`);
    } else {
      setLoading(false);
      navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
    }
  };

  return (
    <CandidatePage loading={loading} token={token} title="Australian Federal Police Check">
      <CandidatePage.View>
        <Dialog header="Here are your Backy Check credentials.">
          <Dialog.Message>
            <p>User: {check?.details.username}</p>
            <p>Pass: {check?.details.password}</p>
          </Dialog.Message>
        </Dialog>

        <div className={styles.root}>
          <div
            className={cn(
              styles.frameWrapper,
              'card',
              'card-with-border',
              'card-with-hidden-overflow',
            )}>
            <iframe src="https://portal.backycheck.com" />
          </div>
          <CustomButton brand={brand} onClick={verifyAndReturn}>
            Submit
          </CustomButton>
        </div>
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CandidateBackyPortal;
