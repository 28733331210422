import { LoginSocialGoogle } from 'reactjs-social-login';
import styles from './GoogleButton.module.scss';

export const GoogleButton = ({ onLoginSuccess = () => {}, onLoginFailure = () => {} }) => {
  return (
    <LoginSocialGoogle
      className={styles.root}
      client_id="31598861663-kakuv7trragnqicvd2m2c6qo821n3694.apps.googleusercontent.com"
      onResolve={onLoginSuccess}
      onReject={onLoginFailure}>
      <img src="assets/images/icons/google.png" className={styles.logo} alt="google" /> Log in with
      Google
    </LoginSocialGoogle>
  );
};
