import AppPage from 'layouts/AppPage/AppPage';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDocumentTitle } from 'hooks/document-title';
import { UsersTab } from './tabs/UsersTab/UsersTab';
import { RolesTab } from './tabs/RolesTab/RolesTab';
import { TeamsTab } from './tabs/TeamsTab/TeamsTab';
import { Permissions } from 'constants/permissions';
import {
  selectCompany,
  updateCompanyOwner,
  createSSOOrganization,
  disableSSOOrganization,
} from 'api/company-slice';
import { useLazyGetCompanyUsersQuery } from 'api/company-api';
import { getTeams, selectTeams, createTeam, updateTeam } from 'api/teams-slice';
import { getRoles, updateRole, deleteRole, createRole, selectRoles } from 'api/roles-slice';
import { useDeleteUserMutation } from 'api/user-api';
import { messagePopUp } from 'api/app-slice';

const Users = () => {
  useDocumentTitle('Users');

  const dispatch = useDispatch();

  const company = useSelector(selectCompany);
  const teams = useSelector(selectTeams);
  const roles = useSelector(selectRoles);

  const [getCompanyUsers] = useLazyGetCompanyUsersQuery();
  const [deleteUser] = useDeleteUserMutation();

  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!company.id) return;

    Promise.all([
      getCompanyUsers({ id: company.id, params: { records: 1000 } }).unwrap(),
      dispatch(getRoles()),
      dispatch(getTeams()),
    ]).then(response => {
      setUsers(response[0].result.users);
      setLoading(false);
    });
  }, [company]);

  return (
    <AppPage loading={loading} requiredPermissions={[Permissions.CompanyWrite]}>
      <div>
        <AppPage.Header
          title="User Access"
          subtitle="Below you can view a list of your current users."
        />
        <Tabs defaultActiveKey="users" className="tabs" mountOnEnter={true}>
          <Tab eventKey="users" title="Users">
            {company.id && (
              <UsersTab
                company={company}
                roles={roles}
                teams={teams}
                getCompanyUsers={getCompanyUsers}
                messagePopUp={params => dispatch(messagePopUp(params))}
                setLoading={setLoading}
                deleteUser={deleteUser}
                updateCompanyOwner={params => dispatch(updateCompanyOwner(params))}
                createSSOOrganization={params => dispatch(createSSOOrganization(params))}
                disableSSOOrganization={() => dispatch(disableSSOOrganization())}
              />
            )}
          </Tab>
          <Tab eventKey="roles" title="Roles">
            <RolesTab
              users={users}
              roles={roles}
              updateRole={(id, data) => dispatch(updateRole({ id, data }))}
              deleteRole={id => dispatch(deleteRole(id))}
              createRole={data => dispatch(createRole(data))}
              setLoading={setLoading}
            />
          </Tab>
          <Tab eventKey="teams" title="Teams">
            <TeamsTab
              users={users}
              teams={teams}
              createTeam={id => dispatch(createTeam(id))}
              updateTeam={(id, data) => dispatch(updateTeam({ id, data }))}
              setLoading={setLoading}
            />
          </Tab>
        </Tabs>
      </div>
    </AppPage>
  );
};

export default Users;
