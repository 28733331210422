import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messagePopUp } from 'api/app-slice';
import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { useBulkImportMutation } from 'api/candidates-api';
import { getForms } from 'api/forms-slice';
import AppPage from 'layouts/AppPage/AppPage';
import { useCSVReader } from 'react-papaparse';
import { ChecksSelector } from 'components/ChecksSelector/ChecksSelector';
import styles from './BulkImport.module.scss';
import { Button } from 'components/FormComponents';
import { CsvTableDetails } from 'components/CsvTableDetails/CsvTableDetails';
import { useDocumentTitle } from 'hooks/document-title';
import { Permissions } from 'constants/permissions';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { selectForms } from 'api/forms-slice';
import { selectCompany } from 'api/company-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { getBrands, selectBrands } from 'api/brands-slice';
import { getBundles, selectBundles } from 'api/bundles-slice';
import { Select, InputGroup } from 'components/FormComponents';

export const BulkImport = () => {
  useDocumentTitle('Bulk Import');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { type } = useParams();

  const forms = useSelector(selectForms);
  const company = useSelector(selectCompany);
  const companyChecks = useSelector(selectCompanyChecks);
  const brands = useSelector(selectBrands);
  const bundles = useSelector(selectBundles);
  const [bulkImport] = useBulkImportMutation();

  const selectorRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const [csvData, setCsvData] = useState();
  const [zoneHover, setZoneHover] = useState(false);

  const [valid, setValid] = useState(false);

  const { CSVReader } = useCSVReader();

  // Initialization
  useEffect(() => {
    Promise.all([dispatch(getBrands()), dispatch(getForms()), dispatch(getBundles())]).then(_ =>
      setLoading(false),
    );
  }, []);

  const handleSubmit = async checksData => {
    setLoading(true);
    let data = {
      csv_data: csvData,
      checks: checksData.checks,
      candidate_data: checksData.candidate,
      renewal: type == 'renewals',
    };

    const { data: response } = await bulkImport(data);

    setLoading(false);
    if (response.error) {
      dispatch(
        messagePopUp({ text: response.error.message, state: MESSAGE_STATE_ERROR, hide: true }),
      );
    } else {
      dispatch(
        messagePopUp({
          text: `${response.result} candidates processed`,
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
      navigate('/dashboard');
    }
  };

  const onUploadAccepted = results => {
    setCsvData(results.data);
    setZoneHover(false);
  };

  const onDragOver = event => {
    event.preventDefault();
    setZoneHover(true);
  };

  const onDragLeave = event => {
    event.preventDefault();
    setZoneHover(false);
  };

  return (
    <AppPage loading={loading} requiredPermissions={[Permissions.CandidateWrite]}>
      <div className={styles.root}>
        <AppPage.Header
          title={`${type === 'renewals' ? 'Renewals' : 'Candidates'} Import`}
          subtitle="Use a spreadsheet to upload multiple candidates at once. Get started by downloading the
                    template spreadsheet below. Once the candidate details are 
                    imported you can choose the check types you need"
          className="u-divider-bottom-border"
        />
        <div className={styles.body}>
          <div className={cn(styles.content, 'card', 'card-with-border', 'u-margin-y--large')}>
            <div className={cn('card_content')}>
              <div className={cn(styles.dataPicker, 'u-width-100', 'u-margin-bottom--large')}>
                {csvData ? (
                  <CsvTableDetails csvData={csvData} onDrop={() => setCsvData(null)} />
                ) : (
                  <div className={cn(styles.container, 'u-width-100')}>
                    <CSVReader
                      onUploadAccepted={onUploadAccepted}
                      onDragOver={onDragOver}
                      onDragLeave={onDragLeave}>
                      {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                        getRemoveFileProps,
                        Remove,
                      }) => (
                        <>
                          <div
                            {...getRootProps()}
                            style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}>
                            {acceptedFile ? (
                              <div style={styles.file}>
                                <div style={styles.info}>
                                  <span style={styles.size}>{acceptedFile.size}</span>
                                  <span style={styles.name}>{acceptedFile.name}</span>
                                </div>
                                <div style={styles.progressBar}>
                                  <ProgressBar />
                                </div>
                                <div {...getRemoveFileProps()} style={styles.remove}>
                                  <Remove color="red" />
                                </div>
                              </div>
                            ) : (
                              <div className={styles.dropzone}>
                                Drop CSV file here or click to upload.
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </CSVReader>
                    <a
                      className={cn('u-link', 'dark', styles.downloadLink)}
                      href={`https://checkmate-prod.s3.ap-southeast-2.amazonaws.com/assets/${type === 'renewals' ? 'renewals_bulk_import.csv' : 'candidates_bulk_import.csv'}`}>
                      Download template
                    </a>
                  </div>
                )}
              </div>
              <ChecksSelector
                ref={selectorRef}
                forms={forms}
                brands={brands}
                bundles={bundles}
                company={company}
                companyChecks={companyChecks}
                setValidity={setValid}
                onSubmit={handleSubmit}
                bulk={true}
              />
              <Button
                className="u-width-100"
                onClick={() => selectorRef.current.submit()}
                disabled={!valid || !csvData}>
                Import
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AppPage>
  );
};

export default BulkImport;
