import PropTypes from 'prop-types';
import styles from './FormMessage.module.scss';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';

export const MessageType = {
  Success: 'Success',
  Error: 'Error',
  Info: 'Info',
};

export const FormMessage = ({
  message = '',
  type = MessageType.Error,
  className,
  onClose = () => {},
}) => {
  if (!message) return null;

  return (
    <div className={cn(styles.root, styles[`root${type}`], { [className]: className })}>
      {type === MessageType.Error && (
        <FeatherIcon icon="alert-triangle" className={styles.statusIcon} />
      )}
      {type === MessageType.Success && <FeatherIcon icon="check" className={styles.statusIcon} />}
      {type === MessageType.Info && (
        <FeatherIcon icon="alert-circle" className={styles.statusIcon} />
      )}
      <p className={styles.message}>{message}</p>
      <FeatherIcon icon="x" className={styles.closeIcon} onClick={onClose} />
    </div>
  );
};

FormMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf([MessageType.Success, MessageType.Error, MessageType.Info]),
  className: PropTypes.string,
  onClose: PropTypes.func,
};
