import styles from './FormThanksCard.module.scss';

export const FormThanksCard = ({ candidate }) => (
  <div className={styles.root}>
    <div className={styles.top}>
      <img src="assets/images/icons/check.svg" alt="check" />
      <div className={styles.text}>
        {candidate ? (
          <>
            Thank you, your <br />
            information was <br />
            submittted successfully
          </>
        ) : (
          <>
            Thank you, your <br />
            reference has been <br />
            submitted successfully
          </>
        )}
      </div>
    </div>
    <div className={styles.bottom}>
      <div>Powered by</div>
      <img src="assets/images/checkmate/icon-label.svg" alt="checkmate" />
    </div>
  </div>
);
