import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import cookie from 'react-cookies';
import { saveAs } from 'file-saver';
import { stringDate } from 'helpers/date';

export const candidateChecksApi = createApi({
  reducerPath: 'candidateChecksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/candidate_checks`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getChecks: builder.query({
      query: ({ type, params }) => `/types/${type}?${toQueryParams(params)}`,
    }),
    getAllCandidateChecks: builder.query({
      query: params => `/admin?${toQueryParams(params)}`,
    }),
    updateCandidateCheck: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: ({ result }) => result,
    }),
    createCandidateCheck: builder.mutation({
      query: ({ candidateId, data }) => ({
        url: `/candidate/${candidateId}/`,
        method: 'POST',
        body: data,
      }),
      transformResponse: ({ result }) => result,
    }),
    cancelCandidateCheck: builder.mutation({
      query: id => ({
        url: `/${id}/cancel`,
        method: 'PUT',
      }),
      transformResponse: ({ result }) => result,
    }),
    restoreCandidateCheck: builder.mutation({
      query: id => ({
        url: `/${id}/restore`,
        method: 'PUT',
      }),
      transformResponse: ({ result }) => result,
    }),
    regenerateSubChecks: builder.mutation({
      query: id => ({
        url: `/${id}/sub_checks/regenerate`,
        method: 'POST',
      }),
      transformResponse: ({ result }) => result,
    }),
    getProviderForm: builder.query({
      queryFn: async ({ check, candidate }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/${check.id}/provider/form`,
          method: 'GET',
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, `${candidate.name}_${check.type}_form`);
        return { data: null };
      },
    }),
    getCandidateCheckResult: builder.query({
      queryFn: async ({ check, candidate, isFile = true }, api, extraOptions, baseQuery) => {
        const { data: result } = await baseQuery({
          url: `/${check.id}/result`,
          method: 'GET',
          responseHandler: async response => {
            if (isFile) {
              const blob = await response.blob();
              return blob;
            } else {
              const jsonResponse = await response.json();
              return jsonResponse.result;
            }
          },
          cache: 'no-cache',
        });
        if (isFile) saveAs(result, `${candidate.name}_${check.type}`);
        else window.open(result, '_blank');

        return { data: null };
      },
    }),
    candidateCheckViewResult: builder.mutation({
      query: ({ id }) => ({
        url: `/${id}/result/view`,
        method: 'GET',
      }),
      transformResponse: ({ result }) => result,
    }),
    getChecskWidgetCount: builder.query({
      query: () => `/widget/counts`,
    }),
    sendToProvider: builder.mutation({
      query: id => ({
        url: `/${id}/provider`,
        method: 'POST',
      }),
      transformResponse: ({ result }) => result,
    }),
    getRenewals: builder.query({
      query: ({ type, params }) => `/types/${type}/renewals?${toQueryParams(params)}`,
      transformResponse: ({ result }) => result,
    }),
    renewCandidateCheck: builder.mutation({
      query: id => ({
        url: `/${id}/renew`,
        method: 'POST',
      }),
      transformResponse: ({ result }) => result,
    }),
    bulkUpdateCandidateChecks: builder.mutation({
      query: data => ({
        url: '/bulk',
        method: 'PUT',
        body: data,
      }),
      transformResponse: ({ result }) => result,
    }),
    bulkRenewCandidateChecks: builder.mutation({
      query: ids => ({
        url: '/bulk/renew',
        method: 'POST',
        body: { ids },
      }),
      transformResponse: ({ result }) => result,
    }),
    getLastCheckOfTypeForCandidateByToken: builder.query({
      query: ({ token, type }) => `/token/${token}/types/${type}`,
      transformResponse: ({ result }) => result,
    }),
    getAnswers: builder.query({
      query: ({ id }) => `/${id}/answers`,
    }),
  }),
});

export const {
  useLazyGetLastCheckOfTypeForCandidateByTokenQuery,
  useLazyGetAllCandidateChecksQuery,
  useLazyGetCandidateCheckResultQuery,
  useCandidateCheckViewResultMutation,
  useCreateCandidateCheckMutation,
  useCancelCandidateCheckMutation,
  useRestoreCandidateCheckMutation,
  useUpdateCandidateCheckMutation,
  useSendToProviderMutation,
  useLazyGetChecksQuery,
  useGetChecskWidgetCountQuery,
  useLazyGetProviderFormQuery,
  useRegenerateSubChecksMutation,
  useLazyGetRenewalsQuery,
  useGetAnswersQuery,
  useRenewCandidateCheckMutation,
  useBulkUpdateCandidateChecksMutation,
  useBulkRenewCandidateChecksMutation,
} = candidateChecksApi;
