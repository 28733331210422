import { Button } from 'components/FormComponents';
import styles from './CustomButton.module.scss';
import cn from 'classnames';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { useBrandingStyleVariables } from 'hooks/branding-style-variables';

export const CustomButton = ({
  completed,
  blockOnComplete,
  brand,
  type = 'primary',
  icon,
  className,
  disabled,
  children,
  submit,
  small = false,
  onClick = () => {},
}) => {
  const bradingStyleVariables = useBrandingStyleVariables(brand);

  return (
    <Button
      type={type}
      className={cn('u-margin-top', styles.root, styles[`root--${type}`], {
        [className]: className,
        [styles.rootCompleted]: completed,
        [styles.small]: small,
      })}
      style={bradingStyleVariables}
      disabled={disabled}
      onClick={() => {
        if (!blockOnComplete || !completed) onClick();
      }}
      icon={icon}
      submit={submit}>
      {completed && (
        <FeatherIcon
          style={{ backgroundColor: brand.color_button }}
          icon="check"
          className={styles.completedIcon}
        />
      )}
      {children}
    </Button>
  );
};
