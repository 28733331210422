import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import { saveAs } from 'file-saver';
import cookie from 'react-cookies';

export const reportingApi = createApi({
  reducerPath: 'reportingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/reporting`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getCandidateChecksReporting: builder.query({
      query: params => `/candidate_checks?${toQueryParams(params)}`,
      transformResponse: response => response.result,
    }),
    getCandidateChecksFilters: builder.query({
      query: () => `/candidate_checks/filters`,
      transformResponse: response => response.result,
    }),
    getCandidateChecksStats: builder.query({
      query: params => `/candidate_checks/stats?${toQueryParams(params)}`,
      transformResponse: response => response.result,
    }),
    downloadCandidateChecksReport: builder.query({
      queryFn: async ({ queryParams }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/candidate_checks/download?${toQueryParams(queryParams)}`,
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, 'checks_reporting.csv');
        return { data: null };
      },
    }),
    getCandidatesFilters: builder.query({
      query: () => `/candidates/filters`,
      transformResponse: response => response.result,
    }),
    getCandidatesStats: builder.query({
      query: params => `/candidates/stats?${toQueryParams(params)}`,
      transformResponse: response => response.result,
    }),
    getCandidatesReporting: builder.query({
      query: params => `/candidates?${toQueryParams(params)}`,
      transformResponse: response => response.result,
    }),
    downloadCandidatesReport: builder.query({
      queryFn: async ({ queryParams }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/candidates/download?${toQueryParams(queryParams)}`,
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, 'candidates_reporting.csv');
        return { data: null };
      },
    }),

    getRefereesFilters: builder.query({
      query: () => `/referees/filters`,
      transformResponse: response => response.result,
    }),
    getRefereesStats: builder.query({
      query: params => `/referees/stats?${toQueryParams(params)}`,
      transformResponse: response => response.result,
    }),
    getRefereesReporting: builder.query({
      query: params => `/referees?${toQueryParams(params)}`,
      transformResponse: response => response.result,
    }),
    downloadRefereesReport: builder.query({
      queryFn: async ({ queryParams }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/referees/download?${toQueryParams(queryParams)}`,
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, 'referees_reporting.csv');
        return { data: null };
      },
    }),
  }),
});

export const {
  useLazyGetCandidateChecksReportingQuery,
  useGetCandidateChecksFiltersQuery,
  useLazyGetCandidateChecksStatsQuery,
  useLazyDownloadCandidateChecksReportQuery,
  useGetCandidatesFiltersQuery,
  useLazyGetCandidatesStatsQuery,
  useLazyGetCandidatesReportingQuery,
  useLazyDownloadCandidatesReportQuery,
  useGetRefereesFiltersQuery,
  useLazyGetRefereesStatsQuery,
  useLazyGetRefereesReportingQuery,
  useLazyDownloadRefereesReportQuery,
} = reportingApi;
