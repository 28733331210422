export const FieldTypes = {
  ShortText: 'short_text',
  FreeText: 'long_text',
  MultipleChoice: 'multiple_choice',
  YesNo: 'yes_no',
  OpinionScale: 'opinion_scale',
  Date: 'date',
  PhoneNumber: 'phone_number',
  StarRating: 'rating',
  Upload: 'file_upload',
  Statement: 'statement',
  Signature: 'signature',
  Country: 'country',
};

export const FieldTypeDetails = {
  [FieldTypes.ShortText]: {
    title: 'Free Text',
    subTitle: 'Answer freely with unlimited text answers',
  },
  [FieldTypes.FreeText]: {
    title: 'Free Text',
    subTitle: 'Answer freely with unlimited text answers',
  },
  [FieldTypes.MultipleChoice]: {
    title: 'Multi-Choice',
    subTitle: 'Select single or multiple answers, or add optional free text',
  },
  [FieldTypes.YesNo]: {
    title: 'Yes/No',
    subTitle: 'Simple yes or No answer',
  },
  [FieldTypes.OpinionScale]: {
    title: 'Opinion Scale',
    subTitle: 'Create your own scales, NPS scoring, or ratings system',
  },
  [FieldTypes.Date]: {
    title: 'Date',
    subTitle: 'Request a specific date (eg preferred start date)',
  },
  [FieldTypes.PhoneNumber]: {
    title: 'Phone Number',
    subTitle: 'Request specific phone number',
  },
  [FieldTypes.StarRating]: {
    title: 'Star Rating',
    subTitle: 'Create your own rating scales',
  },
  [FieldTypes.Upload]: {
    title: 'Upload',
    subTitle: 'Easily upload your docs / ID etc. Use your camera if on a phone',
  },
  [FieldTypes.Statement]: {
    title: 'Statement',
    subTitle: 'A basic statement that does not require an answer',
  },
  [FieldTypes.Signature]: {
    title: 'Signature',
    subTitle: 'A simple signature block to confirm understanding',
  },
  [FieldTypes.Country]: {
    title: 'Country Selector',
    subTitle: 'Country list',
  },
};

export const FieldTypesOptions = [
  {
    title: FieldTypeDetails[FieldTypes.FreeText].title,
    subTitle: FieldTypeDetails[FieldTypes.FreeText].subTitle,
    key: FieldTypes.FreeText,
  },
  {
    title: FieldTypeDetails[FieldTypes.MultipleChoice].title,
    subTitle: FieldTypeDetails[FieldTypes.MultipleChoice].subTitle,
    key: FieldTypes.MultipleChoice,
  },
  {
    title: FieldTypeDetails[FieldTypes.YesNo].title,
    subTitle: FieldTypeDetails[FieldTypes.YesNo].subTitle,
    key: FieldTypes.YesNo,
  },
  {
    title: FieldTypeDetails[FieldTypes.OpinionScale].title,
    subTitle: FieldTypeDetails[FieldTypes.OpinionScale].subTitle,
    key: FieldTypes.OpinionScale,
  },
  {
    title: FieldTypeDetails[FieldTypes.Date].title,
    subTitle: FieldTypeDetails[FieldTypes.Date].subTitle,
    key: FieldTypes.Date,
  },
  {
    title: FieldTypeDetails[FieldTypes.PhoneNumber].title,
    subTitle: FieldTypeDetails[FieldTypes.PhoneNumber].subTitle,
    key: FieldTypes.PhoneNumber,
  },
  {
    title: FieldTypeDetails[FieldTypes.StarRating].title,
    subTitle: FieldTypeDetails[FieldTypes.StarRating].subTitle,
    key: FieldTypes.StarRating,
  },
  {
    title: FieldTypeDetails[FieldTypes.Upload].title,
    subTitle: FieldTypeDetails[FieldTypes.Upload].subTitle,
    key: FieldTypes.Upload,
  },
  {
    title: FieldTypeDetails[FieldTypes.Statement].title,
    subTitle: FieldTypeDetails[FieldTypes.Statement].subTitle,
    key: FieldTypes.Statement,
  },
  {
    title: FieldTypeDetails[FieldTypes.Signature].title,
    subTitle: FieldTypeDetails[FieldTypes.Signature].subTitle,
    key: FieldTypes.Signature,
  },
  {
    title: FieldTypeDetails[FieldTypes.Country].title,
    subTitle: FieldTypeDetails[FieldTypes.Country].subTitle,
    key: FieldTypes.Country,
  },
];

export const TypeformFieldTypesOptions = [
  {
    title: FieldTypeDetails[FieldTypes.FreeText].title,
    subTitle: FieldTypeDetails[FieldTypes.FreeText].subTitle,
    key: FieldTypes.FreeText,
  },
  {
    title: FieldTypeDetails[FieldTypes.MultipleChoice].title,
    subTitle: FieldTypeDetails[FieldTypes.MultipleChoice].subTitle,
    key: FieldTypes.MultipleChoice,
  },
  {
    title: FieldTypeDetails[FieldTypes.YesNo].title,
    subTitle: FieldTypeDetails[FieldTypes.YesNo].subTitle,
    key: FieldTypes.YesNo,
  },
  {
    title: FieldTypeDetails[FieldTypes.OpinionScale].title,
    subTitle: FieldTypeDetails[FieldTypes.OpinionScale].subTitle,
    key: FieldTypes.OpinionScale,
  },
  {
    title: FieldTypeDetails[FieldTypes.Date].title,
    subTitle: FieldTypeDetails[FieldTypes.Date].subTitle,
    key: FieldTypes.Date,
  },
  {
    title: FieldTypeDetails[FieldTypes.PhoneNumber].title,
    subTitle: FieldTypeDetails[FieldTypes.PhoneNumber].subTitle,
    key: FieldTypes.PhoneNumber,
  },
  {
    title: FieldTypeDetails[FieldTypes.Statement].title,
    subTitle: FieldTypeDetails[FieldTypes.Statement].subTitle,
    key: FieldTypes.Statement,
  },
];

export const CustomFieldTypesOptions = [
  {
    title: FieldTypeDetails[FieldTypes.FreeText].title,
    subTitle: FieldTypeDetails[FieldTypes.FreeText].subTitle,
    key: FieldTypes.FreeText,
  },
  {
    title: FieldTypeDetails[FieldTypes.MultipleChoice].title,
    subTitle: FieldTypeDetails[FieldTypes.MultipleChoice].subTitle,
    key: FieldTypes.MultipleChoice,
  },
  {
    title: FieldTypeDetails[FieldTypes.YesNo].title,
    subTitle: FieldTypeDetails[FieldTypes.YesNo].subTitle,
    key: FieldTypes.YesNo,
  },
  {
    title: FieldTypeDetails[FieldTypes.Date].title,
    subTitle: FieldTypeDetails[FieldTypes.Date].subTitle,
    key: FieldTypes.Date,
  },
  {
    title: FieldTypeDetails[FieldTypes.PhoneNumber].title,
    subTitle: FieldTypeDetails[FieldTypes.PhoneNumber].subTitle,
    key: FieldTypes.PhoneNumber,
  },
];
