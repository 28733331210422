const PUBLIC_DOMAINS = [
  '@gmail',
  '@yahoo',
  '@hotmail',
  '@aol',
  '@outlook',
  '@mail',
  '@msn',
  '@wanadoo',
  '@orange',
  '@comcast',
  '@live',
];

const isPrivateDomain = value => {
  const domain = value.match(/(@.*)(?=\.)/g);
  if (!domain) return 'Invalid email';

  const isPublicDomain = PUBLIC_DOMAINS.find(
    publicDomain => publicDomain === domain[0].toLowerCase(),
  );
  return isPublicDomain ? 'Corporate email required' : true;
};

const equals = (value, constValue) => {
  if (value !== constValue) return "Value doesn't match";

  return true;
};

export { isPrivateDomain, equals };
