import { useEffect, useState } from 'react';
import { VerificationCard, Statuses } from './../VerificationCard/VerificationCard';
import InputValidator from 'helpers/input-validator';
import { FormResponse } from 'components/Form/FormResponse/FormResponse';
import { useGetRefereeResponseQuery } from 'api/referees-api';
import cn from 'classnames';

export const RefereeReport = ({ candidate, referee, setLoading = () => {} }) => {
  const { data: response, isLoading } = useGetRefereeResponseQuery(referee.id);

  const [emailVerification, setEmailVerification] = useState({ status: '', msg: '' });
  const [linkedinVerification, setLinkedinVerification] = useState({ status: '', msg: '' });
  const [ipVerification, setIpVerification] = useState({ status: '', msg: '' });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  // Set verification statuses
  useEffect(() => {
    // Email
    if (referee.email === candidate.email)
      setEmailVerification({ status: Statuses.Error, msg: 'Email addresses match' });
    else if (InputValidator.CompanyEmailValidator(referee.email))
      setEmailVerification({ status: Statuses.Success, msg: 'Registered Company Email' });
    else setEmailVerification({ status: Statuses.Warn, msg: 'Non company mail' });

    // Linkedin
    if (referee.linkedin_profile)
      setLinkedinVerification({ status: Statuses.Success, msg: 'Verified Linkedin' });
    else setLinkedinVerification({ status: Statuses.Warn, msg: 'No information provided' });

    // IP
    if (referee.ip === candidate.ip)
      setIpVerification({ status: Statuses.Error, msg: 'IP address matches candidate' });
    else setIpVerification({ status: Statuses.Success, msg: 'Unique IP Address' });
  }, [candidate, referee]);

  return (
    <div>
      <div
        className={cn(
          'd-flex',
          'justify-content-between',
          'u-padding--large',
          'background-secondary',
        )}>
        <VerificationCard>
          <VerificationCard.Header icon="mail">email address</VerificationCard.Header>
          <VerificationCard.Link href={`mailto:${referee.email}`}>
            {referee.email}
          </VerificationCard.Link>
          <VerificationCard.AlertMessage status={emailVerification.status}>
            {emailVerification.msg}
          </VerificationCard.AlertMessage>
        </VerificationCard>
        <VerificationCard>
          <VerificationCard.Header icon="linkedin" fa>
            linkedin profile
          </VerificationCard.Header>
          <VerificationCard.Value>
            {referee.linkedin_profile ? referee.linkedin_profile : '-'}
          </VerificationCard.Value>
          <VerificationCard.AlertMessage status={linkedinVerification.status}>
            {linkedinVerification.msg}
          </VerificationCard.AlertMessage>
        </VerificationCard>
        <VerificationCard>
          <VerificationCard.Header icon="shield">ip address</VerificationCard.Header>
          <VerificationCard.Value>{referee.ip}</VerificationCard.Value>
          <VerificationCard.AlertMessage status={ipVerification.status}>
            {ipVerification.msg}
          </VerificationCard.AlertMessage>
        </VerificationCard>
      </div>

      <hr className="divider u-margin-0" />

      <div className="u-padding--large">
        <FormResponse
          form={response?.form}
          answers={response?.answers}
          params={{
            candidate: candidate.name,
            job: candidate.job_role,
            team: candidate.team_name,
          }}
        />
      </div>
    </div>
  );
};
