import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  integrations: {},
};

export const getCompany = createAsyncThunk('getCompany', async id => {
  const res = await apiRequest(`companies/${id}`, {}, 'get', true);
  return res.result;
});

export const createCompany = createAsyncThunk(
  'createCompany',
  async (params, { rejectWithValue }) => {
    try {
      return await apiRequest('companies', params, 'post', true);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const updateCompany = createAsyncThunk(
  'updateCompany',
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const res = await apiRequest(`companies/${id}`, params, 'put', true);
      return res.result;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const updateCompanyOwner = createAsyncThunk('updateCompanyOwner', async ({ id, userId }) => {
  const res = await apiRequest(`companies/${id}/owner`, { user_id: userId }, 'put', true);
  return res.result;
});

export const createSSOOrganization = createAsyncThunk('createSSOOrganization', async domain => {
  const res = await apiRequest(`companies/sso`, { domain }, 'post');
  return res.result;
});

export const disableSSOOrganization = createAsyncThunk('disableSSOOrganization', async domain => {
  const res = await apiRequest(`companies/sso`, {}, 'delete');
  return res.result;
});

export const connectKnowMy = createAsyncThunk('connectKnowMy', async code => {
  const res = await apiRequest(`partners/knowmy`, { code }, 'post');
  return res.result;
});

export const disconnectKnowMy = createAsyncThunk('disconnectKnowMy', async () => {
  const res = await apiRequest(`partners/knowmy`, {}, 'delete');
  return res.result;
});

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCompany.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(createCompany.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(updateCompany.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(updateCompanyOwner.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(createSSOOrganization.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(disableSSOOrganization.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(connectKnowMy.fulfilled, state => {
        state.integrations.partners.knowmy.enabled = true;
      })
      .addCase(disconnectKnowMy.fulfilled, state => {
        state.integrations.partners.knowmy.enabled = false;
      });
  },
});

export const selectCompany = state => state.company;

export default companySlice.reducer;
