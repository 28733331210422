import React, { useState, useEffect } from 'react';
import { ToggleSwitchInput } from '../../../FormComponents/ToggleSwitchInput/ToggleSwitchInput';
import { InputGroup, Select } from 'components/FormComponents';

export const CandidateQuestionnaireSelectorDetails = ({
  index,
  append,
  remove,
  register,
  watch,
  credits = 0,
  candidateForms,
}) => {
  const [enabled, setEnabled] = useState(false);

  const formValue = watch(`checks.${index}.details.form_id`);

  useEffect(() => {
    if (enabled && isNaN(index)) {
      append({
        type: 'candidate_questionnaire',
        details: {},
      });
    } else if (!enabled && index >= 0) {
      remove(index);
    }
  }, [enabled]);

  useEffect(() => {
    if (index >= 0 && !enabled) setEnabled(true);
    else if (isNaN(index) && enabled) setEnabled(false);
  }, [index]);

  return (
    <ToggleSwitchInput
      text="Candidate Questionnaire"
      logo="https://checkmate-prod.s3.ap-southeast-2.amazonaws.com/assets/checkmate_check_logo.svg"
      subtext={(credits < 0 ? 'Unlimited' : credits) + ' remaining'}
      onChange={e => setEnabled(e.target.checked)}
      value={enabled}>
      {enabled && index >= 0 && (
        <InputGroup title="Form">
          <Select
            name={`checks.${index}.details.form_id`}
            placeholder="Select..."
            register={register}
            value={formValue}
            useDefault>
            {candidateForms.map(form => (
              <Select.Item key={form.id} value={form.id}>
                {form.name}
              </Select.Item>
            ))}
          </Select>
        </InputGroup>
      )}
    </ToggleSwitchInput>
  );
};
