import { useEffect } from 'react';

export const useDocumentTitle = (
  title = undefined,
  defaultValue = 'Checkmate - Reference Checking and Background Screening Software',
) => {
  useEffect(() => {
    document.title = title ? `${title} | Checkmate` : defaultValue;
  }, [title, defaultValue]);
};
