import { useEffect, useState } from 'react';
import { useDocumentTitle } from 'hooks/document-title';
import { useNavigate, useParams } from 'react-router-dom';
import InputValidator from 'helpers/input-validator';
import { Loading } from 'components/Loading/Loading';
import { useLazyGetReportDataQuery } from 'api/referees-api';
import { FormResponse } from 'components/Form/FormResponse/FormResponse';
import {
  VerificationCard,
  Statuses,
} from 'views/Check/tabs/Reference/components/VerificationCard/VerificationCard';
import Moment from 'react-moment';
import styles from './PublicReferenceReport.module.scss';

export const PublicReferenceReport = () => {
  useDocumentTitle('Report | Checkmate');

  const navigate = useNavigate();

  const { token } = useParams();

  const [getReportData] = useLazyGetReportDataQuery();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const [emailVerification, setEmailVerification] = useState({ status: '', msg: '' });
  const [linkedinVerification, setLinkedinVerification] = useState({ status: '', msg: '' });
  const [ipVerification, setIpVerification] = useState({ status: '', msg: '' });

  useEffect(() => {
    getReportData(token)
      .unwrap()
      .then(
        result => {
          setData(result);
          setLoading(false);
        },
        _ => navigate('/not_found_error'),
      );
  }, []);

  useEffect(() => {
    if (!data) return;

    // Email
    if (data.referee.email === data.candidate.email)
      setEmailVerification({ status: Statuses.Error, msg: 'Email addresses match' });
    else if (InputValidator.CompanyEmailValidator(referee.email))
      setEmailVerification({ status: Statuses.Success, msg: 'Registered Company Email' });
    else setEmailVerification({ status: Statuses.Warn, msg: 'Non company mail' });

    // Linkedin
    if (data.referee.linkedin_profile)
      setLinkedinVerification({ status: Statuses.Success, msg: 'Verified Linkedin' });
    else setLinkedinVerification({ status: Statuses.Warn, msg: 'No information provided' });

    // IP
    if (data.referee.ip === data.candidate.ip)
      setIpVerification({ status: Statuses.Error, msg: 'IP address matches candidate' });
    else setIpVerification({ status: Statuses.Success, msg: 'Unique IP Address' });
  }, [data]);

  if (!data) return <Loading active={loading} />;

  return (
    <div className="background-secondary u-padding--large u-width-100 d-flex justify-content-center u-height-100-vh">
      <div className="card card-with-border card--medium">
        <div className="u-padding--large card_content">
          <div className="d-flex justify-content-between align-items-center u-width-100">
            <div>{data.company}</div>
            <div className={styles.poweredBy}>
              <span className="u-margin-right--small">Powered by</span>{' '}
              <img src="assets/images/checkmate/icon-label.svg" alt="checkmate" />
            </div>
          </div>
          <div className="d-flex gap-1 u-width-100 justify-content-between u-margin-top--large">
            <div className={styles.name}>
              <div className={styles.label}>Reference check for</div>
              <div className={styles.value}>{data.candidate.name}</div>
            </div>
            <div className={styles.infoField}>
              <p className={styles.label}>role</p>
              <p className={styles.value}>{data.candidate.job_role}</p>
            </div>
            <div className={styles.infoField}>
              <p className={styles.label}>team</p>
              <p className={styles.value}>{data.candidate.team_name}</p>
            </div>
            <div className={styles.infoField}>
              <p className={styles.label}>create on</p>

              <p className={styles.value}>
                <Moment format="DD/MM/YYYY">{data.referee.created_at}</Moment>
              </p>
            </div>
            <div className={styles.infoField}>
              <p className={styles.label}>completed on</p>
              <p className={styles.value}>
                <Moment format="DD/MM/YYYY">{data.referee.completed_at}</Moment>
              </p>
            </div>
          </div>
          <div className="u-divider" />
          <div className="d-flex gap-1 u-width-100 u-padding-top">
            <div className={styles.name}>
              <div className={styles.label}>Referee name</div>
              <div className={styles.value}>{data.referee.name}</div>
            </div>
            {data.referee.answered_on_behalf_user && (
              <div className={styles.infoField}>
                <p className={styles.label}>On behalf of by</p>
                <p className={styles.value}>{data.referee.answered_on_behalf_user}</p>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between u-padding-y--large u-width-100">
            <VerificationCard>
              <VerificationCard.Header icon="mail">email address</VerificationCard.Header>
              <VerificationCard.Link href={`mailto:${data.referee.email}`}>
                {data.referee.email}
              </VerificationCard.Link>
              <VerificationCard.AlertMessage status={emailVerification.status}>
                {emailVerification.msg}
              </VerificationCard.AlertMessage>
            </VerificationCard>
            <VerificationCard>
              <VerificationCard.Header icon="linkedin" fa>
                linkedin profile
              </VerificationCard.Header>
              <VerificationCard.Value>
                {data.referee.linkedin_profile ? data.referee.linkedin_profile : '-'}
              </VerificationCard.Value>
              <VerificationCard.AlertMessage status={linkedinVerification.status}>
                {linkedinVerification.msg}
              </VerificationCard.AlertMessage>
            </VerificationCard>
            <VerificationCard>
              <VerificationCard.Header icon="shield">ip address</VerificationCard.Header>
              <VerificationCard.Value>{data.referee.ip}</VerificationCard.Value>
              <VerificationCard.AlertMessage status={ipVerification.status}>
                {ipVerification.msg}
              </VerificationCard.AlertMessage>
            </VerificationCard>
          </div>
          <div className="u-width-100 u-padding-top--large">
            <FormResponse
              form={data.form}
              answers={data.answers}
              params={{
                candidate: data.candidate.name,
                job: data.candidate.job_role,
                team: data.candidate.team_name,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
