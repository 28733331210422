import { useEffect, useState } from 'react';
import styles from './CsvTableDetails.module.scss';
import FeatherIcon from 'feather-icons-react';

export const CsvTableDetails = ({ csvData, onDrop = () => {} }) => {
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!csvData || csvData.length < 1) return;

    setData([]);
    csvData.forEach((record, index) => {
      if (index === 0) setHeaders(record);
      else setData(data => [...data, record]);
    });
  }, [csvData]);

  return (
    <div className={styles.root}>
      <FeatherIcon className={styles.closeIcon} icon="x" onClick={onDrop} />
      <table className={styles.table}>
        <thead>
          <tr>
            {headers.map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(row => (
            <tr key={row}>
              {row.map(column => (
                <td key={column}>{column}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
