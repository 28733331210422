import { QuestionResponse } from './QuestionResponse/QuestionResponse';
import { useMemo } from 'react';
import { getIgnoredFields } from 'constants/logic';

export const FormResponse = ({ form, answers, params = {} }) => {
  const fields = useMemo(() => {
    if (!form || !answers) return [];

    let ignoredFields = [];
    form.logic_jumps.forEach(logic => {
      const field = form.fields.find(field => field.order === logic.field);
      const value = answers[field.id];
      const logicIgnoredFields = getIgnoredFields(logic, value);
      ignoredFields = [...ignoredFields, ...logicIgnoredFields];
    });

    return form.fields.filter(field => !ignoredFields.includes(field.order));
  }, [form, answers]);

  return (
    <div>
      {fields.map((field, index) => (
        <QuestionResponse
          key={field.id}
          order={index + 1}
          field={field}
          answer={answers[field.id]}
          params={params}
        />
      ))}
    </div>
  );
};
