import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const idVerificationsApi = createApi({
  reducerPath: 'idVerificationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/id_verifications`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getIdVerificationTypes: builder.query({
      query: () => `/types`,
      transformResponse: ({ result }) => result,
    }),
    verifyId: builder.mutation({
      query: ({ candidateId, data }) => ({
        url: '/',
        method: 'POST',
        body: {
          candidate_id: candidateId,
          entity_data: data,
        },
      }),
      transformResponse: ({ result }) => result,
    }),
    getIdVerification: builder.query({
      query: candidateId => `/${candidateId}`,
      transformResponse: ({ result }) => result,
    }),
    updateIdVerificationByToken: builder.mutation({
      query: ({ token, payload }) => ({
        url: `/token/${token}`,
        method: 'PUT',
        body: payload,
      }),
      transformResponse: ({ result }) => result,
    }),
    deleteIdVerificationByToken: builder.mutation({
      query: ({ token }) => ({
        url: `/token/${token}`,
        method: 'DELETE',
      }),
    }),
    extractIdDetails: builder.query({
      query: ({ token, uploadId }) => ({
        url: `/token/${token}/details_extract`,
        method: 'POST',
        body: { upload_id: uploadId },
      }),
      transformResponse: ({ result }) => result,
    }),
    compareFaces: builder.mutation({
      query: ({ token, sourceId, targetId }) => ({
        url: `/token/${token}/face_comparisson`,
        method: 'POST',
        body: {
          source_upload_id: sourceId,
          target_upload_id: targetId,
        },
      }),
      transformResponse: ({ result }) => result,
    }),
  }),
});

export const {
  useLazyExtractIdDetailsQuery,
  useGetIdVerificationQuery,
  useGetIdVerificationTypesQuery,
  useVerifyIdMutation,
  useCompareFacesMutation,
  useUpdateIdVerificationByTokenMutation,
  useDeleteIdVerificationByTokenMutation,
} = idVerificationsApi;
