import { useEffect, useState } from 'react';
import { InputGroup, Button } from 'components/FormComponents';
import { DynamicTypeInput } from 'components/Form/DynamicTypeInput/DynamicTypeInput';
import { CustomButton } from '../components/CustomButton/CustomButton';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { useForm, useWatch } from 'react-hook-form';
import styles from './CandidateDetails.module.scss';
import { FormMessage, MessageType } from 'components/FormComponents/FormMessage/FormMessage';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import {
  selectCandidateApplication,
  saveCandidateChecksValues,
} from 'api/candidate-application-slice';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const CandidateDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = useParams();

  const {
    application: { brand, check_values, locale },
    fetched,
    details,
  } = useSelector(selectCandidateApplication);

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    unregister,
    watch,
    setError,
    clearErrors,
    formState: { errors },
    control,
  } = useForm();

  // Get update on Controlled components
  useWatch({ control });

  const [dynamicFields, setDynamicFields] = useState({});

  // Initialize
  useEffect(() => {
    if (!fetched) return;

    // Set values
    reset(check_values?.values);

    // Dynamic fields
    const dynamicArrays = details.fields.filter(field => field.type === 'dynamic_array');
    dynamicArrays.forEach(field =>
      setDynamicFields(curr => ({ ...curr, [field.code]: field.start_initialized ? [0] : [] })),
    );
  }, [fetched]);

  // Add record to dynamic array
  const addToDynamicArray = field => {
    const arrayPositions = dynamicFields[field.code];
    const newIndex = arrayPositions.length;

    setDynamicFields(curr => ({ ...curr, [field.code]: [...curr[field.code], newIndex] }));
  };

  // Remove record to dynamic array
  const removeFromDynamicArray = field => {
    const newValue = dynamicFields[field.code];
    const removedIndex = newValue.pop();
    setDynamicFields(curr => ({ ...curr, [field.code]: newValue }));
    unregister(`${field.code}.${removedIndex}`);
  };

  // Render fields
  const renderField = (field, parentField = null, subIndex = 0) => {
    let conditionsMet = true;

    if (field.conditions) {
      const values = getValues() || {};
      field.conditions.forEach(({ code, op, value }) => {
        let conditionMet = false;
        let conditionValue = parentField
          ? values[parentField.code]?.[subIndex]?.[code]
          : values[code];
        switch (op) {
          case 'is':
            conditionMet = conditionValue == value;
            break;
          case 'not_in':
            conditionMet = !value.includes(conditionValue);
            break;
          case 'in':
            conditionMet = value.includes(conditionValue);
            break;
        }

        if (!conditionMet) conditionsMet = false;
      });
    }

    if (!conditionsMet) return;

    const required = details.require_fields.includes(field.code);
    const title = `${field.title} ${required ? '*' : ''}`;
    const code = parentField ? `${parentField.code}.${subIndex}.${field.code}` : field.code;
    const error = errors?.[code]?.type;

    switch (field.type) {
      case 'object':
        return (
          <InputGroup key={code} title={title} description={field.description}>
            <div className={cn('card', 'card-with-border', 'u-padding')}>
              {field.object.map(objectField => renderField(objectField))}
            </div>
          </InputGroup>
        );
      case 'dynamic_array':
        var arrayPositions = dynamicFields[code];
        return (
          <InputGroup key={code} title={title} description={field.description}>
            <div className={cn('card', 'card-with-border', 'u-padding')}>
              {arrayPositions?.map(index => (
                <InputGroup key={`${code}-${index}`} title={`${field.object_title} (${index + 1})`}>
                  <div className={cn('card', 'card-with-border', 'u-padding')}>
                    {field.object.map(objectField => renderField(objectField, field, index))}
                  </div>
                </InputGroup>
              ))}
              <div className={cn('u-flex-box', 'u-flex-justify-between')}>
                <CustomButton
                  brand={brand}
                  className="u-margin-top--0 u-width-auto"
                  small
                  onClick={() => addToDynamicArray(field)}>
                  Add {field.object_title}
                </CustomButton>
                <Button
                  type="delete"
                  disabled={arrayPositions?.length < 1}
                  onClick={() => removeFromDynamicArray(field)}>
                  Remove Last
                </Button>
              </div>
            </div>
          </InputGroup>
        );
      case 'array':
        var arrayPositions = [];
        for (let i = 0; i < field.size; i++) {
          arrayPositions[i] = i;
        }

        return (
          <InputGroup key={code} title={title} description={field.description}>
            <div className={cn('card', 'card-with-border', 'u-padding')}>
              {arrayPositions?.map(index => (
                <InputGroup key={`${code}-${index}`} title={`${field.object_title} (${index + 1})`}>
                  <div className={cn('card', 'card-with-border', 'u-padding')}>
                    {field.object.map(objectField => renderField(objectField, field, index))}
                  </div>
                </InputGroup>
              ))}
            </div>
          </InputGroup>
        );
      default:
        return (
          <DynamicTypeInput
            key={code}
            title={title}
            description={field.description}
            fieldName={code}
            fieldOptions={field.options}
            type={field.type}
            required={required}
            control={control}
            error={error}
            noErrorMessage={true}
            locale={locale}
            register={register}
            watch={watch}
          />
        );
    }
  };

  // Set rejected values if any
  useEffect(() => {
    if (
      !check_values?.rejected ||
      check_values?.rejected_values?.values?.length < 1 ||
      check_values?.rejected_fixes?.values
    )
      return;

    check_values.rejected_values?.values.forEach(rejectedValue => {
      setError(rejectedValue, { type: 'custom' });
    });
  }, [check_values]);

  // Fix rejected values
  const fixRejectedValues = async () => {
    setLoading(true);
    await dispatch(
      saveCandidateChecksValues({
        token,
        data: { rejected_fixes: { ...check_values.rejected_fixes, values: true } },
      }),
    );
    clearErrors();
    setLoading(false);
  };

  // Submit
  const onSubmit = async values => {
    setLoading(true);
    await dispatch(saveCandidateChecksValues({ token, data: { values } }));
    setLoading(false);
    navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
  };

  return (
    <CandidatePage loading={loading} token={token} withTopLogo brand={brand}>
      <CandidatePage.View>
        <CandidatePage.Card className={styles.root}>
          <img src="assets/images/icons/id_icon.svg" className={styles.icon} />
          <h1 className="title-4 u-padding-top u-padding-bottom--large">Your Details</h1>
          <p className="t-subtitle u-margin-bottom">
            Please provide the following details. Please ensure all the information is accurate as
            this is shared directly with the relevant government entities.
          </p>
          {check_values?.rejected &&
            check_values?.rejected_values?.values?.length > 0 &&
            !check_values?.rejected_fixes?.values && (
              <>
                <FormMessage
                  className="u-margin-0"
                  message={
                    check_values?.rejected_values?.reason || 'Please verify the details below.'
                  }
                  type={MessageType.Error}
                />
                <FormMessage
                  type={MessageType.Error}
                  message={
                    'There are some fields that require your attention. Please review the fields in red and click "Fixed" button and then save again.'
                  }
                />
              </>
            )}
          <form onSubmit={handleSubmit(onSubmit)} className="u-width-100">
            <input type="hidden" {...register('submitted')} value={true} />
            {details?.fields.map(field => renderField(field))}
            {check_values?.rejected && Object.keys(errors).length > 0 && (
              <Button
                type="warn"
                onClick={fixRejectedValues}
                className="u-margin-top--large u-width-100">
                Fixed
              </Button>
            )}
            <CustomButton
              submit
              small
              className="u-margin-top--large"
              brand={brand}
              disabled={Object.keys(errors).length > 0}>
              Save
            </CustomButton>
          </form>
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CandidateDetails;
