import {
  useLazyGetCandidateChecksReportingQuery,
  useGetCandidateChecksFiltersQuery,
  useLazyGetCandidateChecksStatsQuery,
  useLazyDownloadCandidateChecksReportQuery,
} from 'api/reporting-api';
import { useState, useEffect } from 'react';
import { ReportingWidget } from '../../components/ReportingWidget/ReportingWidget';
import { Table } from 'components/Table/Table';
import Moment from 'react-moment';
import { Badge } from 'react-bootstrap';
import { Button } from 'components/FormComponents';
import { Link } from 'react-router-dom';

export const ChecksReporting = ({ companyChecks, locale, setLoading = () => {} }) => {
  const [getCandidateChecksReporting] = useLazyGetCandidateChecksReportingQuery();
  const [getCandidateChecksStats] = useLazyGetCandidateChecksStatsQuery();
  const [downloadCandidateChecksReport] = useLazyDownloadCandidateChecksReportQuery();
  const { data: filters } = useGetCandidateChecksFiltersQuery();

  const [checks, setChecks] = useState([]);
  const [stats, setStats] = useState({});
  const [queryParams, setQueryParams] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0,
  });

  const fetchChecks = async (params = {}) => {
    setLoading(true);
    setQueryParams(params);
    const result = await getCandidateChecksReporting(params).unwrap();
    const stats = await getCandidateChecksStats(params).unwrap();
    setStats(stats);
    setPagination(result.pagination);
    setChecks(result.candidate_checks);
    setLoading(false);
  };

  useEffect(() => {
    fetchChecks();
  }, []);

  const download = async () => {
    setLoading(true);
    await downloadCandidateChecksReport({ queryParams }).unwrap();
    setLoading(false);
  };

  return (
    <Table.Context onQueryUpdate={fetchChecks} defaultQuery={{ page: 1 }}>
      <Table.FloatingFilters className="u-margin-bottom--large">
        <Table.FloatingFiltersLeft>
          <Table.DateRangeFilter title="Created within" filterName="created_at" />
          <Table.DateRangeFilter title="Completed within" filterName="completed_at" />
          <Table.MultiSelectFilter
            title="Status"
            filterName="status"
            type="status"
            useDefault={false}
            options={[
              { name: 'Candidate', value: 'candidate' },
              { name: 'Submitted', value: 'submitted' },
              { name: 'Completed', value: 'completed' },
              { name: 'Overdue', value: 'overdue' },
              { name: 'Canceled', value: 'canceled' },
            ]}
          />
          <Table.MultiSelectFilter
            title="Type"
            filterName="type"
            type="status"
            useDefault={false}
            options={filters?.types}
          />
          <Table.MultiSelectFilter
            title="Team"
            filterName="team"
            type="status"
            useDefault={false}
            options={filters?.teams}
          />
          <Table.MultiSelectFilter
            title="Has Result"
            filterName="result_flag"
            type="status"
            useDefault={false}
            options={[
              { name: 'Yes', value: true },
              { name: 'No', value: false },
            ]}
          />
        </Table.FloatingFiltersLeft>
        <Table.FloatingFiltersRight>
          <Button icon="download" onClick={download}>
            Export
          </Button>
        </Table.FloatingFiltersRight>
      </Table.FloatingFilters>
      <ReportingWidget>
        <ReportingWidget.Tile>
          <ReportingWidget.Badge>Total Checks</ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.total_checks}</ReportingWidget.Value>
          <ReportingWidget.Label></ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile>
          <ReportingWidget.Badge>Average Turnaround</ReportingWidget.Badge>
          <ReportingWidget.Value>
            {stats?.avg_turnaround_time}
            <span className="title-3">h</span>
          </ReportingWidget.Value>
          <ReportingWidget.Label></ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Divider />
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Candidate}
          disabled={queryParams.status && !queryParams.status.includes('candidate')}>
          <ReportingWidget.Badge>
            Candidate (
            {stats.total_checks == 0
              ? 0
              : Math.round((stats?.candidate * 100) / stats.total_checks)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.candidate}</ReportingWidget.Value>
          <ReportingWidget.Label>Checks</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.InProgress}
          disabled={queryParams.status && !queryParams.status.includes('submitted')}>
          <ReportingWidget.Badge>
            Submitted (
            {stats.total_checks == 0
              ? 0
              : Math.round((stats?.submitted * 100) / stats.total_checks)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.submitted}</ReportingWidget.Value>
          <ReportingWidget.Label>Checks</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Completed}
          disabled={queryParams.status && !queryParams.status.includes('completed')}>
          <ReportingWidget.Badge>
            Complete (
            {stats.total_checks == 0
              ? 0
              : Math.round((stats?.completed * 100) / stats.total_checks)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.completed}</ReportingWidget.Value>
          <ReportingWidget.Label>Checks</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Warning}
          disabled={queryParams.status && !queryParams.status.includes('completed')}>
          <ReportingWidget.Badge>
            Has Result (
            {stats.completed == 0 ? 0 : Math.round((stats?.result_flag * 100) / stats.completed)}%)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.result_flag}</ReportingWidget.Value>
          <ReportingWidget.Label>Checks</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Canceled}
          disabled={queryParams.status && !queryParams.status.includes('canceled')}>
          <ReportingWidget.Badge>
            Cancelled (
            {stats.total_checks == 0 ? 0 : Math.round((stats?.canceled * 100) / stats.total_checks)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.canceled}</ReportingWidget.Value>
          <ReportingWidget.Label>Checks</ReportingWidget.Label>
        </ReportingWidget.Tile>
      </ReportingWidget>
      <Table.TableContent>
        <Table.Filters>
          <Table.Search placeholder="Search..." />
        </Table.Filters>
        <Table className="u-margin--large">
          <Table.Head>
            <Table.Row>
              <Table.Header sortKey="candidate_checks.type">Type</Table.Header>
              <Table.Header sortKey="candidate_checks.status">Status</Table.Header>
              <Table.Header sortKey="candidate_checks.id">ID</Table.Header>
              <Table.Header sortKey="candidates.first_name">Candidate Name</Table.Header>
              <Table.Header sortKey="candidates.email">Email</Table.Header>
              <Table.Header sortKey="candidate_checks.created_at">Created</Table.Header>
              <Table.Header sortKey="candidate_checks.submitted_at">Submitted</Table.Header>
              <Table.Header sortKey="candidate_checks.completed_at">Completed</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {checks.map(check => (
              <Table.Row key={check.id}>
                <Table.Col className="u-bold">
                  {companyChecks.find(cc => cc.type == check.type)?.check_type.title}
                </Table.Col>
                <Table.Col>
                  <Badge className={`u-status-${check.status}`}>{check.status}</Badge>
                </Table.Col>
                <Table.Col>{check.id}</Table.Col>
                <Table.Col>
                  <Link className="u-link dark" to={`/check/${check.candidate_id}`}>
                    {check.first_name} {check.last_name}
                  </Link>
                </Table.Col>
                <Table.Col>{check.email}</Table.Col>
                <Table.Col>
                  <Moment format={locale.dateSlashFormat}>{check.created_at}</Moment>
                </Table.Col>
                <Table.Col>
                  {check.submitted_at ? (
                    <Moment format={locale.dateSlashFormat}>{check.submitted_at}</Moment>
                  ) : (
                    '-'
                  )}
                </Table.Col>
                <Table.Col>
                  {check.completed_at ? (
                    <Moment format={locale.dateSlashFormat}>{check.completed_at}</Moment>
                  ) : (
                    '-'
                  )}
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Pagination {...pagination} />
        </Table>
      </Table.TableContent>
    </Table.Context>
  );
};
