import { useEffect } from 'react';
import { Error } from './Error';
import ReactGA from 'react-ga';
import { useDocumentTitle } from 'hooks/document-title';
import { useQueryParams } from 'hooks/query-params';

const Error404 = () => {
  useDocumentTitle('Page not found | Checkmate');

  const [message] = useQueryParams(['message']);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return <Error message={message}>404</Error>;
};

export default Error404;
