import { ChangePassword } from './ChangePassword/ChangePassword';
import { TwoFactorAuthentication } from './TwoFactorAuthentication/TwoFactorAuthentication';
import { Tabs, Tab } from 'react-bootstrap';
import styles from './Security.module.scss';
import cn from 'classnames';

export const Security = ({
  user,
  setLoading = () => {},
  messagePopUp = () => {},
  onUserUpdate = () => {},
  onEnable2FA = () => {},
}) => {
  return (
    <div className={cn(styles.root)}>
      <div className={cn('card--small', 'card', 'card-with-border')}>
        <div className="u-margin--large">
          <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
            <h1 className="title-2">Security</h1>
          </div>
          <p className="t-small">Review and update your security settings.</p>
        </div>
        <Tabs defaultActiveKey="password" className="tabs padding-small">
          <Tab eventKey="password" title="Password">
            <ChangePassword user={user} setLoading={setLoading} messagePopUp={messagePopUp} />
          </Tab>
          <Tab eventKey="2fa" title="Two Factor Authentication">
            <TwoFactorAuthentication
              user={user}
              onEnable2FA={onEnable2FA}
              setLoading={setLoading}
              onUserUpdate={onUserUpdate}
              messagePopUp={messagePopUp}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
