import { useState } from 'react';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { Checkbox, Signature } from 'components/FormComponents';
import { CustomButton } from '../components/CustomButton/CustomButton';
import { useForm, useWatch } from 'react-hook-form';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import styles from './CandidateSignature.module.scss';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCandidateApplication,
  submitCandidateApplication,
} from 'api/candidate-application-slice';
import { useCandidateApplicationUploadActions } from 'hooks/upload-actions';
import { useUserAgent } from 'hooks/user-agent';

const CandidateSignature = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { register, watch, control, setValue } = useForm();

  const values = useWatch({ control });

  const { user_agent } = useUserAgent();
  const { token } = useParams();

  const {
    application: { candidate, brand, check_types, candidate_checks },
  } = useSelector(selectCandidateApplication);

  const { signUpload, createUpload, deleteUpload } = useCandidateApplicationUploadActions(token);

  const [loading, setLoading] = useState(false);

  const [signature, setSignature] = useState();

  const onSubmit = async () => {
    setLoading(true);
    await dispatch(submitCandidateApplication({ token, user_agent }));
    setLoading(false);
    navigate(`/form_submission/candidate/${CandidateCheckScreens.SUCCESS}/${token}`);
  };

  const consentWithParams = (consent, candidateCheck) => {
    if (!consent) return;

    const params = {
      brandName: brand.brand_name,
      candidateName: candidate.name,
      country: candidateCheck.details?.country,
    };

    const consentWithVars = consent.replace(/{((?!}).)*}/g, match => {
      const m = match.replace(/[{}]/g, '');
      return params[m];
    });

    return consentWithVars;
  };

  return (
    <CandidatePage loading={loading} token={token} withTopLogo brand={brand}>
      <CandidatePage.View>
        <CandidatePage.Card className={styles.root}>
          <img src="assets/images/icons/signature_icon.svg" className={styles.icon} />
          <h1 className="title-4 u-padding-y">Consent & Signature</h1>
          <p className="u-margin-y">
            To submit please tick that you accept the following consent and sign below using your
            finger or cursor.
          </p>
          <div>
            {candidate_checks.map(candidateCheck => {
              const checkType = check_types.find(
                checkType => candidateCheck.type === checkType.slug,
              );
              if (!checkType.consent) return;

              const name = `terms_${checkType.slug}`;
              if (watch(name) === undefined) setValue(name, false);

              return (
                <div
                  key={checkType.slug}
                  className={cn(
                    'card',
                    'card-with-border',
                    'background-secondary',
                    'u-padding',
                    'd-flex',
                    'u-margin-bottom',
                  )}>
                  <div>
                    <Checkbox
                      name={name}
                      register={register}
                      checked={watch(name)}
                      className="u-no-margin"
                    />
                  </div>
                  <div
                    className={cn(styles.consent, 't-small', 'u-margin-left')}
                    dangerouslySetInnerHTML={{
                      __html: consentWithParams(checkType.consent, candidateCheck),
                    }}></div>
                </div>
              );
            })}
          </div>
          <div className={cn('d-flex', 'u-margin-top', 'u-width-100')}>
            <p className="u-bold">Please sign below</p>
          </div>
          <Signature
            modelParams={{ candidate_id: candidate.id }}
            metadata={{ signature: true }}
            signatureId={signature}
            onChange={setSignature}
            signUpload={signUpload}
            createUpload={createUpload}
            onDeleteUpload={deleteUpload}
            useModel
          />

          <CustomButton
            brand={brand}
            onClick={onSubmit}
            disabled={Object.values(values).filter(value => !value).length > 0 || !signature}
            small>
            Complete and submit
          </CustomButton>
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CandidateSignature;
