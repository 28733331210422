import styles from './CombinedQuestionSkill.module.scss';
import cn from 'classnames';

const SCALE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const CombinedQuestionSkill = ({ order, field, answers }) => (
  <div className={styles.root}>
    <div className={cn('u-flex-box', 'u-flex-justify-start')}>
      <div className={styles.order}>{order}</div>
      <div className={styles.question}>{field.text}</div>
    </div>
    <div className={cn(styles.answers, 'u-flex-box')}>
      <div className={styles.namesContainer}>
        {answers.map(answer => (
          <div key={answer.name} className={styles.name}>
            {answer.name}
          </div>
        ))}
      </div>
      <div>
        {answers.map(answer => (
          <div key={answer.name} className={styles.answer}>
            {SCALE.map(s => (
              <span
                key={s}
                className={cn(styles.scale, { [styles.selected]: s === answer.answer })}>
                {s}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
);
