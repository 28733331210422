import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const oauthClientApi = createApi({
  reducerPath: 'oauthClientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/o_auth_clients`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getOAuthClient: builder.query({
      query: clientId => `/${clientId}`,
    }),
  }),
});

export const { useLazyGetOAuthClientQuery } = oauthClientApi;
