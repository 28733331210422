import React, { useRef } from 'react';
import styles from './TagsMenu.module.scss';
import { Link } from 'react-router-dom';
import { useClickOutside } from '../../hooks/click-outside';
import cn from 'classnames';

export const TagsMenu = ({
  visible,
  tags = [],
  onSelect = () => {},
  bulkTagDeletion = () => {},
  onClose = () => {},
  hideDeletion = false,
  position = 'top',
}) => {
  const ref = useRef(null);
  useClickOutside(ref, onClose);

  return (
    <ul className={cn(styles.root, styles[position], { [styles.rootVisible]: visible })} ref={ref}>
      {tags.map(tag => (
        <li key={tag.id} onClick={() => onSelect(tag.id)}>
          {tag.name}
        </li>
      ))}
      {tags.length > 1 && !hideDeletion && (
        <li className={styles.removeOption} onClick={bulkTagDeletion}>
          Remove all tags
        </li>
      )}
      {tags.length < 1 && (
        <li>
          There are no tags yet.{' '}
          <Link to="/account/tags" className="u-link">
            Start here
          </Link>
        </li>
      )}
    </ul>
  );
};
