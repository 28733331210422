import { List } from 'components/List/List';
import { useState } from 'react';
import { SectionedLogicForm } from 'components/SectionedForm/SectionedLogicForm/SectionedLogicForm';
import cn from 'classnames';

export const IQForms = ({ forms }) => {
  const [form, setForm] = useState();

  return (
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <List
        title="Checkmate IQ"
        subtitle="Here you can see your Intelligent Questionnaires."
        className="u-margin-right--large u-flex-align-self-normal">
        <List.Category title="Library Reference Forms">
          {forms.map(form => (
            <List.Item key={form.id} title={form.name} value={form} onClick={setForm} />
          ))}
        </List.Category>
      </List>
      {form && <SectionedLogicForm form={form} />}
    </div>
  );
};
