import { MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: {
    hide: false,
    text: '',
    state: MESSAGE_STATE_SUCCESS,
    show: false,
    params: null,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    messagePopOut(state) {
      state.message.show = false;
    },
    messagePopUp(state, { payload }) {
      state.message = { ...payload, show: true };
    },
  },
  extraReducers: builder => {},
});

export const { messagePopOut, messagePopUp } = appSlice.actions;

export const selectApp = state => state.app;

export default appSlice.reducer;
