import { Input, InputGroup } from 'components/FormComponents';
import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { IconButton } from 'components/IconButton/IconButton';
import { Partners } from 'constants/partners';
import { IntegrationCard } from '..';
import Alert from 'components/Alert/Alert';
import { useState } from 'react';
import { useOAuth } from 'hooks/oauth';

export const BullhornIntegration = ({
  integration,
  config,
  setLoading = () => {},
  popUpMessage = () => {},
  connect = () => {},
  disconnect = () => {},
  revokeToken = () => {},
}) => {
  const [enabled, setEnabled] = useState(integration.enabled);
  const [showDeletionAlert, setShowDeletionAlert] = useState(false);

  const onConnect = async code => {
    setLoading(true);
    try {
      await connect({ code });
      popUpMessage('Bullhorn integrated with Checkmate', MESSAGE_STATE_SUCCESS);
      setEnabled(true);
    } catch (e) {
      popUpMessage(
        'Bullhorn integration failed. Please check your credentials and try again.',
        MESSAGE_STATE_ERROR,
      );
      setEnabled(false);
    } finally {
      setLoading(false);
    }
  };

  const openAuth = useOAuth({
    authUri: 'https://auth.bullhornstaffing.com/oauth/authorize',
    clientId: 'a4d06189-bee0-4de0-9a09-c7f05ea0ba56',
    responseType: 'code',
    redirectUri: `${process.env.REACT_APP_WEB_URL}/assets/bullhorn_callback.html`,
    callbackAction: 'bullhorn_callback',
    callback: onConnect,
  });

  // const toggleIntegration = async(e) => {
  //   const value = e.target.checked;
  //   if(integration.enabled === value) return;

  //   if(value) {
  //     openAuth();
  //   } else {
  //     setLoading(true);
  //     await disconnect();
  //     setLoading(false);
  //     setEnabled(false);
  //   }
  // }

  const copy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_WEB_URL}/assessments/launcher/${Partners.Bullhorn}/${integration.api_key}`,
    );
  };

  const handleRevokeToken = () => {
    setShowDeletionAlert(false);
    revokeToken(Partners.Bullhorn);
  };

  return (
    <IntegrationCard
      {...config}
      subtitle={
        !enabled && (
          <p className="t-small">
            We're having issues with Bullhorn at the moment. Please contact{' '}
            <a className="u-link dark" href="mailto:support@checkmate.tech" target="_blank">
              Support
            </a>{' '}
            to activate the integration
          </p>
        )
      }>
      <Alert
        show={showDeletionAlert}
        title="Are you sure you want to revoke current Bullhorn token?"
        message="You're about to revoke your current Bullhorn token. You will need to copy/paste the new link onto your Bullhorn's account to keep using the integration. Are you sure you want to continue?"
        ok="Revoke"
        onOk={handleRevokeToken}
        cancel="Cancel"
        onCancel={() => setShowDeletionAlert(false)}
      />
      {enabled && (
        <InputGroup title="Integration URL" className="u-margin-top">
          <div className="d-flex align-items-center">
            <Input
              className="u-width-100"
              inputProps={{
                value: `${process.env.REACT_APP_WEB_URL}/assessments/launcher/${Partners.Bullhorn}/${integration.api_key}`,
              }}
            />
            <IconButton icon="clipboard" className="u-margin-left--small" onClick={copy} />
            <IconButton
              icon="trash-2"
              className="u-margin-left--small"
              type="delete"
              tip="Revoke"
              placement="bottom"
              onClick={() => setShowDeletionAlert(true)}
            />
          </div>
        </InputGroup>
      )}
    </IntegrationCard>
  );
};
