export const FrankieOneStates = {
  UNCHECKED: 'declined',
  NOT_SUPPORTED: 'declined',
  CHECKING: 'in-progress',
  UNPROCESSABLE: 'declined',
  NO_MATCH: 'declined',
  CHECKED_PARTIAL_SUCCESS: 'passed',
  CHECKED_SUCCESS_WITH_NOTES: 'passed',
  CHECKED_SUCCESS_CLEAR: 'passed',
  CHECKED_FAILED: 'declined',
};
