import { useEffect, useState } from 'react';
import AppPage from 'layouts/AppPage/AppPage';
import { getBundles, createBundle, updateBundle, deleteBundle } from 'api/bundles-slice';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { Branding } from './tabs/Branding/Branding';
import { Company } from './tabs/Company/Company';
import { Profile } from './tabs/Profile/Profile';
import { Billing } from './tabs/Billing/Billing';
import { Security } from './tabs/Security/Security';
import { Integrations } from './tabs/Integrations/Integrations';
import { DataProtection } from './tabs/DataProtection/DataProtection';
import { Notifications } from './tabs/Notifications/Notifications';
import { Tags } from './tabs/Tags/Tags';
import { CustomFields } from './tabs/CustomFields/CustomFields';
import { Bundles } from './tabs/Bundles/Bundles';
import { useDocumentTitle } from 'hooks/document-title';
import { useAccessControl } from 'hooks/access-control';
import { Permissions } from 'constants/permissions';
import { AuditLogs } from './tabs/AuditLogs/AuditLogs';
import { useNavigate, useParams } from 'react-router-dom';
import { selectPermissions } from 'api/user-permissions-slice';
import { updateUser, selectUser, enable2FA } from 'api/user-slice';
import { messagePopUp } from 'api/app-slice';
import { getForms } from 'api/forms-slice';
import { getTags, createTag, deleteTag } from 'api/tags-slice';
import { selectCompany, updateCompany, connectKnowMy, disconnectKnowMy } from 'api/company-slice';
import {
  getPartnersConfig,
  connectLever,
  disconnectLever,
  connectJobAdder,
  disconnectJobAdder,
  connectBullhorn,
  disconnectBullhorn,
  connectLivehire,
  disconnectLivehire,
} from 'api/partner-slice';
import { revokePartnerToken } from 'api/partner-token-version-slice';
import {
  getBrands,
  updateBrand,
  deleteBrand,
  createBrand,
  getBrandUploads,
  removeBrandUpload,
  addBrandUpload,
} from 'api/brands-slice';

const Account = () => {
  useDocumentTitle('Your Account | Checkmate');

  const { tab: targetTab } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const company = useSelector(selectCompany);
  const user = useSelector(selectUser);
  const permissions = useSelector(selectPermissions);

  const onCompanyUpdate = params => dispatch(updateCompany({ id: company.id, params }));
  const onUserUpdate = data => dispatch(updateUser({ id: user.id, data }));
  const onMessagePopUp = (text, state, hide = true) =>
    dispatch(messagePopUp({ text, state, hide }));
  const onEnable2FA = data => dispatch(enable2FA({ id: user.id, data }));

  const onGetBrands = () => dispatch(getBrands());
  const onCreateBrand = name => dispatch(createBrand(name));
  const onBrandUpdate = (id, data) => dispatch(updateBrand({ id, data }));
  const onDeleteBrand = id => dispatch(deleteBrand(id));
  const onGetBrandUploads = id => dispatch(getBrandUploads(id));
  const onRemoveBrandUpload = (brandId, uploadId) =>
    dispatch(removeBrandUpload({ brandId, uploadId }));
  const onAddBrandUpload = (brandId, upload) => dispatch(addBrandUpload({ brandId, upload }));

  const onGetForms = () => dispatch(getForms());
  const onGetBundles = () => dispatch(getBundles());
  const onCreateBundle = data => dispatch(createBundle(data));
  const onBundleUpdate = (id, data) => dispatch(updateBundle({ id, data }));
  const onDeleteBundle = id => dispatch(deleteBundle(id));

  const onConnectJobAdder = code => dispatch(connectJobAdder(code));
  const onDisconnectJobAdder = () => dispatch(disconnectJobAdder());
  const onConnectKnowMy = code => dispatch(connectKnowMy(code));
  const onDisconnectKnowMy = () => dispatch(disconnectKnowMy());
  const onConnectBullhorn = params => dispatch(connectBullhorn(params));
  const onDisconnectBullhorn = () => dispatch(disconnectBullhorn());
  const onConnectLever = code => dispatch(connectLever(code));
  const onDisconnectLever = () => dispatch(disconnectLever());
  const onConnectLivehire = params => dispatch(connectLivehire(params));
  const onDisconnectLivehire = () => dispatch(disconnectLivehire());

  const onGetTags = () => dispatch(getTags());
  const onCreateTag = data => dispatch(createTag(data));
  const onDeleteTag = id => dispatch(deleteTag(id));

  const onGetPartnersConfig = () => dispatch(getPartnersConfig());
  const onRevokePartnerToken = partner => dispatch(revokePartnerToken(partner));

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(targetTab);

  const hasAccess = useAccessControl(permissions);

  useEffect(() => setTab(targetTab), [targetTab]);

  return (
    <AppPage loading={loading}>
      <AppPage.Header
        title="Your Account"
        subtitle="Below you can view and edit information associated with your account"
      />
      <Tabs
        defaultActiveKey="profile"
        onSelect={key => navigate(`/account/${key}`)}
        activeKey={tab}
        mountOnEnter={true}
        className="tabs">
        {hasAccess(Permissions.CompanyWrite) && (
          <Tab eventKey="company" title="Company">
            <Company company={company} updateCompany={onCompanyUpdate} setLoading={setLoading} />
          </Tab>
        )}
        <Tab eventKey="profile" title="Profile">
          <Profile
            user={user}
            company={company}
            updateUser={onUserUpdate}
            setLoading={setLoading}
          />
        </Tab>
        <Tab eventKey="security" title="Security">
          <Security
            user={user}
            setLoading={setLoading}
            messagePopUp={onMessagePopUp}
            onUserUpdate={onUserUpdate}
            onEnable2FA={onEnable2FA}
          />
        </Tab>
        {hasAccess(Permissions.BillingWrite) && (
          <Tab eventKey="billing" title="Billing">
            <Billing setLoading={setLoading} />
          </Tab>
        )}
        {hasAccess(Permissions.BrandWrite) && (
          <Tab eventKey="branding" title="Branding">
            <Branding
              user={user}
              setLoading={setLoading}
              messagePopUp={onMessagePopUp}
              createBrand={onCreateBrand}
              getBrands={onGetBrands}
              updateBrand={onBrandUpdate}
              getBrandUploads={onGetBrandUploads}
              deleteBrand={onDeleteBrand}
              removeBrandUpload={onRemoveBrandUpload}
              addBrandUpload={onAddBrandUpload}
            />
          </Tab>
        )}
        {hasAccess(Permissions.CheckBundlesWrite) && (
          <Tab eventKey="bundles" title="Bundles">
            <Bundles
              setLoading={setLoading}
              getForms={onGetForms}
              getBrands={onGetBrands}
              getBundles={onGetBundles}
              createBundle={onCreateBundle}
              updateBundle={onBundleUpdate}
              deleteBundle={onDeleteBundle}
            />
          </Tab>
        )}
        <Tab eventKey="notifications" title="Notifications">
          <Notifications setLoading={setLoading} />
        </Tab>
        {hasAccess(Permissions.CompanyWrite) && (
          <Tab eventKey="data_protection" title="Data Protection">
            <DataProtection
              company={company}
              setLoading={setLoading}
              updateCompany={onCompanyUpdate}
            />
          </Tab>
        )}
        {hasAccess(Permissions.CompanyWrite) && company.audit_logs_enabled && (
          <Tab eventKey="audit_logs" title="Audit Logs">
            <AuditLogs company={company} setLoading={setLoading} />
          </Tab>
        )}
        {hasAccess(Permissions.PartnersWrite) && (
          <Tab eventKey="integrations" title="Integrations">
            <Integrations
              setLoading={setLoading}
              getPartnersConfig={onGetPartnersConfig}
              connectJobAdder={onConnectJobAdder}
              disconnectJobAdder={onDisconnectJobAdder}
              connectBullhorn={onConnectBullhorn}
              disconnectBullhorn={onDisconnectBullhorn}
              connectLever={onConnectLever}
              disconnectLever={onDisconnectLever}
              connectKnowMy={onConnectKnowMy}
              disconnectKnowMy={onDisconnectKnowMy}
              connectLivehire={onConnectLivehire}
              disconnectLivehire={onDisconnectLivehire}
              popUpMessage={onMessagePopUp}
              onCompanyUpdate={onCompanyUpdate}
              onRevokePartnerToken={onRevokePartnerToken}
            />
          </Tab>
        )}
        <Tab eventKey="tags" title="Tags">
          <Tags
            getTags={onGetTags}
            deleteTag={onDeleteTag}
            createTag={onCreateTag}
            setLoading={setLoading}
          />
        </Tab>
        <Tab eventKey="custom_fields" title="Custom Fields">
          <CustomFields setLoading={setLoading} />
        </Tab>
      </Tabs>
    </AppPage>
  );
};

export default Account;
