import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { saveAs } from 'file-saver';
import cookie from 'react-cookies';

export const partnerApi = createApi({
  reducerPath: 'partnerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/partners`,
    prepareHeaders: headers => {
      if (!headers.has('Authorization'))
        headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getApplication: builder.query({
      query: token => `/token/${token}`,
      transformResponse: ({ result }) => result,
    }),
    getReferees: builder.query({
      query: token => `/token/${token}/referee`,
      transformResponse: ({ result }) => result,
    }),
    getJobAdderCandidate: builder.query({
      query: ({ token, partnerApplicationId }) => ({
        url: `/jobadder/jobs_applications/${partnerApplicationId}`,
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    getBullhornCandidate: builder.query({
      query: ({ token, partnerApplicationId }) => ({
        url: `/bullhorn/candidates/${partnerApplicationId}`,
        headers: { Authorization: token },
      }),
      transformResponse: ({ result }) => result,
    }),
    startApplicationByToken: builder.mutation({
      query: ({ token, params }) => ({
        url: `/token/${token}`,
        method: 'POST',
        body: params,
      }),
      transformResponse: ({ result }) => result,
    }),
    sendRefereesReportsByToken: builder.mutation({
      query: ({ token, email, referees }) => ({
        url: `/token/${token}/referee/send`,
        method: 'POST',
        body: { email, referees },
      }),
      transformResponse: ({ result }) => result,
    }),
    connectSmartRecruiters: builder.mutation({
      query: smartrecruiters_company_id => ({
        url: '/smartrecruiters/connect',
        method: 'POST',
        body: { smartrecruiters_company_id },
      }),
      transformResponse: ({ result }) => result,
    }),

    getRefereePdf: builder.query({
      queryFn: async ({ refereeId, token }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/token/${token}/referee/${refereeId}/pdf`,
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, `checkmate_assessment_${refereeId}`);
        return { data: null };
      },
    }),
    getCandidateCheckResult: builder.query({
      queryFn: async ({ token, candidate, check }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/token/${token}/checks/${check.id}/result`,
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, `${candidate.first_name}_${candidate.last_name}-${check.type}`);
        return { data: null };
      },
    }),
  }),
});

export const {
  useGetBullhornCandidateQuery,
  useGetJobAdderCandidateQuery,
  useConnectSmartRecruitersMutation,
  useLazyGetCandidateCheckResultQuery,
  useSendRefereesReportsByTokenMutation,
  useLazyGetRefereePdfQuery,
  useGetRefereesQuery,
  useGetApplicationQuery,
  useStartApplicationByTokenMutation,
} = partnerApi;
