import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from './AdminNavbar.module.scss';
import cn from 'classnames';

export const AdminNavbar = () => (
  <div className={styles.root}>
    <div className={styles.left}>
      <Link to="/dashboard" className={styles.logo}>
        <img
          className={cn(styles.logo, 'u-margin-bottom--0')}
          src="./assets/images/checkmate/icon-label.svg"
          alt="checkmate"
        />
      </Link>
      <div className={cn(styles.hideXS, styles.links)}>
        <NavLink to="/admin/check_types">Check Types</NavLink>
        <NavLink to="/admin/companies">Companies</NavLink>
        <NavLink to="/admin/users">Users</NavLink>
        <NavLink to="/admin/candidates">Candidates</NavLink>
        <NavLink to="/admin/checks">Candidate Checks</NavLink>
        <NavLink to="/admin/reporting">Reporting</NavLink>
        <NavLink to="/admin/log">Logs</NavLink>
      </div>
    </div>
  </div>
);
