import { useEffect, useRef, useState } from 'react';
import styles from './AssessmentLauncher.module.scss';
import { CandidateDetailsForm } from 'components/CandidateDetailsForm/CandidateDetailsForm';
import { ChecksSelector } from 'components/ChecksSelector/ChecksSelector';
import { useGetJobAdderCandidateQuery, useGetBullhornCandidateQuery } from 'api/partner-api';
import {
  useGetCandidateQuery,
  useGetCompanyChecksQuery,
  useGetBrandsQuery,
  useGetUsersQuery,
  useGetFormsQuery,
  useGetCompanyQuery,
  useGetBundlesQuery,
  useCreateCandidateMutation,
} from 'api/partner-v1-api';
import { Button } from 'components/FormComponents';
import { Loading } from 'components/Loading/Loading';
import { Partners } from 'constants/partners';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from 'hooks/query-params';
import { usePartnerV1UploadActions } from 'hooks/upload-actions';

const Steps = {
  CandidateDetails: 'candidate-details',
  CheckDetails: 'check-details',
};

export const AssessmentLauncher = () => {
  const navigate = useNavigate();

  const [bullhornCandidateId, jobAdderApplicationId] = useQueryParams([
    'EntityID',
    'applicationId',
  ]);
  const [partnerApplicationId, setPartnerApplicationId] = useState();

  const { token, partner } = useParams();

  const { data: candidate } = useGetCandidateQuery(
    { token, applicationId: partnerApplicationId },
    { skip: !partnerApplicationId },
  );

  const { data: brands } = useGetBrandsQuery(token, { skip: !partnerApplicationId });
  const { data: forms, isLoading: loadingForms } = useGetFormsQuery(token, {
    skip: !partnerApplicationId,
  });
  const { data: users } = useGetUsersQuery(token, { skip: !partnerApplicationId });
  const { data: company } = useGetCompanyQuery(token, { skip: !partnerApplicationId });
  const { data: companyChecks, isLoading: loadingCompanyChecks } = useGetCompanyChecksQuery(token, {
    skip: !partnerApplicationId,
  });
  const { data: bundles } = useGetBundlesQuery(token, { skip: !partnerApplicationId });

  const { data: jobAdderCandidate } = useGetJobAdderCandidateQuery(
    { token, partnerApplicationId: jobAdderApplicationId },
    { skip: !jobAdderApplicationId || partner != Partners.JobAdder },
  );
  const { data: bullhornCandidate } = useGetBullhornCandidateQuery(
    { token, partnerApplicationId: bullhornCandidateId },
    { skip: !bullhornCandidateId || partner != Partners.Bullhorn },
  );

  const { getUpload, signUpload, createUpload, deleteUpload } = usePartnerV1UploadActions(token);

  const [createCandidate] = useCreateCandidateMutation();

  const [defaultCandidateData, setDefaultCandidateData] = useState();

  const [candidateData, setCandidateData] = useState({});
  const [checksValues, setChecksValues] = useState({});

  const [valid, setValidity] = useState(false);

  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(Steps.CandidateDetails);

  const candidateDatailsRef = useRef(null);
  const selectorRef = useRef(null);

  // Set Bullhorn Details
  useEffect(() => {
    if (!bullhornCandidate) return;

    setPartnerApplicationId(bullhornCandidateId);
    setDefaultCandidateData(bullhornCandidate.candidate);
    setChecksValues(bullhornCandidate.checks_values);
    setLoading(false);
  }, [bullhornCandidate]);

  // Set JobAdder Details
  useEffect(() => {
    if (!jobAdderCandidate) return;

    setPartnerApplicationId(jobAdderApplicationId);
    setDefaultCandidateData(jobAdderCandidate.candidate);
    setChecksValues(jobAdderCandidate.checks_values);
    setLoading(false);
  }, [jobAdderCandidate]);

  // Redirect if candidate exists
  useEffect(() => {
    if (candidate) navigate(`/assessments/progress/${partner}/${token}${window.location.search}`);
  }, [candidate]);

  const submit = async checksData => {
    setLoading(true);
    try {
      setDefaultCandidateData(candidateData);
      await createCandidate({
        token,
        params: {
          candidate: {
            ...candidateData,
            ...checksData.candidate,
            partner_application_id: partnerApplicationId,
          },
          checks: checksData.checks,
          checks_values: checksValues,
        },
      });
      navigate(`/assessments/progress/${partner}/${token}${window.location.search}`);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const toggleStep = () => {
    switch (activeStep) {
      case Steps.CandidateDetails:
        candidateDatailsRef.current.submit();
        break;
      case Steps.CheckDetails:
        setActiveStep(Steps.CandidateDetails);
        break;
    }
  };

  const submitCandidateData = data => {
    setCandidateData(data);
    setActiveStep(Steps.CheckDetails);
  };

  if (loading || loadingForms || loadingCompanyChecks) return <Loading active={true} />;

  return (
    <div className={styles.root}>
      <div
        className={cn(styles.content, styles.candidateDetails, {
          [styles.candidateDetailsHidden]: activeStep !== Steps.CandidateDetails,
        })}>
        <CandidateDetailsForm
          ref={candidateDatailsRef}
          users={users}
          company={company}
          className={styles.candidateDetails}
          defaultValues={defaultCandidateData}
          onSubmit={submitCandidateData}
        />
        <Button icon="arrow-right" className="u-width-100" large onClick={toggleStep}>
          Select Checks
        </Button>
      </div>
      <div
        className={cn(styles.content, styles.checkDetails, {
          [styles.checkDetailsHidden]: activeStep !== Steps.CheckDetails,
        })}>
        <ChecksSelector
          ref={selectorRef}
          company={company}
          bundles={bundles}
          brands={brands}
          companyChecks={companyChecks}
          forms={forms}
          setValidity={setValidity}
          onSubmit={submit}
          allowUploads
          getUpload={getUpload}
          deleteUpload={deleteUpload}
          signUpload={signUpload}
          createUpload={createUpload}
        />
        <Button
          className="u-width-100 u-margin-top--large"
          large
          disabled={!valid}
          onClick={() => selectorRef.current.submit()}>
          Launch Checks
        </Button>
        <Button type="outline" className="u-width-100 u-margin-top" large onClick={toggleStep}>
          Back
        </Button>
      </div>
    </div>
  );
};
