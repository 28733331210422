import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Select, InputGroup, Textarea, Button } from 'components/FormComponents';
import styles from './AddHistoryModal.module.scss';
import { HistoryRow } from 'components/HistoryRow/HistoryRow';
import { useForm } from 'react-hook-form';

export const AddHistoryModal = ({
  visible,
  candidate,
  company,
  user,
  companyChecks,
  onSave = () => {},
  onClose = () => {},
}) => {
  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    onSave({ ...data, user_name: user.name });
    reset();
    onClose();
  };

  return (
    <AnimatedModal
      title="Add history record"
      visible={visible}
      onClose={onClose}
      className={styles.modal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup title="Type" className="u-margin-bottom--large">
          <Select name="type" register={register} value={watch('type')} useDefault>
            <Select.Item value={27}>Note</Select.Item>
            <Select.Item value={28}>Info</Select.Item>
            <Select.Item value={29}>Success</Select.Item>
            <Select.Item value={30}>Warning</Select.Item>
            <Select.Item value={31}>Error</Select.Item>
          </Select>
        </InputGroup>
        <Textarea
          register={register}
          name="message"
          validators={{ required: true }}
          error={errors?.message?.type}
          inputProps={{ rows: 5 }}
          placeholder="Enter your text"
        />
        <div className={styles.previewText}>preview</div>
        <HistoryRow
          company={company}
          candidate={candidate}
          entry={{ user_name: user.name, message: watch('message'), type: watch('type') }}
          companyChecks={companyChecks}
          className={styles.previewRow}
        />

        <div className="u-flex-box u-flex-justify-end">
          <Button submit className="u-margin-top--large u-width-100">
            Save
          </Button>
        </div>
      </form>
    </AnimatedModal>
  );
};
