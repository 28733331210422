import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  EditableTitle,
  Input,
  InputGroup,
  Checkbox,
  JsonInput,
  Textarea,
} from 'components/FormComponents';
import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import cn from 'classnames';
import { useEffect } from 'react';
import { Select } from 'components/FormComponents';

export const CheckTypeEdit = ({ checkType, onClose = () => {}, onSave = () => {} }) => {
  const {
    control,
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Reset form on check change
  useEffect(() => {
    reset(checkType);
  }, [checkType]);

  const selectCheckTypeOptions = [
    { value: 'Credit background checks', label: 'Credit background checks' },
    { value: 'Criminal record check', label: 'Criminal record check' },
    { value: 'Employment checks', label: 'Employment checks' },
    { value: 'Identity check', label: 'Identity check' },
    { value: 'MVR check', label: 'MVR check' },
    {
      value: 'Professional License & Education Background Checks',
      label: 'Professional License & Education Background Checks',
    },
    { value: 'Reference checks', label: 'Reference checks' },
    { value: 'Other checks', label: 'Other checks' },
  ];

  return (
    <form className="card card-with-border u-width-75" onSubmit={handleSubmit(onSave)}>
      <div className="u-padding u-padding-bottom--0">
        <div className={cn('u-flex-box', 'u-flex-align-center')}>
          <EditableTitle register={register} name="title" value={watch('title')} />
        </div>
        <p className="t-small">Configure and manage the check below</p>
      </div>
      <hr />
      <div className="u-margin">
        <InputGroup title="ID">
          <Input disabled={true} type="number" name="id" register={register} />
        </InputGroup>

        <InputGroup
          title="Slug"
          description='code of check. No spaces, use "_" (underscore) instead'>
          <Input name="slug" register={register} />
        </InputGroup>

        <InputGroup title="Internal description" description="What's the purpose of the check.">
          <Textarea name="internal_description" register={register} inputProps={{ rows: 4 }} />
        </InputGroup>

        <InputGroup title="NZD Pricing" description="Price in NZD">
          <Input
            type="number"
            name="pricing_nzd"
            register={register}
            inputProps={{ step: '0.01' }}
          />
        </InputGroup>

        <InputGroup title="USD Pricing" description="Price in USD">
          <Input
            type="number"
            name="pricing_usd"
            register={register}
            inputProps={{ step: '0.01' }}
          />
        </InputGroup>

        <InputGroup title="AUD Pricing" description="Price in AUD">
          <Input
            type="number"
            name="pricing_aud"
            register={register}
            inputProps={{ step: '0.01' }}
          />
        </InputGroup>

        <InputGroup title="Cost" description="Cost of check in NZD">
          <Input type="number" name="cost" register={register} inputProps={{ step: '0.01' }} />
        </InputGroup>

        <InputGroup
          title="Icon"
          description={
            <span className="t-tiny text-secondary">
              Icon name of{' '}
              <a
                className="u-link dark"
                href="https://fontawesome.com/search?q=bank&o=r&m=free&s=solid">
                Font Awesome
              </a>
            </span>
          }>
          <Input name="icon" register={register} />
        </InputGroup>

        <InputGroup title="Logo" description="URL of logo. Host it on AWS bucket">
          <Input name="logo" register={register} />
        </InputGroup>

        <InputGroup
          title="Candidate tab"
          description='Last part of URL when going to candidate profile. Default to "background_checks"'>
          <Input name="candidate_tab" register={register} />
        </InputGroup>

        <InputGroup title="Check configuration" description="Configuration for Check">
          <Controller
            control={control}
            name="configuration"
            render={({ field: { onChange, value } }) => (
              <JsonInput value={value} onChange={onChange} />
            )}
          />
        </InputGroup>

        <InputGroup
          title="Introduction"
          description="Check description to show candidate (Allows HTML)">
          <Textarea name="introduction" register={register} inputProps={{ rows: 4 }} />
        </InputGroup>

        <InputGroup title="Consent" description="Consent to show candidate (Allows HTML)">
          <Textarea name="consent" register={register} inputProps={{ rows: 4 }} />
        </InputGroup>

        <InputGroup title="Result type" description='["file", "link"]. Default to "file"'>
          <Input name="result_type" register={register} />
        </InputGroup>

        <InputGroup
          title="Provider launcher class"
          description="Class for launching when check is created">
          <Input name="provider_launcher_class" register={register} />
        </InputGroup>

        <InputGroup title="Result flag" description="Labels for checks with results option">
          <EditableSettings
            title="Enable result flag"
            open={watch('has_result_flag')}
            toggleable
            register={register}
            name="has_result_flag"
            className="u-margin-0">
            <InputGroup title="True Label" description="Label for when check has results">
              <Input name="true_flag_label" register={register} />
            </InputGroup>
            <InputGroup title="False label" description="Label for when check don't have results">
              <Input name="false_flag_label" register={register} />
            </InputGroup>
          </EditableSettings>
        </InputGroup>

        <InputGroup title="Sub-checks" description="Create sub-checks to update them individually">
          <EditableSettings
            title="Enable Sub-checks"
            open={watch('has_subchecks')}
            toggleable
            register={register}
            name="has_subchecks"
            className="u-margin-0">
            <InputGroup
              title="Sub check config"
              description="Label for when check don't have results">
              <Controller
                control={control}
                name="sub_checks_config"
                render={({ field: { onChange, value } }) => (
                  <JsonInput value={value} onChange={onChange} />
                )}
              />
            </InputGroup>
          </EditableSettings>
        </InputGroup>

        <InputGroup title="Background job" description="Job to launch/complete check in background">
          <EditableSettings
            title="Enable background job"
            open={watch('has_job')}
            toggleable
            register={register}
            name="has_job"
            className="u-margin-0">
            <InputGroup title="Job Class" description="PHP job class">
              <Input name="job_class" register={register} />
            </InputGroup>
            <Checkbox
              name="automatic_job"
              register={register}
              checked={watch('automatic_job')}
              label="Is automatic"
            />
          </EditableSettings>
        </InputGroup>

        <InputGroup title="Provider form class" description="PHP class of provider form">
          <Input name="provider_form_class" register={register} />
        </InputGroup>

        <InputGroup title="Client fields" description="Fields asked to client prior launching">
          <Controller
            control={control}
            name="client_fields"
            render={({ field: { onChange, value } }) => (
              <JsonInput value={value} onChange={onChange} />
            )}
          />
        </InputGroup>

        <InputGroup title="Client required fields" description="Require fields from the above">
          <Controller
            control={control}
            name="client_required_fields"
            render={({ field: { onChange, value } }) => (
              <JsonInput value={value} onChange={onChange} />
            )}
          />
        </InputGroup>

        <InputGroup title="Candidate fields" description="Fields asked to candidate">
          <Controller
            control={control}
            name="candidate_fields"
            render={({ field: { onChange, value } }) => (
              <JsonInput value={value} onChange={onChange} />
            )}
          />
        </InputGroup>

        <InputGroup title="Candidate required fields" description="Require fields from the above">
          <Controller
            control={control}
            name="candidate_required_fields"
            render={({ field: { onChange, value } }) => (
              <JsonInput value={value} onChange={onChange} />
            )}
          />
        </InputGroup>

        <InputGroup title="ID types" description="ID to collect from candidate">
          <Controller
            control={control}
            name="id_types"
            render={({ field: { onChange, value } }) => (
              <JsonInput value={value} onChange={onChange} />
            )}
          />
        </InputGroup>

        <InputGroup
          title="Extra result details"
          description="Extra inputs for Support team to fill on complete">
          <Controller
            control={control}
            name="extra_result_details"
            render={({ field: { onChange, value } }) => (
              <JsonInput value={value} onChange={onChange} />
            )}
          />
        </InputGroup>

        <InputGroup
          title="Extra reporting columns"
          description='Extra columns to show on reporting from "Extra result details"'>
          <Controller
            control={control}
            name="extra_reporting_columns"
            render={({ field: { onChange, value } }) => (
              <JsonInput value={value} onChange={onChange} />
            )}
          />
        </InputGroup>

        <InputGroup
          title="Countries Available"
          description="Countries where the check is available to use">
          <Controller
            control={control}
            name="countries_available"
            render={({ field: { onChange, value } }) => (
              <JsonInput value={value} onChange={onChange} />
            )}
          />
        </InputGroup>

        <InputGroup
          title="Provider Name"
          description="Name of the external provider who process this check (if any)">
          <Input name="provider_name" register={register} />
        </InputGroup>

        <InputGroup title="Check Group" description="Group for filtering in store-front">
          <Select name="check_group" value={watch('check_group')} register={register}>
            {selectCheckTypeOptions.map(option => (
              <Select.Item key={option.value} value={option.value}>
                {option.label}
              </Select.Item>
            ))}
          </Select>
        </InputGroup>

        <InputGroup
          title="Document Uploads "
          description="Array of document uploads config (if any)">
          <Controller
            control={control}
            name="document_uploads"
            render={({ field: { onChange, value } }) => (
              <JsonInput value={value} onChange={onChange} />
            )}
          />
        </InputGroup>

        <Checkbox
          name="candidate_photo"
          register={register}
          checked={watch('candidate_photo')}
          label="Take candidate photo"
        />
        <Checkbox
          name="signature_required"
          register={register}
          checked={watch('signature_required')}
          label="Requires signature"
        />
        <Checkbox
          name="has_expiration_date"
          register={register}
          checked={watch('has_expiration_date')}
          label="Has expiration date"
        />
        <Checkbox
          name="set_submitted_by_provider"
          register={register}
          checked={watch('set_submitted_by_provider')}
          label='Set as "Submitted" when submitted by candidate'
        />
        <Checkbox
          name="complete_on_submit"
          register={register}
          checked={watch('complete_on_submit')}
          label='Set as "Completed" when submitted by candidate'
        />
        <Checkbox
          name="has_identity_verification"
          register={register}
          checked={watch('has_identity_verification')}
          label="Use facial recognition"
        />
        <Checkbox
          name="is_manual"
          register={register}
          checked={watch('is_manual')}
          label="Manually completed by Support team"
        />
        <Checkbox
          name="is_refundable"
          register={register}
          checked={watch('is_refundable')}
          label="Credit can be refund after candidate's submission"
        />
      </div>
      <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
        <Button onClick={onClose} type="secondary">
          Cancel
        </Button>
        <Button submit disabled={true}>
          Save
        </Button>
      </div>
    </form>
  );
};
