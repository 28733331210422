import { useState } from 'react';
import styles from './ImagePicker.module.scss';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { useDropzone } from 'react-dropzone';
import { Loader } from 'components/Loader/Loader';
import { uploadSigned } from 'helpers/aws-s3';
import { useEffect } from 'react';
import * as mime from 'react-native-mime-types';
import {
  useCreateUploadMutation,
  useLazyGetUploadQuery,
  useLazySignUploadQuery,
  useDeleteUploadMutation,
} from 'api/upload-api';
import cn from 'classnames';

export const ImagePicker = ({
  value,
  disabled,
  error,
  modelParams = {},
  metadata = {},
  bucketFolder = 'answers',
  onChange = () => {},
}) => {
  const [imageUrl, setImageUrl] = useState();

  const [getUpload] = useLazyGetUploadQuery();
  const [signUpload] = useLazySignUploadQuery();
  const [createUpload] = useCreateUploadMutation();
  const [deleteUpload] = useDeleteUploadMutation();

  const [fetching, setFetching] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (value) {
      setFetching(true);
      getUpload(value)
        .unwrap()
        .then(result => {
          setImageUrl(result.url);
          setFetching(false);
        });
    } else {
      setImageUrl(null);
    }
  }, [value]);

  /**
   * --- On upload ---
   * 1) Get file meta
   * 2) Sign upload
   * 3) Upload to s3
   * 4) Create upload
   * 5) Set value
   */
  const onDrop = async acceptedFiles => {
    setUploading(true);
    setImageLoading(true);
    const file = acceptedFiles[0];
    if (!file) return;

    // Sign upload
    const params = {
      file_name: file.name,
      file_type: file.type,
      file_ext: mime.extension(file.type),
      bucket_folder: bucketFolder,
    };

    const signedFile = await signUpload(params).unwrap();

    // Upload to S3
    await uploadSigned(signedFile.url, file, file.type);

    // Save model
    const uploadData = {
      ...modelParams,
      metadata,
      file_name: signedFile.file_name,
      file_type: signedFile.file_type,
      hash_key: signedFile.key,
    };

    const { data: upload } = await createUpload(uploadData);

    setImageUrl(upload.url);
    onChange(upload.id);
    setUploading(false);
  };

  const onRemove = async () => {
    if (value) {
      setUploading(true);
      await deleteUpload(value);
      setUploading(false);
    }
    setImageUrl(null);
    onChange(null);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'image/*': [] } });

  const errorBorder = error ? { border: '1px solid red' } : null;
  return (
    <>
      <div className={cn(styles.root, 'margin-top')} style={errorBorder}>
        {uploading || (fetching && <Loader />)}

        {imageUrl && !uploading && !fetching && (
          <div>
            {imageLoading && <Loader />}
            <img
              src={imageUrl}
              className={styles.imagePreview}
              onLoad={() => setImageLoading(false)}
            />
            {!imageLoading && !disabled && (
              <div className={styles.removeImageIconWrapper} onClick={onRemove}>
                <FeatherIcon className={styles.removeImageIcon} icon="trash-2" size={40} />
              </div>
            )}
          </div>
        )}

        {!imageUrl && !uploading && !fetching && (
          <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            <FeatherIcon icon="image" size={30} className={styles.icon} />
            <p>
              Drag or <span className="link">browse</span> to upload
            </p>
          </div>
        )}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </>
  );
};

ImagePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};
