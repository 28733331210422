import { Input, InputGroup, Textarea } from 'components/FormComponents';
import { useWatch } from 'react-hook-form';
import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import cn from 'classnames';

export const LeadGeneration = ({ register, control }) => {
  const followUpQuestionActiveValue = useWatch({ control, name: 'follow_up_question_active' });
  const engagementEmailNotificationValue = useWatch({
    control,
    name: 'custom_engagement_email_active',
  });

  return (
    <div className="background-secondary u-padding">
      <div className={cn('card', 'card-with-border', 'u-padding')}>
        <EditableSettings
          title="Use referees engagement"
          open={followUpQuestionActiveValue}
          toggleable
          register={register}
          name="follow_up_question_active"
          className="u-margin-bottom">
          <InputGroup title="Follow Up Yes/No Question">
            <Textarea
              name="follow_up_question"
              register={register}
              placeholder="Would you be inerested in hearing about new opportunities?"
              inputProps={{ rows: 3 }}
            />
          </InputGroup>
        </EditableSettings>
        <EditableSettings
          title="Use custom notification email"
          open={engagementEmailNotificationValue}
          toggleable
          register={register}
          name="custom_engagement_email_active">
          <InputGroup title="Email address">
            <Input
              register={register}
              name="engagement_email"
              placeholder="e.g. brad@checkmate.tech"
            />
          </InputGroup>
        </EditableSettings>
      </div>
    </div>
  );
};
