import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const formsApi = createApi({
  reducerPath: 'formsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/forms`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getFormFromFile: builder.mutation({
      query: data => ({
        url: '/from_file',
        method: 'POST',
        body: data,
      }),
      transformResponse: ({ result }) => result,
    }),
    getForm: builder.query({
      query: id => `/${id}`,
      transformResponse: ({ result }) => result,
    }),
    getFormsById: builder.query({
      query: ids => `/ids/${ids.join(',')}`,
      transformResponse: ({ result }) => result,
    }),
    getFormAnswersForCandidate: builder.query({
      query: ({ id, candidateId }) => `/${id}/candidate/${candidateId}/answers`,
      transformResponse: ({ result }) => result,
    }),
    getFormByTypeformId: builder.query({
      query: typeformId => `/typeform/${typeformId}`,
      transformResponse: ({ result }) => result,
    }),
    getTypeformAnswersForCandidate: builder.query({
      query: candidateId => `/candidate/${candidateId}/typeform/answers`,
      transformResponse: ({ result }) => result,
    }),
    getTypeformAnswersByToken: builder.query({
      query: token => `/token/${token}/typeform/answers`,
      transformResponse: ({ result }) => result,
    }),
    getFormByTypeformIdByToken: builder.query({
      query: ({ token, typeformId }) => `/token/${token}/typeform/${typeformId}`,
      transformResponse: ({ result }) => result,
    }),
  }),
});

export const {
  useLazyGetFormByTypeformIdByTokenQuery,
  useLazyGetTypeformAnswersByTokenQuery,
  useLazyGetFormsByIdQuery,
  useGetTypeformAnswersForCandidateQuery,
  useGetFormByTypeformIdQuery,
  useGetFormQuery,
  useGetFormAnswersForCandidateQuery,
  useGetFormFromFileMutation,
} = formsApi;
