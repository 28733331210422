import FeatherIcon from 'feather-icons-react';
import styles from './Modal.module.scss';
import { useClickOutside } from '../../hooks/click-outside';
import cn from 'classnames';
import { useEffect, useRef } from 'react';

export const Modal = ({ show, fullWindow, children, disableClose = false, onClose = () => {} }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'auto';
  }, [show]);

  const handleClose = () => {
    if (disableClose) return;
    onClose();
  };

  useClickOutside(wrapperRef, handleClose);

  return (
    <div
      className={cn(styles.root, { [styles.rootVisible]: show, [styles.rootLarge]: fullWindow })}
      ref={wrapperRef}>
      {show && (
        <div className={styles.content}>
          <div className={styles.child}>
            {!disableClose && (
              <FeatherIcon icon="x" onClick={onClose} className={styles.closeBtn} />
            )}
            {children}
          </div>
        </div>
      )}
    </div>
  );
};
