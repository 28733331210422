import { CombinedReportHeader } from './components/CombinedReportHeader/CombinedReportHeader';
import { CombinedQuestionsAnswers } from './components/CombinedQuestionsAnswers/CombinedQuestionsAnswers';
import styles from './CombinedReport.module.scss';

export const CombinedReport = props => (
  <div className={styles.root}>
    <CombinedReportHeader {...props} />
    <CombinedQuestionsAnswers {...props} />
  </div>
);
