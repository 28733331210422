import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, InputGroup, Textarea } from 'components/FormComponents';
import cn from 'classnames';
import { useState } from 'react';

export const ReplaceRefereeModal = ({
  referee,
  visible,
  onClose = () => {},
  onCancel = () => {},
  onReplacementRequest = () => {},
}) => {
  const [message, setMessage] = useState();

  return (
    <AnimatedModal small={true} visible={visible} onClose={onClose} title="Referee replacement">
      <p className="u-margin-y--large t-body">
        You're about to replace {referee.name}. You can: <br />
        <ul className="u-margin-top--small">
          <li>
            Click "Send request" to send an email to the candidate to replace {referee.first_name}.{' '}
          </li>
          <li>
            Click "Cancel referee" to cancel {referee.first_name} and enter a new one manually
          </li>
        </ul>
      </p>
      <InputGroup title="Optional message for candidate">
        <Textarea
          inputProps={{ value: message, onChange: e => setMessage(e.target.value), rows: 5 }}
        />
      </InputGroup>
      <div className={cn('d-flex', 'justify-content-between')}>
        <Button onClick={onCancel} type="secondary">
          Cancel referee
        </Button>
        <Button onClick={() => onReplacementRequest(message)}>Send request</Button>
      </div>
    </AnimatedModal>
  );
};
