import { useEffect, useState } from 'react';

export const useMobileSize = () => {
  const [mobile, setMobile] = useState(false);

  const checkMobile = () => {
    const isMobile = window.innerWidth < 768;
    setMobile(isMobile);
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  });

  return {
    mobile,
  };
};
