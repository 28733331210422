import { useEffect, useState } from 'react';
import { errorRouter } from 'helpers/error-router';
import { useSendRefereeRequestEmailByTokenMutation } from 'api/referees-api';
import { Modal } from 'components/Modal/Modal';
import { Loading } from 'components/Loading/Loading';
import styles from './RefereeRequest.module.scss';
import { useDocumentTitle } from 'hooks/document-title';
import { useNavigate, useParams } from 'react-router-dom';

const RefereeRequest = () => {
  useDocumentTitle('Checkmate - Reference Checking and Background Screening Software');

  const navigate = useNavigate();

  const { token } = useParams();

  const [sendRefereeRequestEmailByToken] = useSendRefereeRequestEmailByTokenMutation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    sendRefereeRequestEmailByToken(token).then(
      success => setLoading(false),
      error => navigate(errorRouter(error)),
    );
  }, []);

  return (
    <div>
      <Loading active={loading} />
      <Modal show={!loading} disableClose>
        <div className={styles.root}>
          <img src="assets/images/icons/check.svg" alt="check" />
          <p className={styles.title}>Reminder sent.</p>
        </div>
      </Modal>
    </div>
  );
};

export default RefereeRequest;
