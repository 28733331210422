import { useEffect, useState } from 'react';
import { ProfileCard } from '../ProfileCard/ProfileCard';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import { CreditsBalanceDetails } from 'components/CreditsBalanceDetails/CreditsBalanceDetails';
import { NavLink, Link } from 'react-router-dom';
import { Permissions } from 'constants/permissions';
import { useAccessControl } from 'hooks/access-control';
import styles from './AppNavbar.module.scss';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'api/user-permissions-slice';

export const AppNavbar = ({
  user,
  company,
  companyChecks,
  authentication,
  fillOnScroll,
  onSignOut = () => {},
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const permissions = useSelector(selectPermissions);
  const hasAccess = useAccessControl(permissions);

  useEffect(() => {
    const handleScroll = e => {
      let target = e.target || e.srcElement;
      let document = target.scrollingElement || target.documentElement;
      var scrollTop = document.scrollTop;
      setScrolled(!!scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleProfileCard = e => {
    if (e) e.preventDefault();
    setShowProfile(!showProfile);
  };

  return (
    <div className={cn(styles.root, { [styles.rootNotScrolled]: !scrolled && fillOnScroll })}>
      <ProfileCard
        user={user}
        visible={showProfile}
        onClose={() => setShowProfile(false)}
        onSignOut={onSignOut}
      />
      <div className={styles.left}>
        <Link to="/dashboard" className={styles.logo}>
          <img
            className={cn(styles.logo, 'u-margin-bottom--0')}
            src="./assets/images/checkmate/icon-label.svg"
            alt="checkmate"
          />
        </Link>
        <div className={cn(styles.hideXS, styles.links)}>
          <NavLink to="/dashboard" className="u-link dark">
            Dashboard
          </NavLink>
          {hasAccess(Permissions.FormsWrite) && (
            <NavLink to="/questionnaires" className="u-link dark">
              Questionnaire
            </NavLink>
          )}
          <NavLink to="/reporting" className="u-link dark">
            Reporting
          </NavLink>
          {company.renewal_dashboard_enabled && (
            <NavLink to="/renewals" className="u-link dark">
              Renewals
            </NavLink>
          )}
          {authentication.admin && <Link to="/admin/candidates">Admin</Link>}
        </div>
      </div>
      <div className={styles.right}>
        <CreditsBalanceDetails companyChecks={companyChecks} />
        <UserAvatar onClick={toggleProfileCard}>{user ? user.first_name[0] : ''}</UserAvatar>
      </div>
    </div>
  );
};
