import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = [];

export const getTags = createAsyncThunk('getTags', async () => {
  const res = await apiRequest(`tags`, {}, 'get');
  return res.result;
});

export const createTag = createAsyncThunk('createTag', async data => {
  const res = await apiRequest('tags', data, 'post');
  return res.result;
});

export const deleteTag = createAsyncThunk('deleteTag', async id => {
  await apiRequest(`tags/${id}`, {}, 'delete');
  return id;
});

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTags.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(createTag.fulfilled, (state, { payload }) => {
        return [...state, payload];
      })
      .addCase(deleteTag.fulfilled, (state, { payload: id }) => {
        return state.filter(tag => tag.id !== id);
      });
  },
});

export const selectTags = state => state.tags;

export default tagsSlice.reducer;
