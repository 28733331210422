import { motion } from 'framer-motion';
import { IdVerificationScreens } from '../constants/id-verification-screens';
import QRCode from 'react-qr-code';
import cn from 'classnames';
import { CustomLink } from 'views/CandidateApp/components/CustomLink/CustomLink';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';

const QRScanning = ({ token, brand }) => (
  <motion.div
    key={IdVerificationScreens.QRScanning}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ ease: 'easeInOut', duration: 0.7 }}
    exit={{ opacity: 0 }}>
    <CandidatePage.Card
      className={cn('d-flex', 'align-items-center', 'justify-content-center', 'flex-column')}>
      <QRCode
        size={196}
        value={`${process.env.REACT_APP_WEB_URL}/form_submission/candidate/identity_verification/${IdVerificationScreens.Selfie}/${token}`}
      />
      <h1 className={cn('title-4', 'u-margin-top--large')}>Scan QR code with mobile device</h1>
      <p className={cn('u-margin-top', 'u-text-centered', 't-small')}>
        For the most effective liveness checks, it's best to complete the ID verification process
        from a mobile device.
      </p>

      <CustomLink
        href={`/form_submission/candidate/identity_verification/${IdVerificationScreens.Selfie}/${token}`}
        className="u-margin-top"
        brand={brand}
        target="_self">
        I don't have a mobile device
      </CustomLink>
    </CandidatePage.Card>
  </motion.div>
);

export default QRScanning;
