import styles from './OpinionScale.module.scss';
import { useState, useEffect } from 'react';
import cn from 'classnames';

export const OpinionScale = ({
  options: { use_custom_steps, steps_start, steps_end, label_start, label_end },
  value,
  disabled,
  onChange,
}) => {
  const [scale, setScale] = useState([]);

  useEffect(() => {
    const start = use_custom_steps && steps_start ? parseInt(steps_start) : 1;
    const end = use_custom_steps && steps_end ? parseInt(steps_end) : 10;
    const array = Array(end - start + 1)
      .fill()
      .map((_, index) => start + index);
    setScale(array);
  }, [use_custom_steps, steps_start, steps_end]);

  const handleClick = number => {
    if (disabled) return;
    onChange(number);
  };

  return (
    <div className={cn(styles.root, { [styles.rootWithLabel]: label_start || label_end })}>
      <div className={styles.scaleWrapper}>
        {scale.map(number => (
          <span
            key={number}
            className={cn('t-body', styles.option, {
              [styles.disabled]: disabled,
              [styles.optionSelected]: value === number,
            })}
            onClick={() => handleClick(number)}>
            {number}
          </span>
        ))}
      </div>
      <label className={styles.labelStart}>{label_start}</label>
      <label className={styles.labelEnd}>{label_end}</label>
    </div>
  );
};
