import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { useForm } from 'react-hook-form';
import { Button, Input, InputGroup } from 'components/FormComponents';
import { useEffect } from 'react';
import { now } from 'helpers/date';
export const NewSubCheckModal = ({ check, visible, onCreate = () => {}, onClose = () => {} }) => {
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
  }, [visible]);

  const onSubmit = data => {
    onCreate({
      title: data.title,
      candidate_check_id: check.id,
      created_at: now(),
    });
  };

  return (
    <AnimatedModal visible={visible} onClose={onClose} title="Add Sub-check">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup title="Title" className="u-margin-top">
          <Input
            name="title"
            register={register}
            placeholder="Title"
            validators={{ required: true }}
            error={errors?.title?.type}
          />
        </InputGroup>

        <Button submit large className="u-width-100 u-margin-top--large">
          Create
        </Button>
      </form>
    </AnimatedModal>
  );
};
