import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputGroup, Input, Button, PhoneNumberPicker } from 'components/FormComponents';

export const RefereeDetails = ({ referee, company, onSubmit = () => {} }) => {
  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: referee });

  useEffect(() => reset(referee), [referee]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="u-padding--large">
      <div className="inline">
        <InputGroup title="First name">
          <Input
            name="first_name"
            register={register}
            validators={{ required: true }}
            placeholder="John"
          />
        </InputGroup>
        <InputGroup title="Last name">
          <Input
            name="last_name"
            register={register}
            validators={{ required: true }}
            placeholder="Doe"
          />
        </InputGroup>
      </div>
      <InputGroup title="Email">
        <Input
          name="email"
          register={register}
          validators={{ required: true }}
          placeholder="john@checkmate.com"
        />
      </InputGroup>
      <InputGroup title="Form">
        <Input name="form_name" register={register} disabled={true} />
      </InputGroup>
      <InputGroup title="Contact Number">
        <Controller
          control={control}
          name="contact_number"
          render={({ field: { onChange, value } }) => (
            <PhoneNumberPicker
              value={value}
              onChange={onChange}
              defaultCountry={company?.country}
            />
          )}
        />
      </InputGroup>
      <Button className="u-width-100 u-margin-top--large" submit>
        Update
      </Button>
    </form>
  );
};
