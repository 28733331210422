import { useEffect, useState } from 'react';
import RefereeCard from 'components/RefereeCard/RefereeCard';
import { RefereeReport } from 'components/RefereeReport/RefereeReport/RefereeReport';
import { CombinedReport } from 'components/RefereeReport/CombinedReport/CombinedReport';
import styles from './Reports.module.scss';
import { useLazyGetQuestionUploadQuery } from 'api/referees-api';
import { useGetFormByTypeformIdQuery, useGetTypeformAnswersForCandidateQuery } from 'api/forms-api';

const COMBINED_ID = 0;

export const Reports = ({
  check,
  referees,
  candidate,
  company,
  targetId,
  setLoading = () => {},
  getPdfReport = () => {},
}) => {
  const { data: form, isLoading: loadingForm } = useGetFormByTypeformIdQuery(
    check.details.typeform_id,
  );
  const { data: responses, isLoading: loadingResponses } = useGetTypeformAnswersForCandidateQuery(
    candidate.id,
  );

  const [downloadQuestionFile] = useLazyGetQuestionUploadQuery();

  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    setLoading(loadingForm || loadingResponses);
  }, [loadingForm, loadingResponses]);

  useEffect(() => {
    if (!targetId) return;

    const referee = referees?.find(referee => referee.id === parseInt(targetId));
    if (referee && referee.completed) setSelectedId(referee.id);
  }, [targetId, referees?.length]);

  const fileName = (id, combined) => {
    let name = `${candidate.first_name}_${candidate.last_name}`;
    if (!combined) {
      const referee = referees.find(referee => referee.id === id);
      name += `-${referee.first_name}_${referee.last_name}`;
    }
    return `${name}.pdf`;
  };

  const handleDownloadReport = (id, combined = false) => {
    const filename = fileName(id, combined);
    getPdfReport(id, filename, combined);
  };

  const handleDownloadQuestionFile = async url => {
    setLoading(true);
    await downloadQuestionFile({ id: selectedId, url }).unwrap();
    setLoading(false);
  };

  return (
    <div className={styles.root}>
      <div>
        <div className={styles.title}>Referee Reports</div>
        <div className={styles.subtitle}>A list of referees and their reference check results.</div>
        {check.details.referees > 1 && (
          <div>
            <RefereeCard
              title="COMBINED REPORT"
              name={check.details.referees + 'x Referees'}
              details={referees
                .filter(referee => !referee.replaced_by)
                .map(referee => referee.name)
                .join(' & ')}
              returnedId={COMBINED_ID}
              disabled={!check.completed_at}
              onClick={setSelectedId}
              onButtonClick={() => handleDownloadReport(candidate.id, true)}
              selected={selectedId === COMBINED_ID}
            />
            <div className={styles.divider}></div>
          </div>
        )}
        {referees.map(referee => (
          <RefereeCard
            key={referee.id}
            title="REFEREE NAME"
            name={referee.name}
            className={styles.card}
            details={referee.role + ' @ ' + referee.company_name}
            returnedId={referee.id}
            disabled={!referee.completed || referee.replaced_by}
            tag={
              referee.replaced_by
                ? `Replaced by ${referees.find(r => r.id === referee.replaced_by).name}`
                : false
            }
            onClick={setSelectedId}
            onButtonClick={handleDownloadReport}
            selected={referee.id === selectedId}
          />
        ))}
      </div>
      {responses && selectedId !== null && (
        <div className={styles.report}>
          {selectedId === COMBINED_ID ? (
            <CombinedReport
              check={check}
              candidate={candidate}
              referees={referees.filter(referee => !referee.replaced_by)}
              company={company}
              form={form}
              intelligent={form?.intelligent}
              responses={responses}
              onDownloadClick={handleDownloadReport}
            />
          ) : (
            <RefereeReport
              referee={referees.find(referee => referee.id === selectedId)}
              candidate={candidate}
              referenceCheck={check}
              form={form}
              answers={responses?.[selectedId]?.answers}
              onDownloadClick={handleDownloadReport}
              downloadQuestionFile={handleDownloadQuestionFile}
            />
          )}
        </div>
      )}
    </div>
  );
};
