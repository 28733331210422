import { IconButton } from 'components/IconButton/IconButton';
import styles from './RefereeCard.module.scss';
import cn from 'classnames';

const RefereeCard = ({
  title,
  name,
  details,
  hideButtons,
  returnedId,
  selected,
  request,
  disabled,
  tag,
  className,
  onClick = () => {},
  onButtonClick = () => {},
  onBehalfButtonClick = () => {},
}) => {
  const handleClick = () => {
    if (disabled) return;

    onClick(returnedId);
  };

  const handleButtonClick = e => {
    if (disabled) return;

    e.stopPropagation();
    onButtonClick(returnedId);
  };

  const handleButtonOnBehalf = e => {
    if (disabled) return;

    e.stopPropagation();
    onBehalfButtonClick(returnedId);
  };

  return (
    <div
      className={cn(styles.root, {
        [styles['--selected']]: selected,
        [styles['--disabled']]: disabled,
        [styles['--request']]: request,
        [className]: className,
      })}
      onClick={handleClick}>
      {tag && <span className={styles.tag}>{tag}</span>}
      <div className={styles.description}>
        <div className={styles.title}>{title}</div>
        <div className={styles.name}>{name}</div>
        <div className={styles.details}>{details}</div>
      </div>
      {!(hideButtons || disabled) && (
        <div>
          {request && (
            <div className="u-flex-box u-flex-direction-vertically u-flex-align-center u-flex-justify-between u-height-100">
              <IconButton
                className={cn(styles.actionButton, 'u-margin-bottom--small')}
                icon="file-text"
                tip="Answer on Behalf"
                onClick={handleButtonOnBehalf}
                tooltipPlacement="top"
              />
              <IconButton
                className={styles.actionButton}
                icon="send"
                tip="Send New Request to Referee"
                onClick={handleButtonClick}
              />
            </div>
          )}
          {!request && (
            <div className="u-flex-box u-flex-align-center u-flex-justify-center">
              <IconButton
                className={styles.actionButton}
                icon="download"
                tip="Download Report"
                onClick={handleButtonClick}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RefereeCard;
