import { useEffect, useState } from 'react';
import { useLazyGetAuditLogsQuery, useLazyDownloadAuditLogsQuery } from 'api/audit-logs-api';
import { Table } from 'components/Table/Table';
import { IconButton } from 'components/IconButton/IconButton';
import { KeyValueModal } from 'components/KeyValueModal/KeyValueModal';
import Moment from 'react-moment';
import cn from 'classnames';

export const AuditLogs = ({ setLoading = () => {} }) => {
  const [getAuditLogs] = useLazyGetAuditLogsQuery();
  const [downloadAuditLogs] = useLazyDownloadAuditLogsQuery();

  const [auditLogs, setAuditLogs] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, total_pages: 1 });

  const [showDataModal, setShowDataModal] = useState(false);
  const [logData, setLogData] = useState({});

  useEffect(() => {
    search({ page: 1 });
  }, []);

  const openLogData = data => {
    setLogData(data);
    setShowDataModal(true);
  };

  const search = async params => {
    setLoading(true);
    const { audit_logs, pagination } = await getAuditLogs(params).unwrap();
    setAuditLogs(audit_logs);
    setPagination(pagination);
    setLoading(false);
  };

  const download = async () => {
    setLoading(true);
    await downloadAuditLogs();
    setLoading(false);
  };

  return (
    <div className={cn('d-flex', 'justify-content-center')}>
      <KeyValueModal
        visible={showDataModal}
        onClose={() => setShowDataModal(false)}
        title="Data"
        data={logData}
      />

      <div className={cn('card--large', 'card', 'card-with-border', 'card-with-hidden-overflow')}>
        <div
          className={cn(
            'u-padding--large',
            'u-divider-bottom-border',
            'd-flex',
            'justify-content-between',
            'align-items-center',
          )}>
          <div>
            <div className={cn('d-flex', 'justify-content-between', 'align-items-center')}>
              <h1 className="title-2">Audit Logs</h1>
            </div>
            <p className="t-small u-margin-0">
              Review the activity of users in your Checkmate account.
            </p>
          </div>
          <IconButton icon="download" onClick={download} />
        </div>
        <div className={cn('background-secondary', 'u-padding')}>
          <Table.Context defaultQuery={{ page: 1 }} onQueryUpdate={search}>
            <Table.TableContent>
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header noBorder>Datetime</Table.Header>
                    <Table.Header noBorder>Actor</Table.Header>
                    <Table.Header noBorder>Entity</Table.Header>
                    <Table.Header noBorder>Action</Table.Header>
                    <Table.Header noBorder>Source</Table.Header>
                    <Table.Header noBorder>IP</Table.Header>
                    <Table.Header noBorder>Details</Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {auditLogs.map(auditLog => (
                    <Table.Row key={auditLog.id}>
                      <Table.Col>
                        <Moment format="MMM Do @h:mm:ss a">{auditLog.created_at}</Moment>
                      </Table.Col>
                      <Table.Col>{auditLog.user_name}</Table.Col>
                      <Table.Col>{auditLog.entity_name}</Table.Col>
                      <Table.Col>
                        <b>{auditLog.action}</b>
                      </Table.Col>
                      <Table.Col>{auditLog.source}</Table.Col>
                      <Table.Col>{auditLog.ip}</Table.Col>
                      <Table.Col>
                        {!Array.isArray(auditLog.data) && (
                          <IconButton icon="file-text" onClick={() => openLogData(auditLog.data)} />
                        )}
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Pagination {...pagination} />
              </Table>
            </Table.TableContent>
          </Table.Context>
        </div>
      </div>
    </div>
  );
};
