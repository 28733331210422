import cn from 'classnames';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { motion } from 'framer-motion';
import { IdVerificationScreens } from '../constants/id-verification-screens';
import moduleStyles from './../CandidateIdVerification.module.scss';
import { useRef, useState } from 'react';
import { OverlayFrame } from 'components/OverlayFrame/OverlayFrame';
import Webcam from 'react-webcam';
import styles from './Selfie.module.scss';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { uploadSigned } from 'helpers/aws-s3';
import { cropImage } from 'helpers/images';
import { analytics } from 'services/segment';

const Screens = {
  Intro: 'intro',
  SelfieCam: 'selfie_cam',
  SelfieConfirmation: 'selfie_confirmation',
  SelfieUploading: 'selfie_uploading',
};

export const Selfie = ({
  candidate,
  brand,
  redirect = () => {},
  setUpload = () => {},
  signUpload = () => {},
  createUpload = () => {},
}) => {
  const [screen, setScreen] = useState(Screens.Intro);
  const [photo, setPhoto] = useState();

  const [frameRec, setFrameRec] = useState();

  const onCapture = (photo, frameRec) => {
    setPhoto(photo);
    setFrameRec(frameRec);
    setScreen(Screens.SelfieConfirmation);
  };

  const onSave = async () => {
    setScreen(Screens.SelfieUploading);

    const croppedImage = await cropImage(photo, frameRec.width, frameRec.height);

    const signedUpload = await signUpload({
      file_name: `${candidate.id}_selfie.jpeg`,
      file_type: 'image/jpeg',
      file_ext: 'jpeg',
      bucket_folder: 'background_applications',
    });

    await uploadSigned(signedUpload.url, croppedImage, 'image/png', true);

    const upload = await createUpload({
      candidate_id: candidate.id,
      metadata: { selfie: true, idv: true },
      file_name: signedUpload.file_name,
      file_type: signedUpload.file_type,
      hash_key: signedUpload.key,
    });

    setUpload(upload);

    analytics.track('identity_verifcaion_selfie_captured');

    redirect(IdVerificationScreens.IDScanning);
  };

  const renderPage = () => {
    switch (screen) {
      case Screens.Intro:
        return (
          <Intro
            brand={brand}
            redirect={redirect}
            onContinue={() => setScreen(Screens.SelfieCam)}
          />
        );
      case Screens.SelfieCam:
        return (
          <Camera brand={brand} onCapture={onCapture} onCancel={() => setScreen(Screens.Intro)} />
        );
      case Screens.SelfieConfirmation:
        return (
          <SelfieConfirmation
            photo={photo}
            brand={brand}
            onCancel={() => setScreen(Screens.SelfieCam)}
            onSave={onSave}
          />
        );
      case Screens.SelfieUploading:
        return <SelfieUploading photo={photo} brand={brand} />;
    }
  };

  return (
    <motion.div
      key={IdVerificationScreens.Selfie}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      {renderPage()}
    </motion.div>
  );
};

export default Selfie;

const Intro = ({ brand, redirect = () => {}, onContinue = () => {} }) => (
  <CandidatePage.Card
    className={cn('d-flex', 'align-items-center', 'justify-content-center', 'flex-column')}>
    <img src="assets/images/icons/selfie.svg" className={moduleStyles.icon} />
    <h1 className={cn('title-4', 'u-margin-top--large')}>Step 1: Take a selfie</h1>
    <p className={cn('u-margin-top', 'u-text-centered', 't-small')}>
      Here's how to capture the perfect selfie for verification purposes:
    </p>
    <div
      className={cn(
        'u-width-100',
        'card',
        'card-with-border',
        'u-padding--small',
        'background-secondary',
        styles.introWrapper,
      )}>
      <ul className="u-padding-0 u-margin-left--large u-margin-top u-margin-bottom">
        <li className="u-padding-bottom">
          <b>Positioning:</b> Face the camera head-on and ensure your face occupies 60-70% of the
          frame. Avoid extreme angles and poses.
        </li>
        <li className="u-padding-bottom">
          <b>Expressions:</b> Keep a neutral expression. Remove dark glasses or tinted lenses, and
          ensure both eyes are open.
        </li>
        <li className="u-padding-bottom">
          <b>Lighting:</b> To increase visibility, avoid bright backlighting and glare. Photographs
          should be taken under even lighting conditions.
        </li>
        <li className="u-padding-bottom">
          <b>Focus & Movement:</b> Ensure the image is not blurry, as this can affect performance
          similarly to low resolution. If focusing issues arise, adjust your distance from the
          device.
        </li>
        <li>
          <b>Orientation:</b> Maintain the device in portrait mode without rotating it.
        </li>
      </ul>
    </div>
    <div className={cn('u-width-100', 'd-grid', 'grid-columns-2', 'column-gap')}>
      <CustomButton
        type="secondary"
        small
        onClick={() => redirect(IdVerificationScreens.QRScanning)}>
        Back
      </CustomButton>
      <CustomButton brand={brand} small onClick={onContinue}>
        Get Started
      </CustomButton>
    </div>
  </CandidatePage.Card>
);

const Camera = ({ brand, onCapture = () => {}, onCancel = () => {} }) => {
  const cameraRef = useRef();
  const frameRef = useRef();

  const capture = () => {
    onCapture(cameraRef.current.getScreenshot(), frameRef.current.getFrameRec());
  };

  return (
    <div className={styles.frameContainer}>
      <Webcam
        ref={cameraRef}
        audio={false}
        screenshotFormat="image/jpeg"
        className={styles.image}
        videoConstraints={{
          facingMode: 'user',
        }}
        mirrored
      />

      <OverlayFrame
        ref={frameRef}
        header="😎 Take a selfie"
        subheader="Hold your face up to the camera viewport"
        round={true}
        strokeColor={brand?.brand_active && brand?.color_active ? brand.color_button : '#b5abca'}
      />

      <div className={styles.buttons}>
        <CustomButton
          type="secondary"
          small
          className={cn('u-margin-right', 'u-margin-top--0')}
          onClick={onCancel}>
          Cancel
        </CustomButton>
        <CustomButton
          icon="camera"
          brand={brand}
          small
          className="u-margin-top--0"
          onClick={capture}>
          Take Selfie
        </CustomButton>
      </div>
    </div>
  );
};

const SelfieConfirmation = ({ brand, photo, onSave = () => {}, onCancel = () => {} }) => (
  <div className={styles.frameContainer}>
    <img src={photo} className={styles.image} /> :
    <OverlayFrame
      header="🧐 Review selfie"
      subheader="Confirm your selfie or take a new photo"
      round={true}
      strokeColor={brand?.brand_active && brand?.color_active ? brand.color_button : '#b5abca'}
    />
    <div className={styles.buttons}>
      <CustomButton
        type="secondary"
        small
        className={cn('u-margin-right', 'u-margin-top--0')}
        onClick={onCancel}>
        Take another
      </CustomButton>
      <CustomButton brand={brand} small className="u-margin-top--0" onClick={onSave}>
        Confirm selfie
      </CustomButton>
    </div>
  </div>
);

const SelfieUploading = ({ brand, photo }) => (
  <div className={styles.frameContainer}>
    <img src={photo} className={styles.image} /> :
    <OverlayFrame
      header="✅ Selfie submitted"
      subheader="👌 You'll be redirected momentarily"
      loading={true}
      round={true}
      strokeColor={brand?.brand_active && brand?.color_active ? brand.color_button : '#b5abca'}
    />
  </div>
);
