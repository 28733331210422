import { useState, useEffect } from 'react';

export const useIncrementalNumber = (endValue = 100, speed = 5) => {
  const [value, setValue] = useState(0);

  const reset = () => {
    setValue(0);
  };

  useEffect(() => {
    let interval = null;

    if (value >= endValue) {
      clearInterval(interval);
    } else {
      interval = setInterval(() => {
        setValue(curr => curr + 1);
      }, speed);
    }

    return () => clearInterval(interval);
  }, [value, endValue]);

  return [value, reset];
};
