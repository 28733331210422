import { forwardRef, useState } from 'react';
import styles from './SearchInput.module.scss';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { useTimer } from 'hooks/timer';
import cn from 'classnames';

export const SearchInput = forwardRef(({ placeholder, className, onChange = () => {} }, ref) => {
  const [value, setValue] = useState('');

  const resetTimer = useTimer(1, () => onChange(value));

  const handleChange = e => {
    setValue(e.target.value);
    resetTimer();
  };

  return (
    <div className={cn(styles.root, { [className]: className })}>
      <FeatherIcon className={styles.icon} icon="search" />

      <input
        ref={ref}
        type="text"
        onChange={handleChange}
        className={styles.input}
        placeholder={placeholder}
      />
    </div>
  );
});

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};
