import React from 'react';

export function getTextWithReferences(text, candidate_name = null, answers = [], params = []) {
  if (!text) return;

  const textWithLinks = text.replace(/\[((?!\]).)*\]\(((?!\)).)*\)/g, function (match) {
    const [, text] = /\[([^)]+)\]/.exec(match);
    const [, link] = /\(([^)]+)\)/.exec(match);

    return `[_${text}_${link}]`;
  });

  const textWithVars = textWithLinks.replace(/{{((?!}).)*}}/g, function (match) {
    var m = match.replace(/[{}]/g, '');
    if (m === 'candidate' && candidate_name) {
      return `[${candidate_name}]`;
    } else if (isNaN(m)) {
      if (params[m]) {
        return `[${params[m]}]`;
      } else {
        return `[${m}]`;
      }
    } else {
      if (answers[m]) return `[${answers[m]}]`;
      else return `[Q${m} answer]`;
    }
  });

  return makeHighlights(textWithVars);
}

export function makeHighlights(text) {
  let text_array = text.split(/\[|\]/);
  let text_highlighted = [];
  for (let i = 0; i < text_array.length; i++) {
    if (i % 2 === 0) {
      text_highlighted.push(text_array[i]);
    } else if (text_array[i][0] === '_') {
      const [, text, link] = text_array[i].split('_');
      text_highlighted.push(
        <a key={i} href={link} target="_blank">
          {text}
        </a>,
      );
    } else {
      text_highlighted.push(
        <span key={i} className="highlight-reference">
          {text_array[i]}
        </span>,
      );
    }
  }
  return text_highlighted;
}

// Custom forms version (can match any variable)
export const textWithReferences = (text, variables = {}) => {
  if (!text) return;

  const textWithLinks = text.replace(/\[((?!\]).)*\]\(((?!\)).)*\)/g, match => {
    const [, text] = /\[([^)]+)\]/.exec(match);
    const [, link] = /\(([^)]+)\)/.exec(match);

    return `<a href="${link}" target="_blank">${text}</a>`;
  });

  const textWithVars = textWithLinks.replace(/{{((?!}).)*}}/g, function (match) {
    const m = match.replace(/[{}]/g, '');
    const value = variables[m];
    return value ? value : m;
  });

  return textWithVars;
};
