import { useDispatch } from 'react-redux';
import {
  useLazyGetUploadQuery,
  useCreateUploadMutation,
  useLazySignUploadQuery,
  useDeleteUploadMutation,
} from 'api/upload-api';
import {
  useLazyGetUploadQuery as useLazyGetPartnerUploadQuery,
  useCreateUploadMutation as useCreatePartnerUploadMutation,
  useLazySignUploadQuery as useLazySignPartnerUploadQuery,
  useDeleteUploadMutation as useDeletePartnerUploadMutation,
} from 'api/partner-v1-api';
import {
  getCandidateUpload,
  signCandidateUpload,
  createCandidateUpload,
  deleteCandidateUpload,
} from 'api/candidate-application-slice';
import {
  useLazyGetRefereeUploadQuery,
  useLazySignRefereeUploadQuery,
  useCreateRefereeUploadMutation,
  useDeleteRefereeUploadMutation,
} from 'api/referees-api';

export const useApiUploadActions = () => {
  const [getUpload] = useLazyGetUploadQuery();
  const handleGetUpload = id => getUpload(id).unwrap();

  const [signUpload] = useLazySignUploadQuery();
  const handleSignUpload = params => signUpload(params).unwrap();

  const [createUpload] = useCreateUploadMutation();
  const handleCreateUpload = async params => {
    const { data: upload } = await createUpload(params);
    return upload;
  };

  const [deleteUpload] = useDeleteUploadMutation();
  const handleDeleteUpload = async id => {
    const { data: upload } = await deleteUpload(id);
    return upload;
  };

  return {
    getUpload: handleGetUpload,
    signUpload: handleSignUpload,
    createUpload: handleCreateUpload,
    deleteUpload: handleDeleteUpload,
  };
};

export const usePartnerV1UploadActions = token => {
  const [getUpload] = useLazyGetPartnerUploadQuery();
  const handleGetUpload = id => getUpload({ token, id }).unwrap();

  const [signUpload] = useLazySignPartnerUploadQuery();
  const handleSignUpload = params => signUpload({ token, params }).unwrap();

  const [createUpload] = useCreatePartnerUploadMutation();
  const handleCreateUpload = async params => {
    const { data: upload } = await createUpload({ token, params });
    return upload;
  };

  const [deleteUpload] = useDeletePartnerUploadMutation();
  const handleDeleteUpload = async id => {
    const { data: upload } = await deleteUpload({ token, id });
    return upload;
  };

  return {
    getUpload: handleGetUpload,
    signUpload: handleSignUpload,
    createUpload: handleCreateUpload,
    deleteUpload: handleDeleteUpload,
  };
};

export const useCandidateApplicationUploadActions = token => {
  const dispatch = useDispatch();

  const getUpload = async id => {
    const { payload: upload } = await dispatch(getCandidateUpload({ token, id }));
    return upload;
  };

  const signUpload = async params => {
    const { payload: signedFile } = await dispatch(signCandidateUpload({ token, params }));
    return signedFile;
  };

  const createUpload = async params => {
    const { payload: upload } = await dispatch(createCandidateUpload({ token, params }));
    return upload;
  };

  const deleteUpload = async id => {
    await dispatch(deleteCandidateUpload({ token, id }));
    return true;
  };

  return {
    getUpload,
    signUpload,
    createUpload,
    deleteUpload,
  };
};

export const useRefereeUploadActions = token => {
  const [getUpload] = useLazyGetRefereeUploadQuery();
  const handleGetUpload = id => getUpload({ token, id }).unwrap();

  const [signUpload] = useLazySignRefereeUploadQuery();
  const handleSignUpload = params => signUpload({ token, params }).unwrap();

  const [createUpload] = useCreateRefereeUploadMutation();
  const handleCreateUpload = async params => {
    const { data: upload } = await createUpload({ token, params });
    return upload;
  };

  const [deleteUpload] = useDeleteRefereeUploadMutation();
  const handleDeleteUpload = async id => {
    const { data: upload } = await deleteUpload({ token, id });
    return upload;
  };

  return {
    getUpload: handleGetUpload,
    signUpload: handleSignUpload,
    createUpload: handleCreateUpload,
    deleteUpload: handleDeleteUpload,
  };
};
