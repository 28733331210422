import { useEffect } from 'react';

export const useScript = (src, onLoadCallback = () => {}) => {
  useEffect(() => {
    let script = document.createElement('script');
    script.async = false;
    script.src = src;
    script.onload = onLoadCallback;
    document.getElementsByTagName('body')[0].appendChild(script);
  }, []);
};
