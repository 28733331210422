import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import styles from './DashboardFilters.module.scss';
import { useGetCandidatesCountQuery } from 'api/candidates-api';
import { SearchInput, Select, InputGroup } from 'components/FormComponents';
import cn from 'classnames';

const STATUSES = {
  All: 'all',
  Active: 'active',
  Completed: 'completed',
  Overdue: 'overdue',
  InProgress: 'in-progress',
  Archived: 'archived',
};

export const DashboardFilters = forwardRef(
  (
    {
      userId,
      tags,
      teams,
      setLoading = () => {},
      onPaginationChange = () => {},
      onCandidatesChange = () => {},
      getCandidates = () => {},
    },
    ref,
  ) => {
    // Search
    const [queryFilter, setQueryFilter] = useState('');

    // Status
    const [statusFilter, setStatusFilter] = useState(STATUSES.Active);
    const [statusesCounts, setStatusesCounts] = useState({
      active: 0,
      completed: 0,
      archived: 0,
      overdue: 0,
      in_progress: 0,
      all: 0,
    });

    // User filter
    const [userFilter, setUserFilter] = useState('');

    // Tags
    const [tagFilter, setTagFilter] = useState('');

    // Teams
    const [teamFilter, setTeamFilter] = useState('');

    // Status counts
    const { data: statusesCountResult } = useGetCandidatesCountQuery();

    useEffect(() => {
      if (!statusesCountResult) return;
      setStatusesCounts(statusesCountResult.result);
    }, [statusesCountResult]);

    const fetch = async () => {
      setLoading(true);
      const { payload } = await getCandidates({
        status: statusFilter,
        search: queryFilter,
        tag: tagFilter,
        team: teamFilter,
        user: userFilter,
      });
      onCandidatesChange(payload.result.candidates);
      onPaginationChange(payload.result.pagination);
      setLoading(false);
    };

    // Allow parent to call updateStatusesCount through ref
    useImperativeHandle(ref, () => ({
      refresh() {
        fetch();
      },
      getFiltersParams() {
        return {
          status: statusFilter,
          search: queryFilter,
          tag: tagFilter,
          team: teamFilter,
          user: userFilter,
        };
      },
    }));

    // Fetch candidates on filters changes
    useEffect(() => {
      fetch();
    }, [queryFilter, statusFilter, tagFilter, teamFilter, userFilter]);

    return (
      <div className={styles.root}>
        <SearchInput
          placeholder="Search for a candidate"
          className={styles.search}
          onChange={setQueryFilter}
        />

        <div className={styles.showing}>
          <InputGroup title="Tags" className={cn(styles.filter, 'u-no-margin-bottom')}>
            <Select
              inputProps={{ value: tagFilter, onChange: e => setTagFilter(e.target.value) }}
              value={tagFilter}>
              <Select.Item value="">Any</Select.Item>
              {tags?.map(tag => (
                <Select.Item key={tag.id} value={tag.id}>
                  {tag.name}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>

          <InputGroup title="Team" className={cn(styles.filter, 'u-no-margin-bottom')}>
            <Select
              inputProps={{ value: teamFilter, onChange: e => setTeamFilter(e.target.value) }}
              value={teamFilter}>
              <Select.Item value="">Any</Select.Item>
              {teams?.map(team => (
                <Select.Item key={team.id} value={team.id}>
                  {team.name}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>

          <InputGroup title="Assigned to" className={cn(styles.filter, 'u-no-margin-bottom')}>
            <Select
              inputProps={{ value: userFilter, onChange: e => setUserFilter(e.target.value) }}
              value={userFilter}>
              <Select.Item value="">Any</Select.Item>
              <Select.Item value={userId}>Me</Select.Item>
            </Select>
          </InputGroup>

          <InputGroup title="Status" className={cn(styles.filter, 'u-no-margin-bottom')}>
            <Select
              inputProps={{ value: statusFilter, onChange: e => setStatusFilter(e.target.value) }}
              value={statusFilter}>
              <Select.Item value={STATUSES.All}>All ({statusesCounts.all})</Select.Item>
              <Select.Item value={STATUSES.Active}>Active ({statusesCounts.active})</Select.Item>
              <Select.Item value={STATUSES.Completed}>
                Completed only ({statusesCounts.completed})
              </Select.Item>
              <Select.Item value={STATUSES.Overdue}>Overdue ({statusesCounts.overdue})</Select.Item>
              <Select.Item value={STATUSES.InProgress}>
                In progress ({statusesCounts.in_progress})
              </Select.Item>
              <Select.Item value={STATUSES.Archived}>
                Archived ({statusesCounts.archived})
              </Select.Item>
            </Select>
          </InputGroup>
        </div>
      </div>
    );
  },
);
