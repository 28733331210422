import { QuestionResponses } from './QuestionResponses/QuestionResponses';

export const FormResponses = ({ fields }) => {
  return (
    <div className="u-margin--large">
      {fields.map((field, index) => (
        <QuestionResponses key={field.id} order={index + 1} field={field} params={{}} />
      ))}
    </div>
  );
};
