import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import styles from './ProfileCard.module.scss';
import { useClickOutside } from 'hooks/click-outside';
import cn from 'classnames';
import { useRef } from 'react';
import { Permissions } from 'constants/permissions';
import { useAccessControl } from 'hooks/access-control';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'api/user-permissions-slice';

export const ProfileCard = ({
  user,
  visible = false,
  onClose = () => {},
  onSignOut = () => {},
}) => {
  const wrapperRef = useRef(null);

  const permissions = useSelector(selectPermissions);
  const hasAccess = useAccessControl(permissions);

  useClickOutside(wrapperRef, onClose);

  return (
    <div className={cn(styles.root, { [styles.rootHidden]: !visible })} ref={wrapperRef}>
      <div className="d-flex u-margin">
        <div className={styles.info}>
          <p className={styles.userName}>{user.name}</p>
          <p className={styles.userEmail}>{user.email}</p>
          <Link to="/account/profile" className={styles.accountBtn}>
            view account
          </Link>
        </div>
      </div>
      <hr />
      <div className={styles.linksWrapper}>
        <Link to="/account/security" className={styles.link}>
          <FeatherIcon icon="lock" />
          security
        </Link>
        {hasAccess(Permissions.BillingWrite) && (
          <Link to="/account/billing" className={styles.link}>
            <FeatherIcon icon="credit-card" />
            billing
          </Link>
        )}
        {hasAccess(Permissions.CompanyWrite) && (
          <Link to="/users" className={styles.link}>
            <FeatherIcon icon="users" />
            users
          </Link>
        )}
        {hasAccess(Permissions.BrandWrite) && (
          <Link to="/account/branding" className={styles.link}>
            <FeatherIcon icon="droplet" />
            branding
          </Link>
        )}
        {hasAccess(Permissions.CheckBundlesWrite) && (
          <Link to="/account/bundles" className={styles.link}>
            <FeatherIcon icon="layers" />
            bundles
          </Link>
        )}
        <Link to="/account/notifications" className={styles.link}>
          <FeatherIcon icon="bell" />
          notifications
        </Link>
        {hasAccess(Permissions.PartnersWrite) && (
          <Link to="/account/integrations" className={styles.link}>
            <FeatherIcon icon="git-pull-request" />
            integrations
          </Link>
        )}
        <a href="https://help.checkmate.tech/en/" target="_blank" className={styles.link}>
          <FeatherIcon icon="help-circle" />
          Support
        </a>
        <a className={styles.link} onClick={onSignOut}>
          <FeatherIcon icon="log-out" />
          sign out
        </a>
      </div>
    </div>
  );
};
