import { Input, ColorInput, InputGroup, ImagePicker, Button } from 'components/FormComponents';
import { useWatch, Controller } from 'react-hook-form';
import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import cn from 'classnames';

export const General = ({ brand, errors, control, register, sendBrandPreview = () => {} }) => {
  const brandActiveValue = useWatch({ control, name: 'brand_active' });
  const bannerLogoActiveValue = useWatch({ control, name: 'banner_logo_active' });
  const colorActiveValue = useWatch({ control, name: 'color_active' });
  const colorButtonValue = useWatch({ control, name: 'color_button' });
  const colorButtonTextValue = useWatch({ control, name: 'color_button_text' });
  const colorLinkValue = useWatch({ control, name: 'color_link' });

  return (
    <div className="background-secondary u-padding">
      <div className={cn('card', 'card-with-border', 'u-padding')}>
        <EditableSettings
          title="Use customized branding"
          open={brandActiveValue}
          toggleable
          register={register}
          name="brand_active"
          className="u-margin-0">
          {brandActiveValue && (
            <>
              <InputGroup title="Name">
                <Input
                  register={register}
                  validators={{ required: true }}
                  name="brand_name"
                  placeholder="Checkmate"
                  error={errors?.brand_name?.type}
                />
              </InputGroup>
              <InputGroup title="Email">
                <Input
                  register={register}
                  name="brand_email"
                  placeholder="no-reply@checkmate.tech"
                  error={brand.brand_email && !brand.signature_id && 'Connection problem'}
                />
              </InputGroup>
              <InputGroup title="Logo - ideal size is 700x600 pixels">
                <Controller
                  control={control}
                  name="brand_logo_id"
                  render={({ field: { onChange, value } }) => (
                    <ImagePicker onChange={onChange} value={value} bucketFolder="logos" />
                  )}
                />
              </InputGroup>
              <EditableSettings
                title="Email Banner - ideal size is 560x190 pixels"
                open={bannerLogoActiveValue}
                toggleable
                register={register}
                name="banner_logo_active">
                <Controller
                  control={control}
                  name="banner_logo_id"
                  render={({ field: { onChange, value } }) => (
                    <ImagePicker onChange={onChange} value={value} bucketFolder="banners" />
                  )}
                />
              </EditableSettings>
              <EditableSettings
                title="Brand Colours"
                open={colorActiveValue}
                toggleable
                register={register}
                name="color_active">
                <InputGroup title="Button color">
                  <ColorInput
                    name="color_button"
                    register={register}
                    initialColor={colorButtonValue}
                  />
                </InputGroup>
                <InputGroup title="Button text color">
                  <ColorInput
                    name="color_button_text"
                    register={register}
                    initialColor={colorButtonTextValue}
                  />
                </InputGroup>
                <InputGroup title="Link color">
                  <ColorInput name="color_link" register={register} initialColor={colorLinkValue} />
                </InputGroup>
              </EditableSettings>
              <div className={cn('u-flex-box', 'u-width-100', 'u-flex-justify-end')}>
                <Button
                  type="secondary"
                  className="u-margin-bottom--small"
                  onClick={sendBrandPreview}>
                  Preview Your Brand
                </Button>
              </div>
            </>
          )}
        </EditableSettings>
      </div>
    </div>
  );
};
