import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, Textarea } from 'components/FormComponents';
import { useForm } from 'react-hook-form';

export const AddNoteModal = ({ visible, onSubmit = () => {}, onClose = () => {} }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <AnimatedModal visible={visible} onClose={onClose} title="Add note">
      <form style={{ width: '400px' }} className="u-margin-top" onSubmit={handleSubmit(onSubmit)}>
        <Textarea
          register={register}
          name="message"
          validators={{ required: true }}
          error={errors?.message?.type}
          placeholder="Enter note..."
          inputProps={{ rows: 5 }}
        />
        <Button className="u-width-100 u-margin-top" submit>
          Add Note
        </Button>
      </form>
    </AnimatedModal>
  );
};
