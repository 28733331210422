import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';
import { saveAs } from 'file-saver';
import { stringDate } from 'helpers/date';
import { toQueryParams } from 'helpers/api';

export const candidatesApi = createApi({
  reducerPath: 'candidatesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/candidates`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getCandidate: builder.query({
      query: id => `/${id}`,
      transformResponse: ({ result }) => result,
    }),
    updateCandidate: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: ({ result }) => result,
    }),
    cancelCandidateChecks: builder.mutation({
      query: id => ({
        url: `/${id}/cancel`,
        method: 'PUT',
      }),
      transformResponse: ({ result }) => result,
    }),
    getCandidateReferees: builder.query({
      query: id => `/${id}/referees`,
      transformResponse: ({ result }) => result,
    }),
    getCandidatesCount: builder.query({
      query: () => '/count',
    }),
    getAllCandidates: builder.query({
      query: params => `/admin?${toQueryParams(params)}`,
    }),
    getCandidateHistory: builder.query({
      query: id => `/${id}/history`,
      transformResponse: ({ result }) => result,
    }),
    getCandidateCompany: builder.query({
      query: id => `/${id}/company`,
      transformResponse: ({ result }) => result,
    }),
    getCandidateChecksValues: builder.query({
      query: id => `/${id}/candidate_checks_values`,
      transformResponse: ({ result }) => result,
    }),
    getCandidateChecks: builder.query({
      query: id => `/${id}/candidate_checks`,
      transformResponse: ({ result }) => result,
    }),
    getCandidateUploads: builder.query({
      query: ({ id, params = {} }) => `/${id}/uploads?${toQueryParams(params)}`,
      transformResponse: ({ result }) => result,
    }),
    addCandidateNote: builder.mutation({
      query: ({ id, message }) => ({
        url: `/${id}/notes`,
        method: 'POST',
        body: { message },
      }),
      transformResponse: ({ result }) => result,
    }),
    addCandidateNotification: builder.mutation({
      query: ({ id, params }) => ({
        url: `/${id}/notification`,
        method: 'POST',
        body: { params },
      }),
      transformResponse: ({ result }) => result,
    }),
    bulkImport: builder.mutation({
      query: data => ({
        url: '/bulk/import',
        method: 'POST',
        body: data,
      }),
    }),
    bulkReminders: builder.mutation({
      query: ({ ids, useAll = false }) => ({
        url: '/bulk/reminder',
        method: 'POST',
        body: { ids, use_all: useAll },
      }),
    }),
    bulkTagDeletion: builder.mutation({
      query: ({ ids, useAll = false }) => ({
        url: '/bulk/tag',
        method: 'DELETE',
        body: { ids, use_all: useAll },
      }),
    }),
    bulkTag: builder.mutation({
      query: body => ({
        url: '/bulk/tag',
        method: 'POST',
        body: body,
      }),
    }),
    bulkArchive: builder.mutation({
      query: ({ ids, useAll = false }) => ({
        url: '/bulk/archive',
        method: 'POST',
        body: { ids, use_all: useAll },
      }),
    }),
    bulkDeletion: builder.mutation({
      query: ({ ids, useAll = false }) => ({
        url: '/bulk/deletion',
        method: 'DELETE',
        body: { ids, use_all: useAll },
      }),
    }),
    bulkExport: builder.mutation({
      queryFn: async ({ ids, useAll }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: '/bulk/export',
          method: 'POST',
          body: { ids, use_all: useAll },
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, `${stringDate()}_checkmate_export`);
        return { data: null };
      },
    }),
    addTagToCandidate: builder.mutation({
      query: ({ id, tagId }) => ({
        url: `/${id}/tags/${tagId}`,
        method: 'POST',
      }),
      transformResponse: ({ result }) => result,
    }),
    removeTagFromCandidate: builder.mutation({
      query: ({ id, tagId }) => ({
        url: `/${id}/tags/${tagId}`,
        method: 'DELETE',
      }),
      transformResponse: ({ result }) => result,
    }),
    sendCandidateRequestEmail: builder.mutation({
      query: id => ({
        url: `/${id}/request`,
        method: 'POST',
      }),
      transformResponse: ({ result }) => result,
    }),
    addCandidateHistory: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}/history`,
        method: 'POST',
        body: data,
      }),
      transformResponse: ({ result }) => result,
    }),
    getCombinedPdfReport: builder.query({
      queryFn: async ({ id, filename }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/${id}/reports/combined`,
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, filename);
        return { data: null };
      },
    }),
    getCandidateFormReport: builder.query({
      queryFn: async ({ candidate, form }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/${candidate.id}/form/${form.id}/report`,
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, `${form.name}_${candidate.name}`);
        return { data: null };
      },
    }),
    getReferenceCandidateResponse: builder.query({
      query: ({ id, refereeId }) => `/${id}/referees/${refereeId}/answers`,
      transformResponse: ({ result }) => result,
    }),
    getCandidateReport: builder.query({
      queryFn: async (candidate, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/${candidate.id}/result`,
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, `${candidate.name}_report`);
        return { data: null };
      },
    }),
    setHistoryLinkCandidateByToken: builder.mutation({
      query: token => ({
        url: `/token/${token}/history`,
        method: 'POST',
      }),
    }),
    getCandidateByToken: builder.query({
      query: token => `/token/${token}`,
      transformResponse: ({ result }) => result,
    }),
    getCandidateRefereesByToken: builder.query({
      query: token => `/token/${token}/referees`,
      transformResponse: ({ result }) => result,
    }),
    getCandidateCompanyByToken: builder.query({
      query: token => `/token/${token}/company`,
      transformResponse: ({ result }) => result,
    }),
    bulkAssignCandidate: builder.mutation({
      query: (body) => ({
        url: '/bulk/assign_candidate',
        method: 'POST',
        body: body,
      })
    }),
  })
})

export const {
  useLazyGetCandidateCompanyByTokenQuery,
  useLazyGetCandidateRefereesByTokenQuery,
  useLazyGetCandidateByTokenQuery,
  useSetHistoryLinkCandidateByTokenMutation,
  useLazyGetCandidateReportQuery,
  useGetReferenceCandidateResponseQuery,
  useLazyGetCandidateFormReportQuery,
  useLazyGetCombinedPdfReportQuery,
  useAddCandidateHistoryMutation,
  useSendCandidateRequestEmailMutation,
  useRemoveTagFromCandidateMutation,
  useAddTagToCandidateMutation,
  useGetCandidateRefereesQuery,
  useGetCandidateUploadsQuery,
  useLazyGetCandidateUploadsQuery,
  useGetCandidateChecksValuesQuery,
  useGetCandidateChecksQuery,
  useGetCandidateQuery,
  useGetCandidateCompanyQuery,
  useLazyGetAllCandidatesQuery,
  useGetCandidatesCountQuery,
  useBulkRemindersMutation,
  useBulkTagDeletionMutation,
  useBulkTagMutation,
  useBulkArchiveMutation,
  useBulkDeletionMutation,
  useBulkExportMutation,
  useUpdateCandidateMutation,
  useCancelCandidateChecksMutation,
  useBulkImportMutation,
  useGetCandidateHistoryQuery,
  useAddCandidateNoteMutation,
  useAddCandidateNotificationMutation,
  useBulkAssignCandidateMutation,
} = candidatesApi
