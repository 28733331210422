import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { IntegrationsSelector } from './components/IntegrationsSelector/IntegrationsSelector';
import { KnowMyIntegration } from './components/KnowMyIntegration/KnowMyIntegration';
import styles from './IntegrationsModal.module.scss';
import { useState } from 'react';

export const IntegrationsModal = ({
  candidate,
  companyIntegrations,
  visible,
  messagePopUp = () => {},
  onClose = () => {},
}) => {
  const [selectedIntegration, setSelectedIntegration] = useState();

  const close = () => {
    onClose();
    setSelectedIntegration(null);
  };

  const renderIntegration = () => {
    switch (selectedIntegration) {
      case 'knowmy':
        return (
          <KnowMyIntegration candidate={candidate} messagePopUp={messagePopUp} onClose={onClose} />
        );
      default:
        return (
          <IntegrationsSelector
            companyIntegrations={companyIntegrations}
            onSelect={setSelectedIntegration}
          />
        );
    }
  };

  return (
    <AnimatedModal className={styles.root} visible={visible} onClose={close} title="Integrations">
      {renderIntegration()}
    </AnimatedModal>
  );
};
