import { useForm } from 'react-hook-form';
import { InputGroup, Input, Select, Button } from 'components/FormComponents';
import { countries } from 'constants/countries';
import { useEffect } from 'react';

export const BillingAddressForm = ({
  billingInfo,
  onClose = () => {},
  handleUpdate = () => {},
}) => {
  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: billingInfo });

  useEffect(() => {
    reset(billingInfo);
  }, [billingInfo]);

  return (
    <form onSubmit={handleSubmit(handleUpdate)} className="u-margin-top--large">
      <InputGroup title="Billing name">
        <Input
          name="billing_name"
          placeholder="Checkmate Technology Limited"
          register={register}
          validators={{ required: true }}
          error={errors?.billing_name?.type}
          inputProps={{ tabIndex: 1 }}
        />
      </InputGroup>
      <InputGroup title="Billing email">
        <Input
          name="email"
          placeholder="billing@checkmate.tech"
          register={register}
          validators={{ required: true }}
          error={errors?.billing_email?.type}
          inputProps={{ tabIndex: 2 }}
        />
      </InputGroup>
      <div className="inline">
        <InputGroup title="Town/City">
          <Input
            name="town"
            placeholder="Enter town/city"
            register={register}
            validators={{ required: true }}
            error={errors?.town?.type}
            inputProps={{ tabIndex: 3 }}
          />
        </InputGroup>
        <InputGroup title="Postcode">
          <Input
            name="post_code"
            placeholder="Enter postcode"
            register={register}
            validators={{ required: true }}
            error={errors?.post_code?.type}
            inputProps={{ tabIndex: 4 }}
          />
        </InputGroup>
      </div>
      <InputGroup title="Country">
        <Select name="country" register={register} value={watch('country')} useDefault>
          {countries.map(country => (
            <Select.Item key={country.value} value={country.value}>
              {country.name}
            </Select.Item>
          ))}
        </Select>
      </InputGroup>
      <div className="d-flex justify-content-between u-width-100 u-margin-top--large">
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button submit>Update</Button>
      </div>
    </form>
  );
};
