import { useForm, useWatch } from 'react-hook-form';
import { countryNumberCodes } from 'constants/countries';
import { InputGroup, Input, Select, Button } from 'components/FormComponents';
import styles from './Company.module.scss';
import { useEffect } from 'react';
import cn from 'classnames';

export const Company = ({ company, updateCompany = () => {}, setLoading = () => {} }) => {
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const countryValue = useWatch({ control, name: 'country' });
  const localeValue = useWatch({ control, name: 'locale' });

  useEffect(() => {
    reset({ name: company.name, country: company.country, locale: company.locale });
  }, [company]);

  const onSubmit = async data => {
    setLoading(true);
    await updateCompany(data);
    setLoading(false);
  };

  return (
    <form className={cn(styles.root)} onSubmit={handleSubmit(onSubmit)}>
      <div className={cn('card--small', 'card', 'card-with-border')}>
        <div className="u-margin--large">
          <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
            <h1 className="title-2">Company Settings</h1>
          </div>
          <p className="t-small">Edit the details of the company settings below.</p>
        </div>
        <div className={cn(styles.content, 'background-secondary', 'u-padding')}>
          <div className={cn('card', 'card-with-border', 'u-padding')}>
            <InputGroup title="Name">
              <Input
                name="name"
                placeholder="e.g. Checkmate"
                register={register}
                validators={{ required: true }}
                error={errors?.name?.type}
                inputProps={{ tabIndex: 1 }}
              />
            </InputGroup>
            <InputGroup title="Country">
              <Select
                name="country"
                placeholder="Country"
                register={register}
                validators={{ required: true }}
                value={countryValue}>
                {countryNumberCodes.map(country => (
                  <Select.Item key={country.id} value={country.id}>
                    {country.name}
                  </Select.Item>
                ))}
              </Select>
            </InputGroup>
            <InputGroup title="Locale">
              <Select
                name="locale"
                register={register}
                validators={{ required: true }}
                value={localeValue}>
                <Select.Item value="en_US">en_US</Select.Item>
                <Select.Item value="en_GB">en_GB</Select.Item>
              </Select>
            </InputGroup>
          </div>
        </div>
        <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-end')}>
          <Button submit>Save</Button>
        </div>
      </div>
    </form>
  );
};
