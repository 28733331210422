import styles from './Checkbox.module.scss';
import cn from 'classnames';
import { useState } from 'react';
import { useEffect } from 'react';

export const Checkbox = ({
  label,
  name,
  className,
  validators = {},
  value,
  checked,
  register = () => {},
  onClick = () => {},
  style,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleClick = () => {
    onClick(!isChecked);
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div
      style={style}
      className={cn(
        'd-flex',
        'align-items-center',
        'u-padding-bottom--small',
        'u-padding-top--small',
        { [className]: className },
      )}>
      <input
        type="checkbox"
        name={name}
        onClick={handleClick}
        value={value}
        className={cn(isChecked ? styles.checkboxActive : styles.checkbox)}
        {...register(name, validators)}
      />
      <span className="t-small u-padding-left--small text-color-primary">{label}</span>
    </div>
  );
};
