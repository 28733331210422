import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = [];

export const getCustomFields = createAsyncThunk('getCustomFields', async () => {
  const res = await apiRequest(`custom_fields`, {}, 'get');
  return res.result;
});

export const updateCustomFields = createAsyncThunk('updateCustomFields', async data => {
  const res = await apiRequest('custom_fields', data, 'post');
  return res.result;
});

const customFieldsSlice = createSlice({
  name: 'customFields',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCustomFields.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(updateCustomFields.fulfilled, (state, { payload }) => {
        return payload;
      });
  },
});

export const selectCustomFields = state => state.customFields;

export default customFieldsSlice.reducer;
