import { useRef, useState, useEffect } from 'react';
import styles from './ColorInput.module.scss';
import PropTypes from 'prop-types';
import { useCombinedRefs } from 'hooks/combined-refs';
import { CustomPicker } from 'react-color';
import { ChangeEvent } from 'utils/form-utils';
import { useClickOutside } from 'hooks/click-outside';
import { useKeydown } from 'hooks/key-down';
import cn from 'classnames';

const { Saturation, Hue } = require('react-color/lib/components/common');

export const ColorInput = ({
  name,
  initialColor = '#FFFFFF',
  className,
  validators = {},
  inputProps = {},
  register = () => {},
}) => {
  const [color, setColor] = useState(initialColor);

  // Picker
  const [showPicker, setShowPicker] = useState(false);
  const componentRef = useRef(null);
  useClickOutside(componentRef, () => setShowPicker(false));
  useKeydown(e => {
    if (e.key === 'Escape') {
      e.preventDefault();
      setShowPicker(false);
    }
  }, window);

  // Be able to use ref with register
  const { ref, ...registerProps } = register(name, validators);
  const inputRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, inputRef);

  // Change
  useEffect(() => {
    if (!inputRef.current || !color) return;

    const { value: currentValue } = inputRef.current;
    inputRef.current.value = color;
    if (currentValue !== color && registerProps?.onChange) {
      registerProps.onChange(ChangeEvent(name, color));
    }
  }, [color, inputRef]);

  useEffect(() => {
    if (initialColor !== color) setColor(initialColor);
  }, [initialColor]);

  return (
    <div ref={componentRef} className={cn(styles.root, { [className]: className })}>
      <div
        className={styles.preview}
        style={{ backgroundColor: color }}
        onClick={() => setShowPicker(!showPicker)}
      />
      <input
        ref={combinedRef}
        name={name}
        className={styles.input}
        placeholder="#FFF"
        {...registerProps}
        {...inputProps}
      />
      <Picker visible={showPicker} color={color} onChange={e => setColor(e.hex)} />
    </div>
  );
};

ColorInput.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  validators: PropTypes.object,
  className: PropTypes.string,
  inputProps: PropTypes.object,
};

const Picker = CustomPicker(({ visible, ...props }) => (
  <div className={cn(styles.picker, { [styles.pickerVisible]: visible })}>
    <div className={styles.saturation}>
      <Saturation {...props} />
    </div>
    <div className={styles.hue}>
      <Hue {...props} />
    </div>
  </div>
));
