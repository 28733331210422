export const shouldSubmitCandidate = (submittedCheck, checks, checkTypes) => {
  // If any check required signature, return false
  const requiresSignature = checkTypes.find(checkType => checkType.signature_required);
  if (requiresSignature) return false;

  // Check unfinished checks. If non or only one and the one submitted, return true.
  const unsubmittedChecks = checks.filter(check => !check.submitted_at);
  if (
    unsubmittedChecks.length === 0 ||
    (unsubmittedChecks.length === 1 && unsubmittedChecks[0].id === submittedCheck.id)
  )
    return true;
  else return false;
};
