import { saveAs } from 'file-saver';
import styles from './QuestionResponse.module.scss';
import { useMemo } from 'react';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import Moment from 'react-moment';
import { FieldTypes } from 'constants/field-types-enum';
import { Button } from 'components/FormComponents';

export const QuestionResponse = ({ order, field, answer, params = {} }) => {
  const download = () => {
    const split = answer.split('.');
    const name = `answer_${field.order}.${split[split.length - 1]}`;
    saveAs(answer, name);
  };

  const questionText = useMemo(() => {
    const text = field.text;

    if (!text) return;

    const textWithLinks = text.replace(/\[((?!\]).)*\]\(((?!\)).)*\)/g, match => {
      const [, text] = /\[([^)]+)\]/.exec(match);
      const [, link] = /\(([^)]+)\)/.exec(match);

      return `<a href="${link}" class='u-link dark' target="_blank">${text}</a>`;
    });

    const textWithVars = textWithLinks.replace(/{{((?!}).)*}}/g, match => {
      const m = match.replace(/[{}]/g, '');
      const value = params[m];
      const text = value ? value : isNaN(m) ? m : `Q${m} answer`;

      return `<b>${text}</b>`;
    });

    return textWithVars;
  }, [field, params]);

  const formattedAnswer = useMemo(() => {
    if (!answer) return;

    switch (field.type) {
      case FieldTypes.OpinionScale:
        const customSteps = field.options.use_custom_steps;
        const stepsStart = customSteps ? field.options.steps_start : 1;
        const stepsEnd = customSteps ? field.options.steps_end : 10;

        let scales = [];
        for (let i = stepsStart; i <= stepsEnd; i++) {
          scales.push(i);
        }
        return (
          <div className={styles.scale}>
            <span className={cn(styles.scaleLabel, styles.scaleLabelLeft)}>
              {field.options.label_start}
            </span>
            {scales.map(s => (
              <span
                key={s}
                className={cn(styles.scaleNumber, { [styles.scaleNumberSelected]: s == answer })}>
                {s}
              </span>
            ))}
            <span className={cn(styles.scaleLabel, styles.scaleLabelRight)}>
              {field.options.label_end}
            </span>
          </div>
        );
      case FieldTypes.StarRating:
        let ratings = [1, 2, 3, 4, 5];
        return (
          <div className={styles.rating}>
            {ratings.map(s => (
              <span key={s} className={cn({ [styles.starFilled]: s <= answer })}>
                <FeatherIcon icon="star" />
              </span>
            ))}
          </div>
        );
      case FieldTypes.MultipleChoice:
        const answers = Array.isArray(answer) ? answer : answer.split(' / ');
        let notInOptionAnswer;
        let optionsValues = field.options.options.map(option => option.value);
        answers.forEach(answer => {
          if (!optionsValues.includes(answer)) notInOptionAnswer = answer;
        });
        return (
          <div className={styles.multipleChoice}>
            {field.options.options.map(option => {
              if (option !== '')
                return (
                  <span
                    key={option.value}
                    className={cn(styles.multipleChoiceOption, {
                      [styles.multipleChoiceOptionSelected]: answers.indexOf(option.value) >= 0,
                    })}>
                    {option.value}
                  </span>
                );
            })}
            {notInOptionAnswer && (
              <span
                className={cn(styles.multipleChoiceOption, styles.multipleChoiceOptionSelected)}>
                {notInOptionAnswer}
              </span>
            )}
          </div>
        );
      case FieldTypes.Date:
        return (
          <span>
            <Moment format="MMMM Do, YYYY">{answer}</Moment>
          </span>
        );
      case FieldTypes.Upload:
        return (
          <span>
            <Button
              className={styles.upload}
              onClick={download}
              icon="download"
              small
              type="secondary">
              View document
            </Button>
          </span>
        );
      case FieldTypes.Signature:
        return <img className={styles.signature} src={answer} />;
      default:
        return <span className={styles.text}>{answer}</span>;
    }
  }, [answer]);

  return (
    <div className={styles.root}>
      <div className={styles.question}>
        <div className={styles.questionOrder}>{order || field.order}</div>
        <div className={styles.questionText}>
          <p dangerouslySetInnerHTML={{ __html: questionText }} />
        </div>
      </div>
      <div className={styles.answer}>{formattedAnswer}</div>
    </div>
  );
};
