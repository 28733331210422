import { Input, InputGroup, Button, Checkbox } from 'components/FormComponents';
import { IntegrationCard } from '..';
import { useEffect, useState } from 'react';

export const TasIntegration = ({
  config,
  integration,
  onCompanyUpdate = () => {},
  setLoading = () => {},
}) => {
  const [enabled, setEnabled] = useState(integration.enabled);

  const [tenantShortCode, setTenantShortCode] = useState(integration.tenant_short_code);
  const [useV2, setUseV2] = useState(integration.use_v2);

  const onSave = async () => {
    setLoading(true);
    await onCompanyUpdate({ tenant_short_code: tenantShortCode, snaphire_use_v2: useV2 });
    setLoading(false);
  };

  useEffect(() => {
    if (enabled) return;

    setLoading(true);
    onCompanyUpdate({ tenant_short_code: null }).then(() => {
      setLoading(false);
    });
  }, [enabled]);

  return (
    <IntegrationCard {...config} enabled={enabled} onToggle={e => setEnabled(e.target.checked)}>
      {enabled && (
        <div className="u-margin-top u-width-100">
          <InputGroup title="Tenant short code">
            <Input
              inputProps={{
                value: tenantShortCode,
                onChange: e => setTenantShortCode(e.target.value),
              }}
              placeholder="tas"
            />
          </InputGroup>
          <Checkbox checked={useV2} onClick={() => setUseV2(curr => !curr)} label="Use version 2" />
          <Button
            type="secondary"
            className="u-width-100 u-margin-top--large"
            onClick={onSave}
            disabled={!tenantShortCode}>
            Save
          </Button>
        </div>
      )}
    </IntegrationCard>
  );
};
