import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const purchasesApi = createApi({
  reducerPath: 'purchasesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/purchases`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getPurchases: builder.query({
      query: () => '/',
      transformResponse: ({ result }) => result,
    }),
    createPurchase: builder.mutation({
      query: data => ({
        url: '/',
        method: 'POST',
        body: data,
      }),
      transformResponse: ({ result }) => result,
    }),
  }),
});

export const { useGetPurchasesQuery, useCreatePurchaseMutation } = purchasesApi;
