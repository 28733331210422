import { useEffect, useState } from 'react';
import { Loading } from '../../components/Loading/Loading';
import { Button } from 'components/FormComponents';
import { AdminNavbar } from './AdminNavbar/AdminNavbar';
import AppMessage from '../AppPage/AppMessage/AppMessage';
import styles from './AdminPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { selectAuthentication } from 'api/authentication-slice';
import { selectApp, messagePopOut } from 'api/app-slice';
import { useDispatch, useSelector } from 'react-redux';

const AdminPage = ({
  title,
  loading,
  button,
  buttonText = 'Save',
  children,
  onClick = () => {},
}) => {
  const dispatch = useDispatch();

  const authentication = useSelector(selectAuthentication);
  const app = useSelector(selectApp);

  const [authenticating, setAuthenticating] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(messagePopOut());
  }, []);

  useEffect(() => {
    if (!authentication.checked) return;
    else if (!authentication.logged) navigate(`/sign_in?redirect_uri=${window.location.pathname}`);
    else if (!authentication.admin) navigate('/dashboard');

    setAuthenticating(false);
  }, [authentication.checked]);

  return (
    <div className={styles.root}>
      <Loading active={loading || authenticating}></Loading>
      <AdminNavbar />
      <AppMessage {...app.message} />
      <div>
        <div className={styles.header}>
          <h1 className={styles.title}>{title}</h1>
          {button && <Button onClick={onClick}>{buttonText}</Button>}
        </div>
        {children}
      </div>
    </div>
  );
};

export default AdminPage;
