import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const candidateChecksValuesApi = createApi({
  reducerPath: 'candidateChecksValuesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/candidates_checks_values`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    rejectCandidateChecksValues: builder.mutation({
      query: ({ candidate_id, rejected_fields }) => ({
        url: `/candidate/${candidate_id}`,
        method: 'DELETE',
        body: { rejected_fields },
      }),
      transformResponse: ({ result }) => result,
    }),
    updateCandidateChecksValues: builder.mutation({
      query: ({ candidate_id, values }) => ({
        url: `/candidate/${candidate_id}`,
        method: 'PUT',
        body: { values },
      }),
      transformResponse: ({ result }) => result,
    }),
  }),
});

export const { useRejectCandidateChecksValuesMutation, useUpdateCandidateChecksValuesMutation } =
  candidateChecksValuesApi;
