import {
  Input,
  DatePicker,
  DateSelector,
  InputGroup,
  Checkbox,
  PhoneNumberPicker,
  MultipleChoice,
  Select,
  MultipleSelect,
  Textarea,
} from 'components/FormComponents';
import { countries, Alpha3Countries } from 'constants/countries';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useLocale } from 'hooks/locale';

export const DynamicTypeInput = ({
  title,
  description,
  fieldName,
  type = 'text',
  fieldOptions,
  required = false,
  control,
  error,
  noErrorMessage = false,
  locale = 'en_GB',
  watch = () => {},
  register = () => {},
}) => {
  const { dateHyphenFormat } = useLocale(locale);

  const render = () => {
    switch (type) {
      case 'text':
        return (
          <InputGroup title={title} description={description}>
            <Input
              name={fieldName}
              register={register}
              validators={{ required }}
              placeholder={title}
              error={error}
              noErrorMessage={noErrorMessage}
            />
          </InputGroup>
        );
      case 'textarea':
        return (
          <InputGroup title={title} description={description}>
            <Textarea
              name={fieldName}
              placeholder={title}
              validators={{ required }}
              error={error}
              inputProps={{ rows: 4 }}
              register={register}
            />
          </InputGroup>
        );
      case 'phone_number':
        return (
          <InputGroup title={title} description={description}>
            <Controller
              control={control}
              name={fieldName}
              rules={{ required }}
              render={({ field: { onChange, value } }) => (
                <PhoneNumberPicker
                  value={value}
                  onChange={onChange}
                  defaultCountry="NZ"
                  error={error}
                />
              )}
            />
          </InputGroup>
        );
      case 'date':
        return (
          <InputGroup title={title} description={description}>
            <Controller
              control={control}
              name={fieldName}
              rules={{ required }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  format={dateHyphenFormat}
                  outputFormat="DD/MM/YYYY"
                  useDefaultToday={false}
                  error={error}
                />
              )}
            />
          </InputGroup>
        );
      case 'date_selector':
        return (
          <InputGroup title={title} description={description}>
            <Controller
              control={control}
              name={fieldName}
              rules={{ required }}
              render={({ field: { onChange, value } }) => (
                <DateSelector
                  value={value}
                  onChange={onChange}
                  outputFormat="DD/MM/YYYY"
                  error={error}
                />
              )}
            />
          </InputGroup>
        );
      case 'radio_button':
        return (
          <InputGroup title={title} error={error} description={description}>
            <Controller
              control={control}
              name={fieldName}
              rules={{ required }}
              render={({ field: { onChange, value } }) => (
                <MultipleChoice
                  options={{ options: fieldOptions }}
                  value={value}
                  onChange={onChange}
                  radioButtonStyle
                  error={error}
                />
              )}
            />
          </InputGroup>
        );
      case 'country':
        return (
          <InputGroup title={title} description={description}>
            <Select
              name={fieldName}
              placeholder="Select..."
              register={register}
              value={watch(fieldName)}
              useDefault
              useSearch>
              {countries.map(country => (
                <Select.Item key={country.value} value={country.value}>
                  {country.name}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>
        );
      case 'alpha3-country':
        return (
          <InputGroup title={title} description={description}>
            <Select
              name={fieldName}
              placeholder="Select..."
              register={register}
              value={watch(fieldName)}
              useDefault
              useSearch>
              {Alpha3Countries.map(country => (
                <Select.Item key={country.code} value={country.code}>
                  {country.name}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>
        );
      case 'multi_country':
        return (
          <InputGroup title={title} description={description}>
            <Controller
              control={control}
              name={fieldName}
              render={({ field: { onChange, value } }) => (
                <MultipleSelect value={value} onChange={onChange} placeholder="Select..." useSearch>
                  {countries.map((country, i) => (
                    <MultipleSelect.Item
                      key={i}
                      value={country.value}
                      active={watch(fieldName)?.includes(country.value)}>
                      {country.name}
                    </MultipleSelect.Item>
                  ))}
                </MultipleSelect>
              )}
            />
          </InputGroup>
        );
      case 'checkbox':
        return (
          <Checkbox
            name={fieldName}
            register={register}
            checked={watch(fieldName)}
            error={error}
            label={title}
          />
        );
      case 'select':
        return (
          <InputGroup title={title} description={description}>
            <Select name={fieldName} register={register} value={watch(fieldName)} useDefault>
              {fieldOptions.map(option => (
                <Select.Item key={option.value} value={option.value}>
                  {option.name}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>
        );
      case 'multi_select':
        return (
          <InputGroup title={title} description={description}>
            <Controller
              control={control}
              name={fieldName}
              render={({ field: { onChange, value } }) => (
                <MultipleSelect value={value} onChange={onChange} placeholder="Select...">
                  {fieldOptions.map(option => (
                    <MultipleSelect.Item
                      key={option.value}
                      value={option.value}
                      active={watch(fieldName)?.includes(option.value)}>
                      {option.name}
                    </MultipleSelect.Item>
                  ))}
                </MultipleSelect>
              )}
            />
          </InputGroup>
        );
      default:
        return null;
    }
  };

  return render();
};

DynamicTypeInput.propTypes = {
  type: PropTypes.oneOf([
    'select',
    'multi_select',
    'text',
    'textarea',
    'date',
    'country',
    'multi_country',
    'phone_number',
    'date_selector',
    'radio_button',
    'checkbox',
    'alpha3-country',
  ]),
  register: PropTypes.func,
  watch: PropTypes.func,
  fieldOptions: PropTypes.any,
  control: PropTypes.any,
  title: PropTypes.string,
  fieldName: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.any,
  noErrorMessage: PropTypes.bool,
  locale: PropTypes.oneOf(['en_GB', 'en_US']),
};
