import { useState } from 'react';
import { List } from 'components/List/List';
import { FormEdit } from 'components/Form/FormEdit/FormEdit';
import { FormTypes, ContactDetailFields } from './../../../../constants/forms';
import cn from 'classnames';

export const CandidateReference = ({ forms, teams, onSave = () => {}, deleteForm = () => {} }) => {
  const [form, setForm] = useState();

  const createNewForm = () => {
    setForm({
      name: 'New Form',
      team_id: null,
      type: FormTypes.CANDIDATE,
      fields: ContactDetailFields,
    });
  };

  const copyFromForm = copyFrom => {
    if (!copyFrom) return;

    let newForm = { ...copyFrom };
    delete newForm.id;

    newForm.name += ' (Copy)';

    newForm.fields.map(field => {
      let newField = { ...field };
      delete newField.id;
      return newField;
    });

    setForm(newForm);
  };

  const handleSave = async data => {
    const updatedForm = await onSave(data);
    if (updatedForm) setForm(updatedForm);
  };

  return (
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <List
        title="Forms"
        subtitle="Create and customise forms that are used to request references from candidates."
        className="u-margin-right--large u-flex-align-self-normal"
        onNew={createNewForm}>
        <List.Category title="Candidate Forms">
          {forms.map(form => (
            <List.Item key={form.id} title={form.name} value={form} onClick={setForm} />
          ))}
        </List.Category>
      </List>
      {form && (
        <FormEdit
          form={form}
          teams={teams}
          blockBefore={4}
          onCopy={copyFromForm}
          onSave={handleSave}
          onDelete={deleteForm}
          onClose={() => setForm(null)}
        />
      )}
    </div>
  );
};
