import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messagePopUp } from 'api/app-slice';
import { MESSAGE_STATE_ERROR, MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { getForms, deleteForm, saveForm } from 'api/forms-slice';
import { getTeams } from 'api/teams-slice';
import { Tabs, Tab } from 'react-bootstrap';
import { CustomForms } from './tabs/CustomForms/CustomForms';
import { IQForms } from './tabs/IQForms/IQForms';
import AppPage from 'layouts/AppPage/AppPage';
import { LibraryForms } from './tabs/LibraryForms/LibraryForms';
import { ReferenceForms } from './tabs/ReferenceForms/ReferenceForms';
import { CandidateQuestionnaires } from './tabs/CandidateQuestionnaires/CandidateQuestionnaires';
import { CandidateReference } from './tabs/CandidateReference/CandidateReference';
import ReactGA from 'react-ga';
import cn from 'classnames';
import styles from './Questionnaires.module.scss';
import { Permissions } from 'constants/permissions';
import { useDocumentTitle } from 'hooks/document-title';
import { useNavigate, useParams } from 'react-router-dom';
import { selectCompany } from 'api/company-slice';
import { selectForms } from 'api/forms-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { selectTeams } from 'api/teams-slice';

const Questionnaires = () => {
  useDocumentTitle('Questionnaires');

  const navigate = useNavigate();

  const { tab: targetTab, id } = useParams();

  const dispatch = useDispatch();

  const company = useSelector(selectCompany);
  const forms = useSelector(selectForms);
  const companyChecks = useSelector(selectCompanyChecks);
  const teams = useSelector(selectTeams);

  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(1);

  const [referenceCheckDetails, setReferenceCheckDetails] = useState();
  const [customReferenceEnabled, setCustomReferenceEnabled] = useState(false);
  const [candidateQuestionnaireEnabled, setCandidateQuestionnaireEnabled] = useState(false);

  // Initialize
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    Promise.all([dispatch(getTeams()), dispatch(getForms())]).then(_ => {
      setLoading(false);
    });
  }, []);

  // Set tab
  useEffect(() => setTab(targetTab), [targetTab]);

  // Set custom reference enabled
  useEffect(() => {
    setCandidateQuestionnaireEnabled(
      !!companyChecks.find(companyCheck => companyCheck.type === 'candidate_questionnaire'),
    );
    setCustomReferenceEnabled(
      !!companyChecks.find(companyCheck => companyCheck.type === 'custom_reference'),
    );
    setReferenceCheckDetails(companyChecks.find(companyCheck => companyCheck.type === 'reference'));
  }, [companyChecks]);

  // Delete form
  const handleDeleteForm = async form => {
    setLoading(true);
    await dispatch(deleteForm(form));
    dispatch(
      messagePopUp({ text: 'Form successfully deleted', state: MESSAGE_STATE_SUCCESS, hide: true }),
    );
    setLoading(false);
  };

  const handleSaveForm = async formData => {
    setLoading(true);
    try {
      const { payload: response } = await dispatch(saveForm(formData));
      if (response.error) throw response.error.message;
      dispatch(
        messagePopUp({ text: 'Form successfully saved', state: MESSAGE_STATE_SUCCESS, hide: true }),
      );
      return response.result.form;
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const showError = error =>
    dispatch(messagePopUp({ text: error, state: MESSAGE_STATE_ERROR, hide: true }));

  return (
    <AppPage loading={loading} requiredPermissions={[Permissions.FormsWrite]}>
      <div className={styles.root}>
        <AppPage.Header
          title="Questionnaires"
          subtitle="Create and configure questionnaires that are used for candidates and references in Checkmate."
        />
        <Tabs
          id="accountTabs"
          onSelect={key => navigate(`/questionnaires/${key}`)}
          activeKey={tab}
          className={cn('tabs', styles.tabs)}>
          {!!referenceCheckDetails && !referenceCheckDetails.configuration?.hide_public_library && (
            <Tab eventKey="library" title="Library">
              <LibraryForms forms={forms.library} />
            </Tab>
          )}
          {!!referenceCheckDetails && (
            <Tab eventKey="custom" title="Custom">
              <CustomForms
                forms={forms.customs}
                onSave={handleSaveForm}
                onDelete={handleDeleteForm}
                copyFrom={forms.library.find(f => f.typeform_id === id)}
              />
            </Tab>
          )}
          {!!referenceCheckDetails && (
            <Tab
              eventKey="iq"
              title={
                <div className={styles.iqTitle}>
                  Checkmate <img src="assets/images/icons/iq_logo.svg" alt="iq" />
                </div>
              }>
              <IQForms forms={forms.intelligent} />
            </Tab>
          )}
          {candidateQuestionnaireEnabled && (
            <Tab eventKey="candidate_questionnaire" title="Candidate Questionnaire">
              <CandidateQuestionnaires
                forms={forms.questionnaires}
                teams={teams}
                deleteForm={handleDeleteForm}
                onSave={handleSaveForm}
              />
            </Tab>
          )}
          {customReferenceEnabled && (
            <Tab eventKey="candidate_reference" title="Candidate Reference">
              <CandidateReference
                forms={forms.candidates}
                teams={teams}
                deleteForm={handleDeleteForm}
                onSave={handleSaveForm}
              />
            </Tab>
          )}
          {customReferenceEnabled && (
            <Tab
              eventKey="referees_reference"
              title="Reference Plus"
              className="questionnaires-tabs">
              <ReferenceForms
                forms={forms.referees}
                teams={teams}
                deleteForm={handleDeleteForm}
                onSave={handleSaveForm}
                company={company}
                setLoading={setLoading}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    </AppPage>
  );
};

export default Questionnaires;
