import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, InputGroup, Select } from 'components/FormComponents';
import { useForm, useWatch } from 'react-hook-form';
import cn from 'classnames';

export const CandidateAssignModal = ({
  users,
  visible,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ shouldUnregister: true });

  const user_id = useWatch({ control, name: 'user_id' });

  return (
    <AnimatedModal visible={visible} onClose={onClose} title="Update candidate">
      <form
        style={{ width: '430px', zIndex: 10000 }}
        className="u-margin-top"
        onSubmit={handleSubmit(onSubmit)}>
        <div className={cn('u-bold')}>Candidate Assignment</div>
        <InputGroup title="The user who is assigned to manage the candidate and receive notifications.">
          <Select
            name="user_id"
            placeholder="User"
            register={register}
            value={user_id}
            useDefault
            useSearch>
            {users?.map(user => (
              <Select.Item key={user.id} value={user.id}>
                {user.first_name} {user.last_name} • {user.email}
              </Select.Item>
            ))}
          </Select>
        </InputGroup>

        <Button className="u-width-100 u-margin-top" submit>
          Update
        </Button>
      </form>
    </AnimatedModal>
  );
};
