import { useEffect } from 'react';

export const useKeydown = (callback = () => {}, ref) => {
  useEffect(() => {
    if (!ref) return;

    ref.addEventListener('keydown', callback);
    return () => ref.removeEventListener('keydown', callback);
  }, [ref]);
};
