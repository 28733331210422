import { IntegrationCard } from '..';

export const SmartRecruitersIntegration = ({ config, integration }) => (
  <IntegrationCard
    {...config}
    subtitle={
      <a
        className="u-link dark"
        target="_blank"
        href="https://marketplace.smartrecruiters.com/partners/checkmate">
        Learn more about our SmartRecruiters integration
      </a>
    }
    enabled={integration.enabled}
    onToggle={() => {}}
  />
);
