import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const billingApi = createApi({
  reducerPath: 'billingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/billing`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getBillingInfo: builder.query({
      query: () => '/',
    }),
    updateBillingInfo: builder.mutation({
      query: params => ({
        url: '/',
        method: 'PUT',
        body: params,
      }),
    }),
  }),
});

export const { useLazyGetBillingInfoQuery, useUpdateBillingInfoMutation } = billingApi;
