import { useState, useEffect, useMemo } from 'react';
import { selectCandidateApplication } from 'api/candidate-application-slice';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import styles from './CandidateDocumentUpload.module.scss';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Steps } from 'components/Steps/Steps';
import { useBrandingStyleVariables } from 'hooks/branding-style-variables';
import { useCandidateApplicationUploadActions } from 'hooks/upload-actions';
import { FilePicker } from 'components/FormComponents';
import { CustomButton } from '../components/CustomButton/CustomButton';

const CandidateDocumentUpload = () => {
  const navigate = useNavigate();

  const { token } = useParams();

  const {
    application: { candidate, brand, uploads },
    documentUploads,
  } = useSelector(selectCandidateApplication);

  const { signUpload, createUpload, deleteUpload } = useCandidateApplicationUploadActions(token);

  const bradingStyleVariables = useBrandingStyleVariables(brand);

  const [currentDocument, setCurrentDocument] = useState();
  const [documentIndex, setDocumentIndex] = useState(1);

  // Initial upload
  useEffect(() => {
    if (!documentUploads || documentUploads.documents.length < 1) return;

    setDocument(0);
  }, [documentUploads?.documents.length]);

  // documentUploads
  const documentUploadsFiles = useMemo(() => {
    if (!currentDocument) return [];
    return uploads.filter(u => u.metadata.doc_type === currentDocument.code);
  }, [uploads, currentDocument]);

  // Validation
  const isValid = useMemo(() => {
    if (!currentDocument) return false;
    if (!currentDocument.required) return true;

    return documentUploadsFiles?.length > 0;
  }, [documentUploadsFiles, currentDocument]);

  // Set a document
  const setDocument = index => {
    const document = documentUploads.documents[index];

    setDocumentIndex(index);
    setCurrentDocument(document);
  };

  const handleNext = () => {
    if (documentUploads.documents.length == documentIndex + 1)
      navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
    else setDocument(documentIndex + 1);
  };

  const handleBack = () => {
    if (documentIndex === 0)
      navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
    else setDocument(documentIndex - 1);
  };

  return (
    <CandidatePage token={token} withTopLogo brand={brand}>
      <CandidatePage.View>
        <CandidatePage.Card className={styles.root}>
          <img src="assets/images/icons/documents.svg" className={styles.icon} />
          <h1 className="title-4 u-padding-top u-padding-bottom--small">Your documents</h1>
          <Steps
            brand={brand}
            steps={documentUploads?.documents?.length}
            currentStep={documentIndex}
          />
          {currentDocument && (
            <div className={styles.document}>
              <img src={currentDocument.logo} className={styles.documentLogo} />
              <h1 className="title-4 u-padding-top u-padding-bottom--small">
                Upload your {currentDocument.title} {!currentDocument.required && '(optional)'}
              </h1>
              <div
                className={cn(
                  styles.documentInstructions,
                  'card',
                  'card-with-border',
                  'u-padding',
                  'u-margin-y',
                  'background-secondary',
                  't-body',
                  'branded-text',
                )}
                dangerouslySetInnerHTML={{ __html: currentDocument.instructions }}
                style={bradingStyleVariables}
              />

              <FilePicker
                useModel
                className="u-margin-top"
                accept={{ 'application/pdf': ['.pdf'], 'image/*': ['.jpeg', '.png'] }}
                modelParams={{ candidate_id: candidate.id }}
                metadata={{ doc_type: currentDocument.code }}
                bucketFolder="background_applications"
                multiple={currentDocument.allow_multiple}
                brand={brand}
                value={
                  currentDocument.allow_multiple ? documentUploadsFiles : documentUploadsFiles[0]
                }
                signUpload={signUpload}
                createUpload={createUpload}
                deleteUpload={deleteUpload}
              />

              <div className="d-flex u-width-100 u-margin-top">
                <CustomButton
                  type="secondary"
                  small
                  className="u-margin-right"
                  onClick={handleBack}>
                  Back
                </CustomButton>
                <CustomButton brand={brand} small disabled={!isValid} onClick={handleNext}>
                  Next
                </CustomButton>
              </div>
            </div>
          )}
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CandidateDocumentUpload;
