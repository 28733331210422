import { useState } from 'react';
import { IconButton } from 'components/IconButton/IconButton';
import LinkShare from '../../LinkShare/LinkShare';
import cn from 'classnames';
import { createSlider } from '@typeform/embed';
import styles from './SectionedForm.module.scss';
import { ToggableSection } from '../ToggableSection/ToggableSection';
import { FormSection } from '../FormSection/FormSection';
import { useNavigate } from 'react-router-dom';

export const SectionedForm = ({ form, allowCopy }) => {
  const navigate = useNavigate();

  const [linkOpened, setLinkOpened] = useState(false);

  const showPreview = () => {
    const { open } = createSlider(form.typeform_id, {
      hidden: {
        candidate: 'Candidate',
        referee: 'Referee',
        job: 'Job',
      },
      hideHeaders: true,
      hideFooter: true,
    });
    open();
  };

  const partFields = part => {
    return form.fields.filter(field => field.check_part === part);
  };

  return (
    <div className={cn(styles.root, 'card', 'card-with-border', 'card--medium')}>
      <div className="u-margin--large">
        <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
          <div className="u-width-50">
            <div className={cn('u-flex-box', 'u-flex-align-center')}>
              <h1 className="title-2">{form.name}</h1>
            </div>
            <p className="t-small">
              {form.fields.length} questions / {Math.ceil(form.fields.length / 3)} minutes to
              complete
            </p>
          </div>
          <div>
            <LinkShare
              open={linkOpened}
              link={`https://checkmatetech.typeform.com/to/${form?.typeform_id}?candidate=Candidate&referee=Referee&job=Job`}
              title="Share this form"
              onClose={() => setLinkOpened(false)}
            />
            <IconButton
              icon="copy"
              tip="Copy"
              className="u-margin-right--small"
              disabled={!form.id && allowCopy}
              onClick={() => navigate(`/questionnaires/custom/${form.typeform_id}`)}
            />
            <IconButton
              icon="share-2"
              disabled={!form?.typeform_id}
              tip="Share Check"
              className="u-margin-right--small"
              onClick={() => setLinkOpened(!linkOpened)}
            />
            <IconButton
              icon="play"
              tip="Preview Check"
              disabled={!form?.typeform_id}
              className="u-margin-right--small"
              onClick={showPreview}
            />
            <IconButton
              icon="user-plus"
              tip="New Check"
              className="u-margin-right--small"
              disabled={!form?.typeform_id}
              onClick={() => navigate(`/new_check/${form.typeform_id}`)}
            />
          </div>
        </div>
      </div>
      <hr className="divider" />
      <div className="u-margin--large">
        <ToggableSection
          title={
            <h1 className="title-4">
              Part 1. Validation{' '}
              <span className="t-small">({partFields('VALIDATION').length} questions)</span>
            </h1>
          }>
          <FormSection fields={partFields('VALIDATION')} />
        </ToggableSection>
        <hr className="divider" />
        <ToggableSection
          title={
            <h1 className="title-4">
              Part 2. Performance{' '}
              <span className="t-small">({partFields('PERFORMANCE').length} questions)</span>
            </h1>
          }>
          <FormSection fields={partFields('PERFORMANCE')} />
        </ToggableSection>
        <hr className="divider" />
        <ToggableSection
          title={
            <h1 className="title-4">
              Part 3. Compliance{' '}
              <span className="t-small">({partFields('COMPLIANCE').length} questions)</span>
            </h1>
          }>
          <FormSection fields={partFields('COMPLIANCE')} />
        </ToggableSection>
      </div>
    </div>
  );
};
