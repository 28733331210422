import { Table } from 'components/Table/Table';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Badge } from 'react-bootstrap';
import { IconButton } from 'components/IconButton/IconButton';
import { Link } from 'react-router-dom';
import { useLocale } from 'hooks/locale';
import { MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';

const RenewalStatuses = {
  unset: 'Pending',
  scheduled: 'Scheduled',
  canceled: 'Canceled',
};

export const RenewalsCandidateChecksTab = ({
  company,
  companyChecks = [],
  messagePopUp = () => {},
  setLoading = () => {},
  getRenewals = () => {},
  updateCandidateCheck = () => {},
  renewCandidateCheck = () => {},
  bulkUpdateCandidateChecks = () => {},
  bulkRenewCandidateChecks = () => {},
}) => {
  const [candidateChecks, setCandidateChecks] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, total_pages: 1 });
  const [checksWithRenewal, setChecksWithRenewal] = useState([]);
  const [companyCheck, setCompanyCheck] = useState({});
  const [renewalStatus, setRenewalStatus] = useState();
  const { dateSlashFormat } = useLocale(company?.locale);

  useEffect(() => {
    setChecksWithRenewal(
      companyChecks
        .filter(companyCheck => companyCheck.renewal_enabled)
        .map(companyCheck => ({ name: companyCheck.check_type.title, value: companyCheck.type })),
    );
  }, [companyChecks]);

  const fetchCandidateChecks = async params => {
    if (!params.type) return;
    setLoading(true);
    setRenewalStatus(params.renewal_status);
    const { data: result } = await getRenewals({ type: params.type, params });
    setCompanyCheck(companyChecks.find(companyCheck => companyCheck.type === params.type));
    setCandidateChecks(result.candidate_checks);
    setPagination(result.pagination);
    setLoading(false);
  };

  const updateRenewalStatus = async (candidateCheckId, renewalStatus) => {
    setLoading(true);
    await updateCandidateCheck({ id: candidateCheckId, data: { renewal_status: renewalStatus } });
    setCandidateChecks(candidateChecks.filter(cc => cc.id !== candidateCheckId));
    setLoading(false);
  };

  const sendRenewal = async candidateCheckId => {
    setLoading(true);
    try {
      await renewCandidateCheck(candidateCheckId);
      setCandidateChecks(candidateChecks.filter(cc => cc.id !== candidateCheckId));
    } catch (error) {
      messagePopUp('No more credits available.', MESSAGE_STATE_ERROR);
    } finally {
      setLoading(false);
    }
  };

  const bulkUpdateRenewalStatus = async (renewalStatus, ids, useAll) => {
    setLoading(true);
    await bulkUpdateCandidateChecks({
      ids: ids,
      use_all: useAll,
      data: { renewal_status: renewalStatus },
    });
    setCandidateChecks(candidateChecks.filter(candidateCheck => !ids.includes(candidateCheck.id)));
    setLoading(false);
  };

  const bulkRenew = async (ids, _) => {
    setLoading(true);
    await bulkRenewCandidateChecks(ids);
    setCandidateChecks(candidateChecks.filter(candidateCheck => !ids.includes(candidateCheck.id)));
    setLoading(false);
  };

  return (
    <Table.Context onQueryUpdate={fetchCandidateChecks} defaultQuery={{ page: 1, when: '30' }}>
      <Table.TableContent className="u-margin--large">
        <Table.Filters>
          <Table.LeftFilters>
            <Table.Search placeholder="Search for a candidate..." />
          </Table.LeftFilters>
          <Table.RightFilters>
            <Table.Filter
              title="Check Type"
              filterName="type"
              options={checksWithRenewal}
              useDefault
            />
            <Table.Filter
              title="Renewal status"
              filterName="renewal_status"
              options={Object.keys(RenewalStatuses).map(statusKey => ({
                name: RenewalStatuses[statusKey],
                value: statusKey,
              }))}
              useDefault
            />
            <Table.Filter
              title="When"
              filterName="when"
              options={[
                { name: 'Next week', value: '7' },
                { name: 'Next month', value: '30' },
                { name: 'Next 3 months', value: '90' },
                { name: 'Next 6 months', value: '180' },
                { name: 'Due', value: '-7200' },
              ]}
              useDefault
            />
          </Table.RightFilters>
        </Table.Filters>
        <Table className="u-margin--large">
          <Table.Head>
            <Table.Row>
              <Table.Header bulkSelector>State</Table.Header>
              <Table.Header>Candidate name</Table.Header>
              <Table.Header>Completed at</Table.Header>
              <Table.Header>Time left</Table.Header>
              <Table.Header>Scheduled for</Table.Header>
              <Table.Header align="center">Actions</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {candidateChecks.map(candidateCheck => (
              <Table.Row key={candidateCheck.id}>
                <Table.Col bulkSelector selectorId={candidateCheck.id}>
                  <Badge className={`u-status-${candidateCheck.renewal_status}`}>
                    {RenewalStatuses[candidateCheck.renewal_status]}
                  </Badge>
                </Table.Col>
                <Table.Col styles={{ 'vertical-align': 'center' }}>
                  <Link to={`/check/${candidateCheck.candidate_id}`} className="u-link dark">
                    {candidateCheck.first_name} {candidateCheck.last_name}
                  </Link>
                </Table.Col>
                <Table.Col styles={{ 'vertical-align': 'center' }}>
                  <Moment format={dateSlashFormat}>{candidateCheck.completed_at}</Moment>
                </Table.Col>
                <Table.Col styles={{ 'vertical-align': 'center' }}>
                  {companyCheck.use_expiration_for_renewal ? (
                    <Moment fromNow>{candidateCheck.expiration_date}</Moment>
                  ) : (
                    <Moment fromNow add={{ days: companyCheck?.renewal_period }}>
                      {candidateCheck.completed_at}
                    </Moment>
                  )}
                </Table.Col>
                <Table.Col styles={{ 'vertical-align': 'center' }}>
                  {companyCheck.use_expiration_for_renewal ? (
                    <Moment format={dateSlashFormat}>{candidateCheck.expiration_date}</Moment>
                  ) : (
                    <Moment add={{ days: companyCheck?.renewal_period }} format={dateSlashFormat}>
                      {candidateCheck.completed_at}
                    </Moment>
                  )}
                </Table.Col>
                <Table.Col align="center">
                  {renewalStatus !== 'canceled' && (
                    <IconButton
                      icon="x"
                      tip="Stop renewal"
                      onClick={() => updateRenewalStatus(candidateCheck.id, 'canceled')}
                    />
                  )}
                  <IconButton
                    icon="check"
                    tip="Confirm renewal"
                    className="u-margin-x--small"
                    onClick={() => updateRenewalStatus(candidateCheck.id, 'scheduled')}
                  />
                  <IconButton
                    icon="send"
                    tip="Send now"
                    onClick={() => sendRenewal(candidateCheck.id)}
                  />
                </Table.Col>
              </Table.Row>
            ))}
            {candidateChecks.length < 1 && <Table.Placeholder>No renewals found</Table.Placeholder>}
          </Table.Body>
          <Table.Pagination {...pagination} />
        </Table>
      </Table.TableContent>
      <Table.BulkActions>
        {renewalStatus !== 'canceled' && (
          <Table.BulkAction
            icon="x"
            tip="Stop renewals"
            onClick={(ids, useAll) => bulkUpdateRenewalStatus('canceled', ids, useAll)}
          />
        )}
        <Table.BulkAction
          icon="check"
          tip="Confirm renewals"
          onClick={(ids, useAll) => bulkUpdateRenewalStatus('scheduled', ids, useAll)}
        />
        <Table.BulkAction icon="send" tip="Send renewals now" onClick={bulkRenew} />
      </Table.BulkActions>
    </Table.Context>
  );
};
