import {
  useGetRefereesFiltersQuery,
  useLazyGetRefereesStatsQuery,
  useLazyGetRefereesReportingQuery,
  useLazyDownloadRefereesReportQuery,
} from 'api/reporting-api';
import { useState, useEffect } from 'react';
import { ReportingWidget } from '../../components/ReportingWidget/ReportingWidget';
import { Table } from 'components/Table/Table';
import Moment from 'react-moment';
import { Badge } from 'react-bootstrap';
import { Button } from 'components/FormComponents';
import { Link } from 'react-router-dom';

export const RefereesReporting = ({ locale, setLoading = () => {} }) => {
  const [getRefereesReporting] = useLazyGetRefereesReportingQuery();
  const [getRefereesStats] = useLazyGetRefereesStatsQuery();
  const [downloadRefereesReport] = useLazyDownloadRefereesReportQuery();
  const { data: filters } = useGetRefereesFiltersQuery();

  const [referees, setReferees] = useState([]);
  const [stats, setStats] = useState({});
  const [queryParams, setQueryParams] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0,
  });

  const fetchReferees = async (params = {}) => {
    setLoading(true);
    setQueryParams(params);
    const result = await getRefereesReporting(params).unwrap();
    const stats = await getRefereesStats(params).unwrap();
    setStats(stats);
    setPagination(result.pagination);
    setReferees(result.referees);
    setLoading(false);
  };

  useEffect(() => {
    fetchReferees();
  }, []);

  const download = async () => {
    setLoading(true);
    await downloadRefereesReport({ queryParams }).unwrap();
    setLoading(false);
  };

  return (
    <Table.Context onQueryUpdate={fetchReferees} defaultQuery={{ page: 1 }}>
      <Table.FloatingFilters className="u-margin-bottom--large">
        <Table.FloatingFiltersLeft>
          <Table.DateRangeFilter title="Completed within" filterName="completed_at" />
          <Table.MultiSelectFilter
            title="Status"
            filterName="status"
            type="status"
            useDefault={false}
            options={[
              { name: 'In-Progress', value: 'in-progress' },
              { name: 'Completed', value: 'completed' },
              { name: 'Overdue', value: 'overdue' },
              { name: 'Canceled', value: 'canceled' },
            ]}
          />
          <Table.MultiSelectFilter
            title="Engagement"
            filterName="extra_question"
            type="status"
            useDefault={false}
            options={[
              { name: 'Engaged', value: 'Yes' },
              { name: 'Not Engaged', value: 'No' },
            ]}
          />
          <Table.MultiSelectFilter
            title="Fraudulent"
            filterName="warning_flag"
            type="status"
            useDefault={false}
            options={[
              { name: 'Fraudulent', value: true },
              { name: 'Not Fraudulent', value: false },
            ]}
          />
          <Table.MultiSelectFilter
            title="Forms"
            filterName="form_id"
            type="status"
            useDefault={false}
            options={filters?.custom_reference_forms}
          />
          <Table.MultiSelectFilter
            title="Forms"
            filterName="typeform_id"
            type="status"
            useDefault={false}
            options={filters?.reference_forms}
          />
        </Table.FloatingFiltersLeft>
        <Table.FloatingFiltersRight>
          <Button icon="download" onClick={download}>
            Export
          </Button>
        </Table.FloatingFiltersRight>
      </Table.FloatingFilters>
      <ReportingWidget>
        <ReportingWidget.Tile>
          <ReportingWidget.Badge>Total Referees</ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.total_referees}</ReportingWidget.Value>
          <ReportingWidget.Label></ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile>
          <ReportingWidget.Badge>Average Completion</ReportingWidget.Badge>
          <ReportingWidget.Value>
            {stats?.avg_completion_time}
            <span className="title-3">h</span>
          </ReportingWidget.Value>
          <ReportingWidget.Label></ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Divider />
        <ReportingWidget.Tile
          type={ReportingWidget.Types.InProgress}
          disabled={queryParams.status && !queryParams.status.includes('in-progress')}>
          <ReportingWidget.Badge>
            In-Progress (
            {stats.total_referees == 0
              ? 0
              : Math.round((stats?.in_progress * 100) / stats.total_referees)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.in_progress}</ReportingWidget.Value>
          <ReportingWidget.Label>Referees</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Overdue}
          disabled={queryParams.status && !queryParams.status.includes('overdue')}>
          <ReportingWidget.Badge>
            Overdue (
            {stats.total_referees == 0
              ? 0
              : Math.round((stats?.overdue * 100) / stats.total_referees)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.overdue}</ReportingWidget.Value>
          <ReportingWidget.Label>Referees</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Completed}
          disabled={queryParams.status && !queryParams.status.includes('completed')}>
          <ReportingWidget.Badge>
            Complete (
            {stats.total_referees == 0
              ? 0
              : Math.round((stats?.completed * 100) / stats.total_referees)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.completed}</ReportingWidget.Value>
          <ReportingWidget.Label>Referees</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Warning}
          disabled={queryParams.status && !queryParams.status.includes('completed')}>
          <ReportingWidget.Badge>
            Fraudulent (
            {stats.completed == 0 ? 0 : Math.round((stats?.fraudulent * 100) / stats.completed)}%)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.fraudulent}</ReportingWidget.Value>
          <ReportingWidget.Label>Referees</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Canceled}
          disabled={queryParams.status && !queryParams.status.includes('canceled')}>
          <ReportingWidget.Badge>
            Cancelled (
            {stats.total_referees == 0
              ? 0
              : Math.round((stats?.canceled * 100) / stats.total_referees)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.canceled}</ReportingWidget.Value>
          <ReportingWidget.Label>Referees</ReportingWidget.Label>
        </ReportingWidget.Tile>
      </ReportingWidget>
      <Table.TableContent>
        <Table.Filters>
          <Table.Search placeholder="Search..." />
        </Table.Filters>
        <Table className="u-margin--large">
          <Table.Head>
            <Table.Row>
              <Table.Header sortKey="referees.first_name">Name</Table.Header>
              <Table.Header sortKey="referees.status">Status</Table.Header>
              <Table.Header sortKey="referees.email">Email</Table.Header>
              <Table.Header sortKey="candidates.first_name">Candidate Name</Table.Header>
              <Table.Header sortKey="candidates.team_name">Candidate Department</Table.Header>
              <Table.Header sortKey="referees.completed_at">Completed</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {referees.map(referee => (
              <Table.Row key={referee.id}>
                <Table.Col>
                  {referee.first_name} {referee.last_name}
                </Table.Col>
                <Table.Col>
                  <Badge className={`u-status-${referee.status}`}>{referee.status}</Badge>
                </Table.Col>
                <Table.Col>{referee.email}</Table.Col>
                <Table.Col>
                  <Link className="u-link dark" to={`/check/${referee.candidate_id}`}>
                    {referee.candidate_first_name} {referee.candidate_last_name}
                  </Link>
                </Table.Col>
                <Table.Col>{referee.team_name}</Table.Col>
                <Table.Col>
                  {referee.completed_at ? (
                    <Moment format={locale.dateSlashFormat}>{referee.completed_at}</Moment>
                  ) : (
                    '-'
                  )}
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Pagination {...pagination} />
        </Table>
      </Table.TableContent>
    </Table.Context>
  );
};
