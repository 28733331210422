import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, Textarea, Input, InputGroup, Select } from 'components/FormComponents';
import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import { useForm, useWatch } from 'react-hook-form';

export const AddNotificationModal = ({ visible, onSubmit = () => {}, onClose = () => {} }) => {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const recipient = useWatch({ control, name: 'recipient' });

  const onUpdate = data => {
    onSubmit(data);
  };

  return (
    <AnimatedModal visible={visible} onClose={onClose} title="Send Email">
      <form style={{ width: '400px' }} className="u-margin-top" onSubmit={handleSubmit(onUpdate)}>
        <InputGroup>
          <Select name="recipient" register={register} value={recipient} useDefault>
            <Select.Item value="candidate">Candidate</Select.Item>
            <Select.Item value="user">User</Select.Item>
          </Select>
        </InputGroup>
        <InputGroup title="Subject" className="u-margin-bottom--large">
          <Input name="subject" placeholder="Enter Subject.." register={register} />
        </InputGroup>
        <InputGroup title="Body" className="u-margin-bottom--large">
          <Textarea
            register={register}
            name="message"
            validators={{ required: true }}
            error={errors?.message?.type}
            placeholder="Enter body..."
            inputProps={{ rows: 5 }}
          />
        </InputGroup>
        <EditableSettings
          title="add a note"
          open={watch('add_note_active')}
          toggleable
          register={register}
          name="add_note_active">
          <InputGroup>
            <Textarea
              name="add_note"
              register={register}
              placeholder="Enter note..."
              inputProps={{ rows: 10 }}
            />
          </InputGroup>
        </EditableSettings>
        <Button className="u-width-100 u-margin-top" submit>
          Send Email
        </Button>
      </form>
    </AnimatedModal>
  );
};
