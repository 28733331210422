import styles from './AlreadyCompletedForm.module.scss';

const AlreadyCompletedForm = () => (
  <div className={styles.root}>
    <img src="assets/images/icons/check.svg" alt="check" />
    <p className={styles.title}>Form already completed</p>
  </div>
);

export default AlreadyCompletedForm;
