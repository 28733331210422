import React from 'react';
import styles from './UserAvatar.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import cn from 'classnames';

export const UserAvatar = ({ children, className, tooltip, onClick = () => {} }) => (
  <OverlayTrigger
    trigger={tooltip ? ['hover', 'focus'] : 'none'}
    placement="top"
    overlay={<Tooltip>{tooltip}</Tooltip>}>
    <div className={cn(styles.root, { [className]: className })} onClick={onClick}>
      {children}
    </div>
  </OverlayTrigger>
);
