import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messagePopUp } from 'api/app-slice';
import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { getForms } from 'api/forms-slice';
import { getBrands, selectBrands, getBrandUploads } from 'api/brands-slice';
import useLocalStorage from 'react-use-localstorage';
import { createCandidate } from 'api/candidates-slice';
import { errorRouter } from 'helpers/error-router';
import AppPage from 'layouts/AppPage/AppPage';
import { Step1Form } from './components/Step1Form/Step1Form';
import { Step2Form } from './components/Step2Form/Step2Form';
import { StepSelector } from './components/StepSelector/StepSelector';
import styles from './NewCheck.module.scss';
import cn from 'classnames';
import { useDocumentTitle } from 'hooks/document-title';
import { getBundles, selectBundles } from 'api/bundles-slice';
import { Permissions } from 'constants/permissions';
import { useGetUserTeamsQuery, useGetCompanyUsersQuery } from 'api/user-api';
import { useNavigate, useParams } from 'react-router-dom';
import { selectForms } from 'api/forms-slice';
import { selectUser } from 'api/user-slice';
import { selectCompany } from 'api/company-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { useApiUploadActions } from 'hooks/upload-actions';
import { getCustomFields, selectCustomFields } from 'api/candidate-custom-fields-slice';

const NewCheck = () => {
  useDocumentTitle('New Check');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { target } = useParams();

  const forms = useSelector(selectForms);
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const companyChecks = useSelector(selectCompanyChecks);
  const brands = useSelector(selectBrands);
  const bundles = useSelector(selectBundles);
  const customFields = useSelector(selectCustomFields);

  const { data: teams } = useGetUserTeamsQuery(user.id);
  const { data: companyUsers } = useGetCompanyUsersQuery();

  const { getUpload, signUpload, createUpload, deleteUpload } = useApiUploadActions();

  const [targetForm, setTargetForm] = useLocalStorage('newCandidateForm');
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [invalidStep, setInvalidStep] = useState(false);

  // Data
  const [candidateData, setCandidateData] = useState({});

  // Ref
  const topRef = useRef(null);
  const step1FormRef = useRef(null);

  const onGetBrandUploads = brandId => dispatch(getBrandUploads(brandId));

  // Initialize
  useEffect(() => {
    if (!user.id) return;

    if (target) setTargetForm(target);

    Promise.all([
      dispatch(getBrands()),
      dispatch(getForms()),
      dispatch(getCustomFields()),
      dispatch(getBundles()),
    ]).then(
      _ => setLoading(false),
      error => navigate(errorRouter(error)),
    );
  }, [user]);

  // Scroll
  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  // Next Step
  const nextStep = () => {
    scrollToTop();
    step1FormRef.current.submit();
  };

  const submitCandidateData = data => {
    setCandidateData(data);
    setStep(2);
  };

  // Submit
  const submit = async checksData => {
    const data = {
      candidate_data: {
        ...candidateData,
        ...checksData.candidate,
        custom_fields: candidateData.custom_fields?.reduce((prev, value, index) => {
          if (value) {
            return {
              ...prev,
              [index + '']: value,
            };
          }
        }, {}),
      },
      checks: checksData.checks,
    };
    setLoading(true);

    try {
      const { payload: response } = await dispatch(createCandidate(data));
      if (response.error) throw response.error.log;

      const candidate = response.result;
      dispatch(messagePopUp({ text: 'New check added', state: MESSAGE_STATE_SUCCESS, hide: true }));
      const referenceCheck = data.checks.find(check => check?.type === 'reference');
      const customReferenceCheck = data.checks.find(check => check?.type === 'custom_reference');
      if (referenceCheck?.details.bypassed) navigate(`/check/${candidate.id}/referees`);
      else if (customReferenceCheck?.details.bypassed) navigate(`/check/${candidate.id}/reference`);
      else navigate('/dashboard');
    } catch (error) {
      dispatch(messagePopUp({ text: error, state: MESSAGE_STATE_ERROR, hide: true }));
    } finally {
      setLoading(false);
    }
  };

  // Render
  return (
    <AppPage loading={loading} requiredPermissions={[Permissions.CandidateWrite]}>
      <div className={styles.root}>
        <div ref={topRef} />
        <AppPage.Header
          title="New Check"
          subtitle="Launch background screening for a new candidate, and configure the required checks 😎"
          ctaText="Bulk Import"
          ctaClick={() => navigate('/bulk_import/candidates')}
          className="u-divider-bottom-border"
        />
        <div className={styles.body}>
          {user.id && (
            <div className={cn(styles.content, 'u-margin-y--large')}>
              <StepSelector
                className="u-margin-bottom--large"
                activeStep={step}
                invalid={invalidStep}
                onStep1Selection={() => setStep(1)}
                onStep2Selection={nextStep}
              />
              <div className={cn(styles.forms, { [styles['forms--step2']]: step === 2 })}>
                <Step1Form
                  ref={step1FormRef}
                  company={company}
                  brands={brands}
                  teams={teams}
                  customFields={customFields}
                  onSubmit={submitCandidateData}
                  onClick={nextStep}
                  setInvalid={setInvalidStep}
                  users={companyUsers}
                />
                <Step2Form
                  brands={brands}
                  companyChecks={companyChecks}
                  forms={forms}
                  targetForm={targetForm}
                  bundles={bundles}
                  useDocuments
                  allowUploads
                  getBrandUploads={onGetBrandUploads}
                  onSubmit={submit}
                  stepBack={() => setStep(1)}
                  getUpload={getUpload}
                  createUpload={createUpload}
                  deleteUpload={deleteUpload}
                  signUpload={signUpload}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </AppPage>
  );
};

export default NewCheck;
