import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import FeatherIcon from 'feather-icons-react';
import { ToggleLabel, Input } from 'components/FormComponents';
import { Button } from 'components/FormComponents';
import { useFieldArray } from 'react-hook-form';
import { Collapse } from 'react-bootstrap';
import styles from './MultipleChoiceSettings.module.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';

export const MultipleChoiceSettings = ({ register, control, name, field, errors }) => {
  return (
    <>
      <EditableSettings title="Settings" className="u-margin-top" startOpen={true}>
        <ToggleLabel register={register} name={`${name}.required`} label="Required" />
        <div className="u-divider u-margin-y--small" />
        <>
          <ToggleLabel
            register={register}
            name={`${name}.use_description`}
            label="Add description"
          />
          <Collapse in={field.use_description}>
            <div>
              <Input
                register={register}
                name={`${name}.description`}
                className="u-margin-top--small"
                placeholder="Enter description..."
              />
            </div>
          </Collapse>
        </>
        <div className="u-divider u-margin-y--small" />
        <ToggleLabel
          register={register}
          name={`${name}.options.multiple_selection`}
          label="Multiple selection"
        />
        <div className="u-divider u-margin-y--small" />
        <ToggleLabel
          register={register}
          name={`${name}.options.other_option`}
          label="Add 'other' option"
        />
      </EditableSettings>
      <div className="u-divider u-margin-y" />
      <OptionsConfig register={register} name={name} control={control} errors={errors} />
    </>
  );
};

const OptionsConfig = ({ register, name, control, errors }) => {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `${name}.options.options`,
  });
  var errorCount = 0;
  if (fields?.length && errors?.options?.options?.length) {
    errors?.options?.options?.forEach(error => {
      if (error) {
        errorCount = errorCount + 1;
      }
    });
  }

  const addOption = () => {
    append({ value: '' });
  };

  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return;
    move(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="options">
        {({ innerRef, droppableProps, placeholder }) => (
          <div
            className="card card-with-border background-secondary u-padding-x"
            {...droppableProps}
            ref={innerRef}>
            {fields.map((option, index) => (
              <Option
                key={option.id}
                index={index}
                name={`${name}.options.options.${index}`}
                register={register}
                option={option}
                onRemove={() => remove(index)}
                onEnter={addOption}
                errors={errors}
              />
            ))}
            {placeholder}
            <Button type="link" onClick={addOption}>
              + Add another option
            </Button>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Option = ({ name, register, onRemove, option, index, onEnter, errors }) => {
  const handleKeyDown = e => {
    switch (e.code) {
      case 'Enter':
        e.preventDefault();
        onEnter();
        break;
      case 'Backspace':
        if (e.target.value === '') {
          e.preventDefault();
          onRemove();
        }
        break;
    }
  };

  return (
    <Draggable draggableId={option.id} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          className={cn(styles.option, 'd-flex', 'align-items-center', 'u-margin-top')}
          {...draggableProps}
          {...dragHandleProps}
          ref={innerRef}>
          <FeatherIcon icon="move" className={cn('u-margin-right--small', styles.icon)} />
          <Input
            register={register}
            name={`${name}.value`}
            className={styles.input}
            validators={{ required: true }}
            error={errors?.options?.options?.[index]?.value?.type}
            placeholder="Enter option..."
            actionIcon="trash-2"
            onActionClick={onRemove}
            inputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
      )}
    </Draggable>
  );
};
