import styles from './QuestionAnswerSkillRow.module.scss';
import cn from 'classnames';

export const QuestionAnswerSkillRow = ({ order, field, answer }) => (
  <div className={cn(styles.root, 'u-flex-box', 'u-flex-justify-between')}>
    <div className="u-flex-box">
      {field && <div className={styles.number}>{order}</div>}
      <div className={styles.question}>{field.text}</div>
    </div>
    <div className={styles.answer}>
      <div>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(s => (
          <span key={s} className={cn(styles.scale, { [styles.selected]: s === answer })}>
            {s}
          </span>
        ))}
      </div>
    </div>
  </div>
);
