import { useState } from 'react';
import styles from './Dialog.module.scss';
import cn from 'classnames';

export const Dialog = ({ header, children }) => {
  const [opened, setOpened] = useState(true);

  return (
    <div className={styles.root}>
      <div className={styles.button} onClick={() => setOpened(curr => !curr)}>
        <img
          className={styles.logo}
          src="assets/images/checkmate/icon-rotated-white.svg"
          alt="checkmate"
        />
      </div>
      <div className={cn(styles.dialog, { [styles.dialogClosed]: !opened })}>
        <div className={styles.header}>
          <h3>Hi 👋</h3>
          <p>{header}</p>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

const Message = ({ children }) => <div className={styles.message}>{children}</div>;

Dialog.Message = Message;
