import styles from './SingleSignOnButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

export const SingleSignOnButton = ({ className, onClick = () => {} }) => (
  <div onClick={onClick} className={cn(styles.root, { [className]: className })}>
    <FontAwesomeIcon icon="key" className={styles.icon} />
    Log in with Single Sign-on
  </div>
);
