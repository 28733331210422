import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { IntegrationCard } from '..';
import { useState } from 'react';
import { useOAuth } from 'hooks/oauth';
import { useTranslation } from 'react-i18next';
export const JobAdderIntegration = ({
  integration,
  config,
  connect = () => {},
  disconnect = () => {},
  setLoading = () => {},
  popUpMessage = () => {},
}) => {
  const { t } = useTranslation();

  const [enabled, setEnabled] = useState(integration.enabled);

  const onConnect = async code => {
    setLoading(true);
    const { payload } = await connect({ code });

    if (payload.error) {
      popUpMessage(t('integrations.jobadder.integration.failed'), MESSAGE_STATE_ERROR);
      setEnabled(false);
    } else {
      popUpMessage(t('integrations.jobadder.integration.success'), MESSAGE_STATE_SUCCESS);
      setEnabled(true);
    }
    setLoading(false);
  };

  const openAuth = useOAuth({
    authUri: 'https://id.jobadder.com/connect/authorize',
    clientId: 'zr2anaoyhobedkxk4syecodq2a',
    responseType: 'code',
    redirectUri: `${process.env.REACT_APP_WEB_URL}/assets/jobadder_callback.html`,
    scope: 'partner_ui_action offline_access read_jobapplication read write write_jobapplication',
    callbackAction: 'jobadder_callback',
    callback: onConnect,
  });

  const toggleIntegration = async e => {
    const value = e.target.checked;
    if (integration.enabled === value) return;

    if (value) {
      openAuth();
    } else {
      setLoading(true);
      await disconnect();
      setLoading(false);
      setEnabled(false);
    }
  };

  return <IntegrationCard {...config} enabled={enabled} onToggle={toggleIntegration} />;
};
