import {
  useGetCandidatesFiltersQuery,
  useLazyGetCandidatesStatsQuery,
  useLazyGetCandidatesReportingQuery,
  useLazyDownloadCandidatesReportQuery,
} from 'api/reporting-api';
import { useState, useEffect } from 'react';
import { ReportingWidget } from '../../components/ReportingWidget/ReportingWidget';
import { Table } from 'components/Table/Table';
import Moment from 'react-moment';
import { Badge } from 'react-bootstrap';
import { Button } from 'components/FormComponents';
import { Link } from 'react-router-dom';

export const CandidatesReporting = ({ locale, setLoading = () => {} }) => {
  const [getCandidatesStats] = useLazyGetCandidatesStatsQuery();
  const { data: filters } = useGetCandidatesFiltersQuery();
  const [getCandidatesReporting] = useLazyGetCandidatesReportingQuery();
  const [downloadCandidatesReport] = useLazyDownloadCandidatesReportQuery();

  const [candidates, setCandidates] = useState([]);
  const [stats, setStats] = useState({});
  const [queryParams, setQueryParams] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0,
  });

  const fetchCandidates = async (params = {}) => {
    setLoading(true);
    setQueryParams(params);
    const result = await getCandidatesReporting(params).unwrap();
    const stats = await getCandidatesStats(params).unwrap();
    setStats(stats);
    setPagination(result.pagination);
    setCandidates(result.candidates);
    setLoading(false);
  };

  useEffect(() => {
    fetchCandidates();
  }, []);

  const download = async () => {
    setLoading(true);
    await downloadCandidatesReport({ queryParams }).unwrap();
    setLoading(false);
  };

  return (
    <Table.Context onQueryUpdate={fetchCandidates} defaultQuery={{ page: 1 }}>
      <Table.FloatingFilters className="u-margin-bottom--large">
        <Table.FloatingFiltersLeft>
          <Table.DateRangeFilter title="Created within" filterName="created_at" />
          <Table.DateRangeFilter title="Completed within" filterName="completed_at" />
          <Table.MultiSelectFilter
            title="Status"
            filterName="status"
            type="status"
            useDefault={false}
            options={[
              { name: 'Candidate', value: 'candidate' },
              { name: 'In-Progress', value: 'in-progress' },
              { name: 'Completed', value: 'completed' },
              { name: 'Overdue', value: 'overdue' },
              { name: 'Canceled', value: 'canceled' },
            ]}
          />
          <Table.MultiSelectFilter
            title="Team"
            filterName="team"
            type="status"
            useDefault={false}
            options={filters?.teams}
          />
          <Table.MultiSelectFilter
            title="Brand"
            filterName="brand"
            type="status"
            useDefault={false}
            options={filters?.brands}
          />
          <Table.MultiSelectFilter
            title="User"
            filterName="user"
            type="status"
            useDefault={false}
            options={filters?.users}
          />
          <Table.MultiSelectFilter
            title="Bundle"
            filterName="bundle"
            type="status"
            useDefault={false}
            options={filters?.bundles}
          />
        </Table.FloatingFiltersLeft>
        <Table.FloatingFiltersRight>
          <Button icon="download" onClick={download}>
            Export
          </Button>
        </Table.FloatingFiltersRight>
      </Table.FloatingFilters>
      <ReportingWidget>
        <ReportingWidget.Tile>
          <ReportingWidget.Badge>Total Candidates</ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.total_candidates}</ReportingWidget.Value>
          <ReportingWidget.Label></ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile>
          <ReportingWidget.Badge>Average Turnaround</ReportingWidget.Badge>
          <ReportingWidget.Value>
            {stats?.avg_turnaround_time}
            <span className="title-3">h</span>
          </ReportingWidget.Value>
          <ReportingWidget.Label></ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Divider />
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Candidate}
          disabled={queryParams.status && !queryParams.status.includes('candidate')}>
          <ReportingWidget.Badge>
            Candidate (
            {stats.total_candidates == 0
              ? 0
              : Math.round((stats?.candidate * 100) / stats.total_candidates)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.candidate}</ReportingWidget.Value>
          <ReportingWidget.Label>Candidates</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.InProgress}
          disabled={queryParams.status && !queryParams.status.includes('in-progress')}>
          <ReportingWidget.Badge>
            In-progress (
            {stats.total_candidates == 0
              ? 0
              : Math.round((stats?.in_progress * 100) / stats.total_candidates)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.in_progress}</ReportingWidget.Value>
          <ReportingWidget.Label>Candidates</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Overdue}
          disabled={queryParams.status && !queryParams.status.includes('overdue')}>
          <ReportingWidget.Badge>
            Overdue (
            {stats.total_candidates == 0
              ? 0
              : Math.round((stats?.overdue * 100) / stats.total_candidates)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.overdue}</ReportingWidget.Value>
          <ReportingWidget.Label>Candidates</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Completed}
          disabled={queryParams.status && !queryParams.status.includes('completed')}>
          <ReportingWidget.Badge>
            Complete (
            {stats.total_candidates == 0
              ? 0
              : Math.round((stats?.completed * 100) / stats.total_candidates)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.completed}</ReportingWidget.Value>
          <ReportingWidget.Label>Candidates</ReportingWidget.Label>
        </ReportingWidget.Tile>
        <ReportingWidget.Tile
          type={ReportingWidget.Types.Canceled}
          disabled={queryParams.status && !queryParams.status.includes('canceled')}>
          <ReportingWidget.Badge>
            Cancelled (
            {stats.total_candidates == 0
              ? 0
              : Math.round((stats?.canceled * 100) / stats.total_candidates)}
            %)
          </ReportingWidget.Badge>
          <ReportingWidget.Value>{stats?.canceled}</ReportingWidget.Value>
          <ReportingWidget.Label>Candidates</ReportingWidget.Label>
        </ReportingWidget.Tile>
      </ReportingWidget>
      <Table.TableContent>
        <Table.Filters>
          <Table.Search placeholder="Search..." />
        </Table.Filters>
        <Table className="u-margin--large">
          <Table.Head>
            <Table.Row>
              <Table.Header sortKey="candidates.first_name">Name</Table.Header>
              <Table.Header sortKey="candidates.status">Status</Table.Header>
              <Table.Header sortKey="candidates.email">Email</Table.Header>
              <Table.Header sortKey="candidates.bundle_id">Bundle</Table.Header>
              <Table.Header sortKey="candidates.created_at">Created</Table.Header>
              <Table.Header sortKey="candidates.submitted_at">Submitted</Table.Header>
              <Table.Header sortKey="candidates.completed_at">Completed</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {candidates.map(candidate => (
              <Table.Row key={candidate.id}>
                <Table.Col>
                  <Link className="u-link dark" to={`/check/${candidate.id}`}>
                    {candidate.first_name} {candidate.last_name}
                  </Link>
                </Table.Col>
                <Table.Col>
                  <Badge className={`u-status-${candidate.status}`}>{candidate.status}</Badge>
                </Table.Col>
                <Table.Col>{candidate.email}</Table.Col>
                <Table.Col>
                  {candidate.bundle_id
                    ? filters?.bundles.find(bundle => bundle.value == candidate.bundle_id)?.name
                    : '-'}
                </Table.Col>
                <Table.Col>
                  <Moment format={locale.dateSlashFormat}>{candidate.created_at}</Moment>
                </Table.Col>
                <Table.Col>
                  {candidate.submitted_at ? (
                    <Moment format={locale.dateSlashFormat}>{candidate.submitted_at}</Moment>
                  ) : (
                    '-'
                  )}
                </Table.Col>
                <Table.Col>
                  {candidate.completed_at ? (
                    <Moment format={locale.dateSlashFormat}>{candidate.completed_at}</Moment>
                  ) : (
                    '-'
                  )}
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Pagination {...pagination} />
        </Table>
      </Table.TableContent>
    </Table.Context>
  );
};
