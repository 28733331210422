import { useEffect } from 'react';
import { Error } from './Error';
import ReactGA from 'react-ga';
import { useDocumentTitle } from 'hooks/document-title';

const Error500 = () => {
  useDocumentTitle('Server Error | Checkmate');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return <Error>500</Error>;
};

export default Error500;
