import { forwardRef } from 'react';
import { Button } from 'components/FormComponents';
import { CandidateDetailsForm } from 'components/CandidateDetailsForm/CandidateDetailsForm';
import styles from './Step1Form.module.scss';
import cn from 'classnames';

export const Step1Form = forwardRef(({ onClick = () => {}, ...props }, ref) => (
  <div className={cn(styles.root)}>
    <div className={cn('card', 'card-with-border')}>
      <div className="u-margin--large">
        <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
          <h1 className="title-3 u-margin-y">Step 1. Enter Candidate Details</h1>
        </div>
      </div>
      <div className={cn('background-secondary', 'u-padding', 'card_border--top')}>
        <CandidateDetailsForm ref={ref} {...props} className="u-margin-top u-width-100" />
      </div>
      <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
        <Button type="secondary">Cancel</Button>
        <Button onClick={onClick}>Next</Button>
      </div>
    </div>
  </div>
));
