import { CustomButton } from '../components/CustomButton/CustomButton';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCandidateApplication } from 'api/candidate-application-slice';

const CandidateSuccess = () => {
  const { token } = useParams();

  const {
    application: { candidate, brand },
  } = useSelector(selectCandidateApplication);

  const handleClose = () => {
    if (brand.click_through_active && brand.click_through_candidate_active)
      window.location.replace(brand.click_through_candidate);
    else window.location.replace('https://www.checkmate.tech/app/submission-confirmation');
  };

  return (
    <CandidatePage token={token}>
      <CandidatePage.View>
        <CandidatePage.Card className={cn('d-flex', 'flex-column', 'align-items-center')}>
          <CandidatePage.Logo
            path={
              brand.brand_active && brand.brand_logo
                ? brand.brand_logo
                : '/assets/images/checkmate/icon.svg'
            }
          />
          <h3>Thanks, {candidate.name}</h3>
          <p className="u-text-centered u-margin-top">
            Your information has been successfully submitted - you do not need to do anything else
            and can simply close this window.
          </p>
          <CustomButton small brand={brand} onClick={handleClose}>
            Close
          </CustomButton>
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CandidateSuccess;
