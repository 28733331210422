import { useEffect, useState } from 'react';
import styles from './EditableSettings.module.scss';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { Collapse } from 'react-bootstrap';
import { Toggle } from '../FormComponents';
import cn from 'classnames';

export const EditableSettings = ({
  title,
  className,
  children,
  open,
  toggleable = false,
  name,
  onChange,
  register = () => {},
}) => {
  const [localOpen, setLocalOpen] = useState(open);

  useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  return (
    <div className={cn(styles.root, 'card', 'card-with-border', { [className]: className })}>
      <header className={cn(styles.header, { [styles.headerVisible]: localOpen })}>
        <div className="u-flex-box u-flex-align-center">
          {toggleable && onChange && (
            <Toggle
              className={cn('u-margin-right--small')}
              inputProps={{ checked: open, onChange }}
            />
          )}
          {toggleable && !onChange && (
            <Toggle className={cn('u-margin-right--small')} name={name} register={register} />
          )}
          {title}
        </div>
        <div className={styles.iconWrapper}>
          <FeatherIcon
            icon="chevron-up"
            className={styles.chevronIcon}
            onClick={() => {
              setLocalOpen(false);
            }}
          />
          <FeatherIcon
            icon="edit-2"
            className={styles.editIcon}
            onClick={() => {
              setLocalOpen(true);
            }}
          />
        </div>
      </header>
      <Collapse in={localOpen}>
        <div>
          <div className={styles.body}>{children}</div>
        </div>
      </Collapse>
    </div>
  );
};

EditableSettings.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  toggleable: PropTypes.bool,
  register: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
};
