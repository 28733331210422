import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = [];

export const getRoles = createAsyncThunk('getRoles', async () => {
  const res = await apiRequest(`roles`, {}, 'get');
  return res.result;
});

export const createRole = createAsyncThunk('createRole', async data => {
  const res = await apiRequest('roles', data, 'post');
  return res.result;
});

export const updateRole = createAsyncThunk('updateRole', async ({ id, data }) => {
  const res = await apiRequest(`roles/${id}`, data, 'put');
  return res.result;
});

export const deleteRole = createAsyncThunk('deleteRole', async id => {
  await apiRequest(`roles/${id}`, {}, 'delete');
  return id;
});

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRoles.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(createRole.fulfilled, (state, { payload }) => {
        return [...state, payload];
      })
      .addCase(updateRole.fulfilled, (state, { payload }) => {
        const updatedRole = payload;
        return state.map(role => (role.id === updatedRole.id ? updatedRole : role));
      })
      .addCase(deleteRole.fulfilled, (state, { payload: id }) => {
        return state.filter(role => role.id !== id);
      });
  },
});

export const selectRoles = state => state.roles;

export default rolesSlice.reducer;
