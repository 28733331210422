import { useState, useRef } from 'react';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { ChecksSelector } from 'components/ChecksSelector/ChecksSelector';
import { Button } from 'components/FormComponents';
import cn from 'classnames';

export const AddCheckModal = ({
  companyChecks,
  forms,
  visible,
  className,
  onSubmit = () => {},
  onClose = () => {},
}) => {
  const selectorRef = useRef(null);

  const [valid, setValidity] = useState(false);

  return (
    <AnimatedModal visible={visible} onClose={onClose} title="Add Check">
      <div className={cn({ [className]: className })}>
        <p className="u-margin-y t-small">
          Select the additional check types that are available with your account.
        </p>
        <ChecksSelector
          ref={selectorRef}
          companyChecks={companyChecks}
          forms={forms}
          setValidity={setValidity}
          onSubmit={onSubmit}
        />
        <Button
          onClick={() => selectorRef.current.submit()}
          disabled={!valid}
          className="u-width-100">
          Send request
        </Button>
      </div>
    </AnimatedModal>
  );
};
