import { useEffect, useState } from 'react';

export const useUserAgent = () => {
  const [userAgent, setUserAgent] = useState('');
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    setUserAgent(userAgent);
    setMobile(userAgent.includes('Mobile'));
  }, []);

  return {
    user_agent: userAgent,
    mobile,
  };
};
