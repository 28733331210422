import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import { saveAs } from 'file-saver';
import cookie from 'react-cookies';

export const refereesApi = createApi({
  reducerPath: 'refereesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/referees`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    createReferee: builder.mutation({
      query: data => ({
        url: '/',
        method: 'POST',
        body: data,
      }),
      transformResponse: ({ result }) => result,
    }),
    updateReferee: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: ({ result }) => result,
    }),
    deleteReferee: builder.mutation({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
    }),
    sendRefereeRequestEmail: builder.mutation({
      query: id => ({
        url: `/${id}/request`,
        method: 'POST',
      }),
      transformResponse: ({ result }) => result,
    }),
    sendRefereeRequestEmailByToken: builder.mutation({
      query: token => ({
        url: `/token/${token}/request`,
        method: 'POST',
      }),
      transformResponse: ({ result }) => result,
    }),
    sendReplacementRequestEmail: builder.mutation({
      query: ({ id, message }) => ({
        url: `/${id}/request/replacement`,
        method: 'POST',
        body: { message },
      }),
      transformResponse: ({ result }) => result,
    }),
    getPdfReport: builder.query({
      queryFn: async ({ id, filename }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/${id}/report_pdf`,
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          cache: 'no-cache',
        });
        saveAs(file, filename);
        return { data: null };
      },
    }),
    getRefereeTypeformParams: builder.query({
      query: id => `/${id}/typeform`,
      transformResponse: ({ result }) => result,
    }),
    getRefereeFormByToken: builder.query({
      query: token => `/token/${token}/form`,
      transformResponse: ({ result }) => result,
    }),
    getQuestionUpload: builder.query({
      queryFn: async ({ id, url }, api, extraOptions, baseQuery) => {
        const { data } = await baseQuery({
          url: `/${id}/report/question/upload?url=${url}`,
          responseHandler: async response => {
            const [, filename] = response.headers['content-disposition'].split('filename=');
            const blob = await response.blob();
            return { file: blob, filename };
          },
          cache: 'no-cache',
        });
        saveAs(data.file, data.filename);
        return { data: null };
      },
    }),
    getQuestionUploadByToken: builder.query({
      queryFn: async ({ token, url }, api, extraOptions, baseQuery) => {
        const { data } = await baseQuery({
          url: `/token/${token}/report/question/upload?url=${url}`,
          responseHandler: async response => {
            const [, filename] = response.headers['content-disposition'].split('filename=');
            const blob = await response.blob();
            return { file: blob, filename };
          },
          cache: 'no-cache',
        });
        saveAs(data.file, data.filename);
        return { data: null };
      },
    }),
    getRefereeResponse: builder.query({
      query: id => `/${id}/answers`,
      transformResponse: ({ result }) => result,
    }),
    getReportData: builder.query({
      query: token => `/token/${token}/report`,
      transformResponse: ({ result }) => result,
    }),
    getRefereeBrandByToken: builder.query({
      query: token => `/token/${token}/brand`,
      transformResponse: ({ result }) => result,
    }),
    getRefereeByToken: builder.query({
      query: token => `/token/${token}`,
      transformResponse: ({ result }) => result,
    }),
    getCompanyCheckConfigByToken: builder.query({
      query: token => `/token/${token}/company_check_config`,
      transformResponse: ({ result }) => result,
    }),
    saveFollowUpQuestion: builder.mutation({
      query: ({ token, follow_up_answer }) => ({
        url: `/token/${token}/followUpQuestion`,
        method: 'POST',
        body: { follow_up_answer },
      }),
    }),
    saveUtmSource: builder.mutation({
      query: ({ token, utm_source }) => ({
        url: `/token/${token}/history`,
        method: 'POST',
        body: { utm_source },
      }),
    }),
    verifyReferee: builder.query({
      query: ({ token, linkedin_profile, user_agent }) =>
        `/token/${token}/typeform?linkedin_profile=${linkedin_profile}&user_agent=${user_agent}`,
      transformResponse: ({ result }) => result,
    }),
    saveResponse: builder.mutation({
      query: ({ token, params }) => ({
        url: `/token/${token}`,
        method: 'POST',
        body: params,
      }),
    }),
    getRefereeUpload: builder.query({
      query: ({ token, id }) => `/token/${token}/uploads/${id}`,
      transformResponse: ({ result }) => result,
    }),
    signRefereeUpload: builder.query({
      query: ({ token, params }) => `/token/${token}/uploads/sign?${toQueryParams(params)}`,
      transformResponse: ({ result }) => result,
    }),
    createRefereeUpload: builder.mutation({
      query: ({ token, params }) => ({
        url: `/token/${token}/uploads`,
        method: 'POST',
        body: params,
      }),
      transformResponse: ({ result }) => result,
    }),
    deleteRefereeUpload: builder.mutation({
      query: ({ token, id }) => ({
        url: `/token/${token}/uploads/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetRefereeUploadQuery,
  useLazySignRefereeUploadQuery,
  useCreateRefereeUploadMutation,
  useDeleteRefereeUploadMutation,
  useSaveResponseMutation,
  useLazyVerifyRefereeQuery,
  useLazyGetRefereeFormByTokenQuery,
  useSaveUtmSourceMutation,
  useGetRefereeByTokenQuery,
  useSendRefereeRequestEmailByTokenMutation,
  useSaveFollowUpQuestionMutation,
  useGetRefereeBrandByTokenQuery,
  useGetCompanyCheckConfigByTokenQuery,
  useLazyGetQuestionUploadByTokenQuery,
  useLazyGetReportDataQuery,
  useDeleteRefereeMutation,
  useGetRefereeResponseQuery,
  useLazyGetQuestionUploadQuery,
  useLazyGetRefereeTypeformParamsQuery,
  useCreateRefereeMutation,
  useUpdateRefereeMutation,
  useSendRefereeRequestEmailMutation,
  useSendReplacementRequestEmailMutation,
  useLazyGetPdfReportQuery,
} = refereesApi;
