import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './DatePicker.module.scss';
import PropTypes from 'prop-types';
import DatePickerComponent from 'react-datepicker';
import cn from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export const DatePicker = ({
  value,
  className,
  minDate,
  format = 'dd-MM-yyyy',
  outputFormat = 'DD/MM/YYYY',
  error,
  useDefaultToday = true,
  onChange = () => {},
}) => {
  const [date, setDate] = useState(useDefaultToday ? new Date() : null);

  // Set given value as date
  useEffect(() => {
    if (!value) return;

    const dateValue = moment(value, outputFormat);
    if (!dateValue.isValid()) return;

    setDate(dateValue.toDate());
  }, [value]);

  // Send date value in output format
  useEffect(() => {
    if (!date) return;

    const newValue = moment(date).format(outputFormat);
    onChange(newValue);
  }, [date]);

  return (
    <div className={cn(styles.root, { [styles.rootInvalid]: !!error, [className]: className })}>
      <DatePickerComponent
        dateFormat={format}
        onChange={setDate}
        selected={date}
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
};

DatePicker.propTypes = {
  className: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  outputFormat: PropTypes.string,
};
