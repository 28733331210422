import { useEffect, useState } from 'react';
import { Loading } from 'components/Loading/Loading';
import { verifySSO } from 'api/authentication-slice';
import { useLazyGetSSOUrlQuery } from 'api/authentication-api';
import { FormMessage } from 'components/FormComponents';
import { useNavigate, useParams } from 'react-router-dom';
import { useDocumentTitle } from 'hooks/document-title';
import styles from './SingleSignOnDomain.module.scss';
import { useQueryParams } from 'hooks/query-params';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

const SingleSignOnDomain = () => {
  useDocumentTitle('Single Sign On');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [code] = useQueryParams(['code']);
  const [messageText, setMessageText] = useState();

  let { domain } = useParams();

  const [getSSOUrl] = useLazyGetSSOUrlQuery();

  const getAuthUrl = async domain => {
    setLoading(true);
    try {
      const result = await getSSOUrl(domain).unwrap();
      window.location.replace(result);
    } catch (e) {
      setMessageText('Domain not found.');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      setLoading(true);
      dispatch(verifySSO(code)).then(
        () => navigate('/dashboard'),
        error => {
          setMessageText('Something went wrong. Please contact support');
          setLoading(false);
        },
      );
    } else {
      setLoading(false);
    }
  }, [code]);

  useEffect(() => {
    if (!domain) return;
    getAuthUrl(domain);
  }, [domain]);

  return (
    <div className={styles.root}>
      <Loading active={loading} />
      <div className={cn('card u-margin-top', 'card-with-shadow', styles.card)}>
        <div className={cn('card_content', styles.content)}>
          <img
            onClick={() => window.location.replace('https://www.checkmate.tech')}
            className={cn(styles.logo, 'u-margin-bottom--large')}
            src="/assets/images/checkmate/icon-label.svg"
            alt="Checkmate Logo"
          />
          <h2 className="design-marker title-2 u-margin-bottom--large">Single Sign On</h2>
          <FormMessage
            message={messageText}
            className="u-margin-top--0"
            onClose={() => setMessageText(null)}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleSignOnDomain;
