import { useEffect, useRef, useState } from 'react';
import RefereeCard from 'components/RefereeCard/RefereeCard';
import { MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { AddRefereeCard } from './components/AddRefereeCard/AddRefereeCard';
import { NewRefereeForm } from './components/NewRefereeForm/NewRefereeForm';
import { RefereeDetailsForm } from './components/RefereeDetailsForm/RefereeDetailsForm';
import Alert from 'components/Alert/Alert';
import { useLazyGetRefereeTypeformParamsQuery } from 'api/referees-api';
import { createSlider } from '@typeform/embed';
import '@typeform/embed/build/css/slider.css';
import styles from './Referees.module.scss';

export const Referees = ({
  check,
  candidate,
  company,
  referees = [],
  targetId,
  setLoading = () => {},
  updateReferee = () => {},
  sendRequestEmail = () => {},
  addReferee = () => {},
  onMessagePopUp = () => {},
  deleteReferee = () => {},
}) => {
  const topRef = useRef(null);

  const [getRefereeTypeformParams] = useLazyGetRefereeTypeformParamsQuery();

  const [alertParams, setAlertParams] = useState({});
  const [showRequestAlert, setShowRequestAlert] = useState(false);
  const [showRequestConfirmationAlert, setShowRequestConfirmationAlert] = useState(false);

  const [showNewRefereeForm, setShowNewRefereeForm] = useState(false);
  const [isRefereeFromBackup, setIsRefereeFormBackup] = useState(false);

  const [selectedRefereeId, setSelectedRefereeId] = useState(null);
  const [selectedReferee, setSelectedReferee] = useState(null);

  // Set selected referee id
  useEffect(() => {
    if (targetId) setSelectedRefereeId(parseInt(targetId));
  }, [targetId]);

  // Change selected referee
  useEffect(() => {
    if (!selectedRefereeId) {
      setSelectedReferee(null);
      return;
    }
    setSelectedReferee(referees.find(referee => referee.id === selectedRefereeId));
    setShowNewRefereeForm(false);
  }, [selectedRefereeId]);

  // Answer on behalf
  const openRefereeForm = async refereeId => {
    setLoading(true);
    const { typeform_id, hidden } = await getRefereeTypeformParams(refereeId).unwrap();
    setLoading(false);

    const { open, close } = createSlider(typeform_id, {
      hidden,
      hideHeaders: true,
      hideFooter: true,
      onSubmit: async () => {
        close();
        setLoading(true);
        await updateReferee(refereeId, { answered_on_behalf_user_id: true, overdue: false });
        const referee = referees.find(referee => referee.id === refereeId);
        setLoading(false);
        onMessagePopUp(`Reference on behalf of ${referee.name} completed`, MESSAGE_STATE_SUCCESS);
      },
    });
    open();
  };

  // New referee
  const handleNewRefereeSubmission = async refereeData => {
    setLoading(true);
    refereeData.backup = isRefereeFromBackup;
    refereeData.candidate_id = candidate.id;
    refereeData.candidate_check_id = check.id;
    const referee = await addReferee(refereeData);
    setShowNewRefereeForm(false);
    setShowRequestAlert(true);
    setAlertParams({ refereeId: referee.id });
    setLoading(false);
  };

  // Update referee
  const handleUpdate = async (updateFields, completed) => {
    setLoading(true);
    await updateReferee(selectedRefereeId, updateFields);
    setLoading(false);
    topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    onMessagePopUp('Referee updated', MESSAGE_STATE_SUCCESS);
    setShowRequestAlert(!completed);
    setAlertParams({ refereeId: selectedRefereeId });
  };

  // Render referee cards
  const renderCards = () => {
    let primaryCards = [];
    let backupCards = [];
    referees.forEach(referee => {
      var card = (
        <RefereeCard
          key={referee.id}
          title="REFEREE NAME"
          name={referee.name}
          className={styles.card}
          details={referee.role + ' @ ' + referee.company_name}
          returnedId={referee.id}
          disabled={referee.replaced_by}
          tag={
            referee.replaced_by
              ? `Replaced by ${referees.find(r => r.id === referee.replaced_by).name}`
              : false
          }
          request
          onBehalfButtonClick={openRefereeForm}
          onClick={setSelectedRefereeId}
          onButtonClick={() => {
            setAlertParams({ refereeId: referee.id });
            setShowRequestConfirmationAlert(true);
          }}
          selected={referee.id === selectedRefereeId}
          hideButtons={referee.completed}
        />
      );
      if (referee.backup) backupCards.push(card);
      else primaryCards.push(card);
    });

    if (check?.details.backup_referee && backupCards.length > 0) {
      return (
        <div className={styles.cardsContainer}>
          <p>Primary referees</p>
          {primaryCards}
          {check?.details.referees < 3 && (
            <AddRefereeCard
              onClick={() => {
                setShowNewRefereeForm(true);
                setIsRefereeFormBackup(false);
                setSelectedRefereeId(null);
              }}
            />
          )}
          <div className={styles.divider} />
          <p>Backup referees</p>
          {backupCards}
          {backupCards.length === 0 && (
            <AddRefereeCard
              onClick={() => {
                setShowNewRefereeForm(true);
                setIsRefereeFormBackup(true);
                setSelectedRefereeId(null);
              }}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className={styles.cardsContainer}>
          {primaryCards}
          {!candidate.canceled && (check?.details.bypassed || check?.details.referees < 3) && (
            <AddRefereeCard
              onClick={() => {
                setShowNewRefereeForm(true);
                setIsRefereeFormBackup(false);
                setSelectedRefereeId(null);
              }}
            />
          )}
        </div>
      );
    }
  };

  return (
    <div className={styles.root}>
      <Alert
        show={showRequestConfirmationAlert}
        title="Are you sure you want to send a new request?"
        ok="Send new request"
        cancel="Cancel"
        params={alertParams}
        onOk={params => {
          setShowRequestConfirmationAlert(false);
          setShowRequestAlert(false);
          sendRequestEmail(params.refereeId);
        }}
        onCancel={() => {
          setShowRequestConfirmationAlert(false);
          setShowRequestAlert(false);
        }}
      />
      <Alert
        show={showRequestAlert}
        title="Would you like to send a new request to the referee?"
        ok="Send New Request"
        cancel="No Thanks"
        params={alertParams}
        onOk={params => {
          setShowRequestConfirmationAlert(false);
          setShowRequestAlert(false);
          sendRequestEmail(params.refereeId);
        }}
        onCancel={() => {
          setShowRequestConfirmationAlert(false);
          setShowRequestAlert(false);
        }}
      />
      <div className={styles.top} ref={topRef} />
      <div className={styles.title}>Referees</div>
      <div className={styles.subtitle}>
        {referees.length > 0
          ? 'View and update the list of referees provided by the candidate'
          : 'No referees have been provided'}
      </div>

      <div className={styles.content}>
        {renderCards()}
        <div className={styles.formContainer}>
          {showNewRefereeForm && (
            <NewRefereeForm
              company={company}
              onSubmit={handleNewRefereeSubmission}
              onClose={() => setShowNewRefereeForm(false)}
            />
          )}
          {selectedReferee && (
            <RefereeDetailsForm
              referee={selectedReferee}
              onUpdate={handleUpdate}
              deleteReferee={deleteReferee}
              onClose={() => setSelectedReferee(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
