import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = [];

export const getTeams = createAsyncThunk('getTeams', async () => {
  const res = await apiRequest(`teams`, {}, 'get');
  return res.result;
});

export const createTeam = createAsyncThunk('createTeam', async data => {
  const res = await apiRequest('teams', data, 'post');
  return res.result;
});

export const updateTeam = createAsyncThunk('updateTeam', async ({ id, data }) => {
  const res = await apiRequest(`teams/${id}`, data, 'put');
  return res.result;
});

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTeams.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(createTeam.fulfilled, (state, { payload }) => {
        return [...state, payload];
      })
      .addCase(updateTeam.fulfilled, (state, { payload }) => {
        const updatedTeam = payload;
        return state.map(team => (team.id === updatedTeam.id ? updatedTeam : team));
      });
  },
});

export const selectTeams = state => state.teams;

export default teamsSlice.reducer;
