import styles from './RowDetails.module.scss';
import { IconButton } from 'components/IconButton/IconButton';
import cn from 'classnames';
import { Badge } from 'react-bootstrap';

export const RowDetails = ({ children, className }) => (
  <div className={cn(styles.root, { [className]: className })}>{children}</div>
);

const Column = ({ title, data }) => (
  <div className={styles.column}>
    <span className={styles.title}>{title}</span>
    <span className={styles.data}>{data}</span>
  </div>
);

const Action = ({ icon, tooltip, onClick = () => {} }) => (
  <IconButton prefix="fa" className={styles.action} icon={icon} tip={tooltip} onClick={onClick} />
);

const Status = ({ status }) => (
  <Badge className={cn(`u-status-${status}`, 'u-margin-left', styles.status)}>{status || ''}</Badge>
);

RowDetails.Column = Column;
RowDetails.Action = Action;
RowDetails.Status = Status;
