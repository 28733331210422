import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { IntegrationCard } from '..';
import { useState } from 'react';
import { useOAuth } from 'hooks/oauth';

export const KnowMyIntegration = ({
  config,
  integration,
  connect = () => {},
  disconnect = () => {},
  setLoading = () => {},
  popUpMessage = () => {},
}) => {
  const [enabled, setEnabled] = useState(integration.enabled);

  const onConnect = async code => {
    setLoading(true);
    try {
      await connect(code);
      popUpMessage('KnowMy integrated with Checkmate', MESSAGE_STATE_SUCCESS);
      setEnabled(true);
    } catch (e) {
      popUpMessage(
        'KnowMy integration failed. Please check your credentials and try again.',
        MESSAGE_STATE_ERROR,
      );
      setEnabled(false);
    } finally {
      setLoading(false);
    }
  };

  const openAuth = useOAuth({
    authUri: `${process.env.REACT_APP_KNOWMY_URL}/integration`,
    clientId: process.env.REACT_APP_KNOWMY_CLIENT_ID,
    responseType: 'code',
    redirectUri: `${process.env.REACT_APP_WEB_URL}/assets/knowmy_callback.html`,
    scope: 'offline_access journeys:read_write',
    callbackAction: 'knowmy_callback',
    callback: onConnect,
  });

  const toggleIntegration = async e => {
    const value = e.target.checked;
    if (integration.enabled === value) return;

    if (value) {
      openAuth();
    } else {
      setLoading(true);
      await disconnect();
      setLoading(false);
      setEnabled(false);
    }
  };

  return <IntegrationCard {...config} enabled={enabled} onToggle={toggleIntegration} />;
};
