import { useState, useEffect } from 'react';
import { DynamicTypeInput } from 'components/Form/DynamicTypeInput/DynamicTypeInput';
import { Button, DatePicker, Input, InputGroup, Select } from 'components/FormComponents';
import { Controller, useForm } from 'react-hook-form';
import { useGetIdVerificationTypesQuery } from 'api/id-verifications-api';
import { Alpha3Countries } from 'constants/countries';
import moment from 'moment';

export const IdVerificationForm = ({ idVerification, checkValues, onVerify = () => {} }) => {
  const { reset, register, setValue, control, watch, handleSubmit } = useForm({
    shouldUnregister: true,
  });

  const { data: idTypes } = useGetIdVerificationTypesQuery();

  const [selectedIdType, setSelectedIdType] = useState();

  // Set default values
  useEffect(() => {
    if (!checkValues && !idVerification) return;

    if (idVerification?.details) {
      reset(idVerification.details);
    } else {
      const dob = checkValues.dob
        ? moment(checkValues.dob, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null;
      const countryCode = checkValues.postal_country
        ? Alpha3Countries.find(c => c.name === checkValues.postal_country)?.code
        : null;
      reset({
        name: {
          givenName: checkValues.first_name,
          familyName: checkValues.last_name,
        },
        dateOfBirth: {
          dateOfBirth: dob,
        },
        addresses: [{ country: countryCode }],
      });
    }
  }, [checkValues, idVerification]);

  const changeIdType = idTypeCode => {
    setSelectedIdType(idTypes.find(type => type.code === idTypeCode));
    setValue('identityDocs[0].idType', selectedIdType?.type);
  };

  return (
    <form
      style={{ width: '500px' }}
      className="u-margin-top--large"
      onSubmit={handleSubmit(onVerify)}>
      <InputGroup title="First name">
        <Input name="name.givenName" register={register} validators={{ required: true }} />
      </InputGroup>
      <InputGroup title="Last name">
        <Input name="name.familyName" register={register} validators={{ required: true }} />
      </InputGroup>
      <InputGroup title="Date of birth">
        <Controller
          control={control}
          name="dateOfBirth.dateOfBirth"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <DatePicker outputFormat="YYYY-MM-DD" value={value} onChange={onChange} />
          )}
        />
      </InputGroup>
      <InputGroup title="Country of residence">
        <Select
          name="addresses[0].country"
          placeholder="Select Country of residence..."
          rules={{ required: true }}
          register={register}
          value={watch('addresses[0].country')}>
          {Alpha3Countries.map(country => (
            <Select.Item key={country.code} value={country.code}>
              {country.name}
            </Select.Item>
          ))}
        </Select>
      </InputGroup>

      <div className="card card-with-border u-padding">
        <InputGroup title="ID Type">
          <Select
            inputProps={{
              value: selectedIdType?.code,
              onChange: e => changeIdType(e.target.value),
            }}
            value={selectedIdType?.code}
            placeholder="Select Bundle...">
            {idTypes?.map(idType => (
              <Select.Item key={idType.code} value={idType.code}>
                {idType.name}
              </Select.Item>
            ))}
          </Select>
          <Input register={register} name="identityDocs[0].idType" inputProps={{ hidden: true }} />
        </InputGroup>
        {selectedIdType?.params?.map(param => (
          <DynamicTypeInput
            key={param.code}
            title={param.name}
            fieldName={`identityDocs[0].${param.code}`}
            fieldOptions={param.options}
            type={param.type}
            control={control}
            register={register}
            watch={watch}
          />
        ))}
        {selectedIdType?.extra_params?.map((param, i) => (
          <>
            <Input
              register={register}
              name={`identityDocs[0].extraData[${i}].kvpKey`}
              inputProps={{ hidden: true, value: param.code }}
            />
            <DynamicTypeInput
              title={param.name}
              fieldName={`identityDocs[0].extraData[${i}].kvpValue`}
              fieldOptions={param.options}
              type={param.type}
              control={control}
              register={register}
              watch={watch}
            />
          </>
        ))}
      </div>

      <Button icon="shield" className="u-width-100 u-margin-top--large" submit>
        Verify
      </Button>
    </form>
  );
};
