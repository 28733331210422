import { useBrandingStyleVariables } from 'hooks/branding-style-variables';
import styles from './Loader.module.scss';
import cn from 'classnames';

export const Loader = ({ className, brand }) => {
  const bradingStyleVariables = useBrandingStyleVariables(brand);

  return (
    <div className={cn(styles.container, { [className]: className })} style={bradingStyleVariables}>
      <div className={styles.root}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
