import VerificationInput from 'react-verification-input';
import styles from './CodeInput.module.scss';

export const CodeInput = ({ className = '', autoFocus = true, onComplete = () => {} }) => (
  <VerificationInput
    type="number"
    validChars="0-9"
    classNames={{
      container: [className],
      character: [styles.character],
      characterInactive: [styles.characterInactive],
    }}
    onComplete={onComplete}
    autoFocus={autoFocus}
  />
);
