import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, Select } from 'components/FormComponents';
import { useEffect, useState } from 'react';

export const NewRefereeModal = ({
  visible,
  formDetails,
  forms,
  onSelect = () => {},
  onClose = () => {},
}) => {
  const [index, setIndex] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (forms.length < 1) return;

    setOptions(
      formDetails.map(reference => {
        const candidateForm = forms.find(form => form.id === reference.candidate);
        const refereeForm = forms.find(form => form.id === reference.referee);

        return { candidateForm, refereeForm };
      }),
    );
  }, [forms, formDetails]);

  const handleSelection = () => onSelect(index);

  return (
    <AnimatedModal
      title="Choose form"
      visible={visible}
      onClose={onClose}
      className="u-min-width-small">
      <Select
        inputProps={{ onChange: e => setIndex(e.target.value) }}
        value={index}
        className="u-margin-top--large"
        useDefault>
        {options.map((option, index) => (
          <Select.Item value={index}>
            {option.candidateForm?.name} / {option.refereeForm?.name}
          </Select.Item>
        ))}
      </Select>
      <Button className="u-margin-top--large u-width-100" onClick={handleSelection}>
        Select
      </Button>
    </AnimatedModal>
  );
};
