import { useRef } from 'react';
import styles from './AnimatedModal.module.scss';
import { useClickOutside } from '../../hooks/click-outside';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';

export const AnimatedModal = ({
  visible,
  children,
  className,
  title,
  noClose = false,
  onClose = () => {},
  small = false,
}) => {
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, onClose);

  return (
    <div className={cn(styles.root, { [styles.rootVisible]: visible })}>
      <div
        className={cn(styles.contentWrapper, { [styles.contentWrapperSmall]: small })}
        ref={wrapperRef}>
        {!noClose && <FeatherIcon icon="x" onClick={onClose} className={styles.closeBtn} />}
        {title && <h1 className={styles.title}>{title}</h1>}
        <div className={cn({ [className]: className })}>{children}</div>
      </div>
    </div>
  );
};
