import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { CandidateFooter } from './CandidateFooter/CandidateFooter';
import {
  getCandidateApplication,
  selectCandidateApplication,
} from 'api/candidate-application-slice';
import { Loading } from '../../components/Loading/Loading';
import styles from './CandidatePage.module.scss';
import cn from 'classnames';
import { useDocumentTitle } from 'hooks/document-title';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { analytics } from 'services/segment';

const CandidatePage = ({
  loading,
  token,
  fullScreen,
  footer = false,
  children,
  withTopLogo,
  brand,
}) => {
  useDocumentTitle();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    application: { candidate },
    fetched,
    idVerification,
  } = useSelector(selectCandidateApplication);

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    window.onpopstate = () => {
      navigate(`/form_submission/candidate/${token}`);
    };
  }, []);

  useEffect(() => {
    if (!candidate?.id) return;

    window.Intercom('boot', {
      app_id: 'yh3o8vqn',
      name: candidate.name,
      email: candidate.email,
      user_id: candidate.id,
      user_type: 'candidate',
      created_at: moment(candidate.created_at, 'DD/MM/YYYY').unix(),
    });

    analytics.identify({
      userType: 'candidate',
      userId: candidate.id,
      userName: candidate.name,
      userEmail: candidate.email,
      isIdVerification: idVerification.required,
      groupId: candidate.company_id,
    });
  }, [candidate]);

  useEffect(() => {
    if (fetched) setFetching(false);
    else
      dispatch(getCandidateApplication(token)).then(({ payload }) => {
        if (payload.result?.candidate_checks.length < 1)
          navigate(`/form_submission/candidate/${CandidateCheckScreens.SUCCESS}/${token}`);

        setFetching(false);
      });
  }, []);

  return (
    <div className={styles.root}>
      {withTopLogo && (
        <img
          className={styles.root_logo}
          src={
            brand?.brand_active && brand?.brand_logo
              ? brand.brand_logo
              : '/assets/images/checkmate/icon.svg'
          }
        />
      )}
      <Loading active={fetching || loading}></Loading>
      <div className={cn(styles.content, { [styles.contentFullScreen]: fullScreen })}>
        {children}
      </div>
      {footer && <CandidateFooter />}
    </div>
  );
};

const Header = ({ brand, candidateName, subtitle }) => (
  <header className={cn('d-flex', 'flex-column', 'justify-content-center', 'align-items-center')}>
    <img
      className={styles.logo}
      src={
        brand?.brand_active && brand?.brand_logo
          ? brand.brand_logo
          : '/assets/images/checkmate/icon.svg'
      }
    />
    <h3>👋 Hi {candidateName}</h3>
    <p className={cn('u-margin-top', 'u-margin-bottom--0', 't-body')}>{subtitle}</p>
  </header>
);

const Logo = ({ path }) => <img className={styles.logo} src={path} />;

const View = ({ children, className }) => (
  <div className={cn(styles.view, { [className]: className })}>{children}</div>
);

const Form = ({ children, className }) => (
  <form className={cn(styles.form, { [className]: className })}>{children}</form>
);

const Card = ({ children, className }) => (
  <div
    className={cn(styles.card, 'card', 'card-with-border', 'u-padding--large', {
      [className]: className,
    })}>
    {children}
  </div>
);

CandidatePage.Logo = Logo;
CandidatePage.View = View;
CandidatePage.Form = Form;
CandidatePage.Card = Card;
CandidatePage.Header = Header;

export default CandidatePage;
