import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const notificationSettingsApi = createApi({
  reducerPath: 'notificationSettingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/notification_settings`,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getNotificationSetting: builder.query({
      query: () => `/`,
    }),
    updateNotificationSetting: builder.mutation({
      query: params => ({
        url: '/',
        method: 'PUT',
        body: params,
      }),
    }),
  }),
});

export const { useGetNotificationSettingQuery, useUpdateNotificationSettingMutation } =
  notificationSettingsApi;
