import { useEffect } from 'react';
import { Loading } from 'components/Loading/Loading';
import { validateUser } from 'api/user-slice';
import cookie from 'react-cookies';
import { useDocumentTitle } from 'hooks/document-title';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const ValidateUser = () => {
  useDocumentTitle('Validate User | Checkmate');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { token } = useParams();

  useEffect(() => {
    dispatch(validateUser(token)).then(
      ({ payload }) => {
        cookie.save('token', payload, { path: '/' });
        navigate('/dashboard/validate');
      },
      error => navigate('/not_found_error'),
    );
  }, []);

  return <Loading active={true} />;
};

export default ValidateUser;
