import React, { useEffect, useState } from 'react';
import styles from './AssessmentMessage.module.scss';
import cn from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { useParams } from 'react-router-dom';
import { useQueryParams } from 'hooks/query-params';

export const AssessmentMessage = () => {
  const { severity } = useParams();
  const [message] = useQueryParams(['message']);

  const [icon, setIcon] = useState('info');

  useEffect(() => {
    if (!severity) return;
    switch (severity) {
      case 'info':
        setIcon('info');
        break;
      case 'error':
        setIcon('alert-circle');
        break;
    }
  }, [severity]);

  return (
    <div className={cn(styles.root, styles[severity])}>
      <div className={styles.message}>
        <FeatherIcon className={styles.icon} icon={icon} />
        <p>{message}</p>
      </div>
    </div>
  );
};
