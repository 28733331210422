import { useEffect } from 'react';
import { FormResponse } from 'components/Form/FormResponse/FormResponse';
import { useGetReferenceCandidateResponseQuery } from 'api/candidates-api';

export const CandidateAnswers = ({ candidate, referee, setLoading = () => {} }) => {
  const { data: response, isLoading } = useGetReferenceCandidateResponseQuery({
    id: candidate.id,
    refereeId: referee.id,
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <div className="u-padding--large">
      <FormResponse
        form={response?.form}
        answers={response?.answers}
        params={{
          candidate: candidate.name,
          job: candidate.job_role,
          team: candidate.team_name,
        }}
      />
    </div>
  );
};
