import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import { Fragment, useState, useEffect } from 'react';
import { ToggleLabel, Input, Select, InputGroup } from 'components/FormComponents';
import { Collapse } from 'react-bootstrap';
import cn from 'classnames';
import styles from './OpinionScaleSettings.module.scss';

export const OpinionScaleSettings = ({ register, name, field, watch }) => (
  <Fragment>
    <EditableSettings title="Settings" className="u-margin-top" startOpen={true}>
      <ToggleLabel register={register} name={`${name}.required`} label="Required" />
      <div className="u-divider u-margin-y--small" />
      <Fragment>
        <ToggleLabel register={register} name={`${name}.use_description`} label="Add description" />
        <Collapse in={field.use_description}>
          <div>
            <Input
              register={register}
              name={`${name}.description`}
              className="u-margin-top--small u-margin-bottom"
              placeholder="Enter description..."
            />
          </div>
        </Collapse>
      </Fragment>
      <div className="u-divider u-margin-y--small" />
      <Fragment>
        <ToggleLabel
          register={register}
          name={`${name}.options.use_custom_steps`}
          label="Customise steps"
        />
        <Collapse in={field.options?.use_custom_steps}>
          <div className="u-margin-top--small">
            <div className="d-flex align-items-center">
              <Select
                register={register}
                name={`${name}.options.steps_start`}
                value={watch(`${name}.options.steps_start`)}
                className="u-margin-right"
                useDefault>
                <Select.Item value="0">0</Select.Item>
                <Select.Item value="1">1</Select.Item>
              </Select>
              <p className="u-margin-0 t-body">to</p>
              <Select
                register={register}
                name={`${name}.options.steps_end`}
                value={watch(`${name}.options.steps_end`)}
                className="u-margin-left"
                useDefault>
                <Select.Item value="3">3</Select.Item>
                <Select.Item value="5">5</Select.Item>
                <Select.Item value="7">7</Select.Item>
                <Select.Item value="10">10</Select.Item>
              </Select>
            </div>
            <div className="d-flex align-items-center u-margin-top">
              <InputGroup
                title={`"${field.options?.steps_start}" Label`}
                className="u-margin-right--large u-margin-bottom--0">
                <Input
                  name={`${name}.options.label_start`}
                  placeholder="Enter label"
                  register={register}
                />
              </InputGroup>
              <InputGroup
                title={`"${field.options?.steps_end}" Label`}
                className="u-margin-left u-margin-bottom--small">
                <Input
                  name={`${name}.options.label_end`}
                  placeholder="Enter label"
                  register={register}
                />
              </InputGroup>
            </div>
          </div>
        </Collapse>
      </Fragment>
    </EditableSettings>
    <hr className="u-margin-y--large" />
    <ScalePreview {...field.options} />
  </Fragment>
);

const ScalePreview = ({ use_custom_steps, steps_start, steps_end, label_start, label_end }) => {
  const [scale, setScale] = useState([]);

  useEffect(() => {
    const start = use_custom_steps && steps_start ? parseInt(steps_start) : 1;
    const end = use_custom_steps && steps_end ? parseInt(steps_end) : 10;
    const array = Array(end - start + 1)
      .fill()
      .map((_, index) => start + index);
    setScale(array);
  }, [use_custom_steps, steps_start, steps_end]);

  return (
    <div className={cn(styles.scalePreview, 'u-margin-bottom--large')}>
      <div className={styles.scaleWrapper}>
        {scale.map(number => (
          <span key={number} className="t-body">
            {number}
          </span>
        ))}
      </div>
      <label className={styles.labelStart}>{label_start}</label>
      <label className={styles.labelEnd}>{label_end}</label>
    </div>
  );
};
