import { List } from 'components/List/List';
import { SectionedForm } from 'components/SectionedForm/SectionedForm/SectionedForm';
import { useState } from 'react';
import cn from 'classnames';

export const LibraryForms = ({ forms }) => {
  const [form, setForm] = useState();

  return (
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <List
        title="Library Forms"
        subtitle="Access a library of reference questionnaires based on common industries and roles."
        className="u-margin-right--large u-flex-align-self-normal">
        <List.Category title="Library Reference Forms">
          {forms.map(form => (
            <List.Item key={form.id} title={form.name} value={form} onClick={setForm} />
          ))}
        </List.Category>
      </List>
      {form && <SectionedForm form={form} />}
    </div>
  );
};
