import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { CustomButton } from '../../../components/CustomButton/CustomButton';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

export const SignReviewModal = ({ brand, visible, token, onClose = () => {} }) => {
  const navigate = useNavigate();

  return (
    <AnimatedModal
      className={cn('d-flex', 'flex-column', 'align-items-center')}
      visible={visible}
      onClose={onClose}>
      <img
        className="u-width-25 u-margin-top--large"
        src="assets/images/icons/signature_icon.svg"
      />
      <p className="u-bold u-margin-y">Almost there, your last step is to review and sign</p>
      <CustomButton
        brand={brand}
        icon="edit-3"
        onClick={() =>
          navigate(`/form_submission/candidate/${CandidateCheckScreens.SIGN}/${token}`)
        }
        small>
        Review and Sign
      </CustomButton>
    </AnimatedModal>
  );
};
