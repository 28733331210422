import cn from 'classnames';
import { IconButton } from 'components/IconButton/IconButton';

export const AddRefereeCard = ({ onClick = () => {} }) => {
  return (
    <div
      className={cn(
        'd-flex',
        'align-items-center',
        'justify-content-between',
        'card',
        'card-with-border',
        'u-padding',
      )}>
      <h1 className="title-4">Add referee</h1>
      <IconButton icon="plus" onClick={onClick} />
    </div>
  );
};
