import { useEffect } from 'react';
import ReactGA from 'react-ga';
import styles from './DeletionPolicy.module.scss';
import { Link } from 'react-router-dom';

export const DeletionPolicy = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    window.scrollTo(0, 0);
    document.title = 'Deletion Policy | Checkmate';
  }, []);

  return (
    <div className={styles.root}>
      <Link to="">
        <img src="assets/images/checkmate/icon-label.svg" alt="checkmate" />
      </Link>
      <h1 className={styles.title}>Checkmate Data Retention and Deletion Policy</h1>
      <br />
      <p>
        The privacy and security of candidate information are of utmost importance to us here at
        Checkmate.
      </p>
      <p>
        It’s essential that we offer an easy yet secure solution. We’re constantly innovating and
        investing to ensure we have the most robust, safe solution to ensure we handle sensitive
        information gathered while completing background checks with respect.
      </p>
      <p>
        Checkmate complies with the relevant privacy legislation in New Zealand and Australia, which
        sets out the requirements for collecting, using, storage and disposal of personal data.
      </p>
      <p>
        To help you better understand how we handle and manage your data, we’ve outlined our policy
        below.
      </p>
      <p>
        Checkmate requires identification documents to complete our pre-employment checks. This may
        include but not be limited to an image of a candidate’s passport, driver’s license, firearms
        license, address details, signature and other identifiable information.
      </p>
      <p>
        This information is collected from candidates using a secure encrypted link. Once data is
        collected, it is stored on secure cloud hosting storage (AWS) as is encrypted at rest. The
        information is then relayed alongside a candidate signed consent to the various government
        entities conducting the check.
      </p>
      <p>
        Once the check is completed and returned, Checkmate is required to retain identification
        documents alongside candidates consent for a minimum of three months as per our agreement
        with the New Zealand Ministry of Justice.
      </p>
      <p>
        Three months from the date in which a check was completed, Checkmate will permanently delete
        the identification documents from our server. Checkmate will retain the final check report
        indefinitely. However, this remains the responsibility of Checkmate’s client as the
        employer.
      </p>
      <p>
        You have the right to access the data we store on you. You also have the right to request
        that we delete all information held on you, as long as this does not interfere with existing
        legislation (mentioned above). The request this, please contact our team via email
        <a className="u-link" href="mailto:team@checkmate.tech">
          {' '}
          team@checkmate.tech
        </a>
      </p>
    </div>
  );
};
