import { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { messagePopUp } from 'api/app-slice';
import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { Button } from 'components/FormComponents';
import { FormSectionEdit } from 'components/SectionedForm/FormSectionEdit/FormSectionEdit';
import { FieldTypes } from 'constants/field-types-enum';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCustomFields,
  getCustomFields,
  updateCustomFields,
} from 'api/candidate-custom-fields-slice';

export const CustomFields = ({ setLoading = () => {} }) => {
  const dispatch = useDispatch();

  const customFields = useSelector(selectCustomFields);

  const {
    control,
    register,
    setValue,
    unregister,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    fields,
    append,
    swap,
    remove: removeField,
  } = useFieldArray({ control, name: 'fields', keyName: 'key' });

  // Initialize
  useEffect(() => {
    setLoading(true);
    dispatch(getCustomFields()).then(result => setLoading(false));
  }, []);

  // Update fields
  useEffect(() => {
    reset({ fields: customFields });
  }, [customFields?.length]);

  const onSave = async data => {
    setLoading(true);
    try {
      const { payload: response } = await dispatch(updateCustomFields(data));
      if (response.error) throw new Error(response.error.message);
      dispatch(
        messagePopUp({
          text: 'Custom Fields successfully saved',
          state: MESSAGE_STATE_SUCCESS,
          hide: true,
        }),
      );
    } catch (error) {
      dispatch(messagePopUp({ text: error, state: MESSAGE_STATE_ERROR, hide: true }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let updatedFields = [];
    let order = 0;

    fields.forEach(field => {
      order++;
      updatedFields.push({ ...field, order: order });
    });

    setValue('fields', updatedFields);
  }, [fields.length]);

  const addField = () => {
    append({
      order: fields.length + 1,
      text: '',
      type: FieldTypes.FreeText,
      required: true,
      options: { required: true, options: [{ value: '' }] },
    });
  };

  return (
    <form className={cn('u-flex-box', 'u-flex-justify-center')} onSubmit={handleSubmit(onSave)}>
      <div className={cn('card', 'card-with-border', 'card--medium')}>
        <div className="u-margin--large">
          <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
            <h1 className="title-2">Custom Fields</h1>
          </div>
          <p className="t-small">
            Create and manage custom fields to collect additional information about each candidate.
          </p>
        </div>
        <div className={cn('background-secondary', 'u-padding', 'card_border--top')}>
          <div className={cn('card', 'card-with-border', 'u-padding')}>
            <FormSectionEdit
              fields={fields}
              control={control}
              errors={errors}
              customField={true}
              unregister={unregister}
              watch={watch}
              register={register}
              setValue={setValue}
              swapField={swap}
              addField={addField}
              removeField={removeField}
            />
          </div>
        </div>
        <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-end')}>
          <Button submit>Save</Button>
        </div>
      </div>
    </form>
  );
};
