import { useEffect, useState } from 'react';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import styles from './CadidateRejectedIds.module.scss';
import { CustomButton } from '../components/CustomButton/CustomButton';
import { ID_TYPES } from 'helpers/checks';
import { UploadIdModal } from '../components/UploadIdModal/UploadIdModal';
import { FormMessage, MessageType } from 'components/FormComponents/FormMessage/FormMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCandidateApplicationUploadActions } from 'hooks/upload-actions';
import {
  selectCandidateApplication,
  saveCandidateChecksValues,
} from 'api/candidate-application-slice';

export const CadidateRejectedIds = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = useParams();

  const {
    application: { candidate, brand, uploads, check_values },
  } = useSelector(selectCandidateApplication);

  const { signUpload, createUpload, deleteUpload } = useCandidateApplicationUploadActions(token);

  const [loading, setLoading] = useState(false);

  const [showUploadIdModal, setShowUploadIdModal] = useState(false);
  const [selectedIdType, setSelectedIdType] = useState({});

  const [nonRejectedUploads, setNonRejectedUploads] = useState([]);

  const [rejectedIdTypes, setRejectedIdTypes] = useState([]);

  // Set non rejected uploads
  useEffect(() => {
    setNonRejectedUploads(
      uploads.filter(u => !check_values.rejected_values?.files?.includes(u.id)),
    );
    setRejectedIdTypes(
      uploads
        .filter(u => check_values.rejected_values?.files.includes(u.id))
        .map(u => u.metadata?.id_type),
    );
  }, [uploads.length, check_values.rejected_values]);

  const onIdTypeSelected = idType => {
    setSelectedIdType(idType);
    setShowUploadIdModal(true);
  };

  const onSubmit = async () => {
    setLoading(true);
    await dispatch(
      saveCandidateChecksValues({
        token,
        data: { rejected_fixes: { ...check_values.rejected_fixes, ids: true } },
      }),
    );
    setLoading(false);
    navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
  };

  return (
    <CandidatePage loading={loading} token={token} withTopLogo brand={brand}>
      <CandidatePage.View>
        <UploadIdModal
          brand={brand}
          candidate={candidate}
          selectedIdType={selectedIdType}
          visible={showUploadIdModal}
          uploads={nonRejectedUploads}
          onClose={() => setShowUploadIdModal(false)}
          signUpload={signUpload}
          onUpload={createUpload}
          onDelete={deleteUpload}
        />
        <CandidatePage.Card className={styles.root}>
          <img src="assets/images/icons/id_icon.svg" className={styles.icon} />
          <h1 className="title-4 u-padding-y">Upload your ID</h1>
          {check_values?.rejected_values?.reason && (
            <FormMessage
              className="u-margin-0"
              message={check_values.rejected_values.reason}
              type={MessageType.Error}
            />
          )}
          <FormMessage type={MessageType.Error} message="Please re-upload the following IDs" />

          <div className={styles.idsWrapper}>
            {rejectedIdTypes.map(idOption => {
              const option = ID_TYPES[idOption];

              if (!option) return;

              return (
                <CustomButton
                  key={idOption}
                  small
                  type="secondary"
                  brand={brand}
                  width="300px"
                  onClick={() => onIdTypeSelected(option)}
                  completed={nonRejectedUploads.find(u => u.metadata.id_type === idOption)}>
                  {option.title}
                </CustomButton>
              );
            })}
          </div>

          <CustomButton brand={brand} className="u-margin-top--large" onClick={onSubmit} small>
            Continue
          </CustomButton>
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CadidateRejectedIds;
