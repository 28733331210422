import { useState } from 'react';
import { Toggle } from '..';
import { IconButton } from 'components/IconButton/IconButton';
import styles from './ToggleSwitchInput.module.scss';
import { Collapse } from 'react-bootstrap';
import cn from 'classnames';

export const ToggleSwitchInput = ({
  logo,
  text,
  subtext,
  name,
  value,
  className,
  children,
  onChange = () => {},
}) => {
  const [showContent, setShowContent] = useState(true);

  return (
    <div
      className={cn(styles.root, 'card', 'card-with-border', 'u-width-100', 'u-margin-bottom', {
        [className]: className,
      })}>
      <div className={cn('card_content', 'u-width-100')}>
        <div
          className={cn(
            styles.input,
            'u-width-100',
            'd-flex',
            'align-items-center',
            'justify-content-between',
          )}>
          <div className={cn('d-flex', 'align-items-center')}>
            {logo && <img className={styles.logo} src={logo} />}
            <div>
              <h2 className="title-5">{text}</h2>
              <span className="t-tiny text-secondary">{subtext}</span>
            </div>
          </div>
          <Toggle inputProps={{ name, checked: value, onChange }} />
        </div>
        <Collapse in={children && value} className="u-width-100">
          <div>
            <div className={cn(styles.content)}>{children}</div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};
