export const ID_TYPES = {
  drivers_license: {
    code: 'drivers_license',
    prefix: 'the front of',
    hasPhoto: true,
    complementary_id: {
      code: 'drivers_license_back',
      prefix: 'the back of',
      title: 'Drivers License',
    },
  },
  passport: {
    code: 'passport',
    hasPhoto: true,
  },
  nz_passport: {
    code: 'nz_passport',
    hasPhoto: true,
  },
  au_passport: {
    code: 'au_passport',
    hasPhoto: true,
  },
  foreign_passport: {
    code: 'foreign_passport',
    hasPhoto: true,
    complementary_id: { code: 'visa_document', title: 'Visa document' },
  },
  firearm_license: {
    code: 'firearm_license',
  },
  card_18: {
    code: 'card_18',
    hasPhoto: true,
  },
  birth_certificate: {
    code: 'birth_certificate',
  },
  citizenship_certificate: {
    code: 'citizenship_certificate',
  },
  centrelink_card: {
    code: 'centrelink_card',
  },
  certificate_of_identity: {
    code: 'certificate_of_identity',
  },
  tertiary_student_card: {
    code: 'tertiary_student_card',
  },
  au_photo_id_card: {
    code: 'au_photo_id_card',
    hasPhoto: true,
  },
  government_employee_id: {
    code: 'government_employee_id',
  },
  defense_force_id_card: {
    code: 'defense_force_id_card',
  },
  dva_card: {
    code: 'dva_card',
  },
  id: {
    code: 'id',
    hasPhoto: true,
  },
  white_card: {
    code: 'white_card',
  },
  other: {
    code: 'other',
  },
};

export const RESULT_OPTIONS = {
  File: 'file',
  Link: 'link',
  None: 'none',
};
