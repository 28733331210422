import { Controller } from 'react-hook-form';
import {
  Input,
  Select,
  MultipleSelect,
  DatePicker,
  PhoneNumberPicker,
  InputGroup,
} from 'components/FormComponents';
import { FieldTypes } from 'constants/field-types-enum';
import styles from './CustomFields.module.scss';

export const CustomFields = ({ fields, register, control, watch = () => {} }) => {
  const renderField = field => {
    switch (field.type) {
      case FieldTypes.FreeText:
        return (
          <Input
            name={`custom_fields.${field.id}`}
            register={register}
            validators={{ required: field.required }}
            placeholder="Enter your answer"
            type={field?.options?.type ? field.options.type : 'text'}
          />
        );
      case FieldTypes.MultipleChoice:
        return (
          <Controller
            control={control}
            name={`custom_fields.${field.id}`}
            render={({ field: { onChange, value } }) => (
              <MultipleSelect value={value} onChange={onChange} placeholder="Select...">
                {field.options?.options.map(option => (
                  <MultipleSelect.Item
                    value={option.value}
                    active={watch(`custom_fields.${field.id}`)?.includes(option.value)}>
                    {option.value}
                  </MultipleSelect.Item>
                ))}
              </MultipleSelect>
            )}
          />
        );
      case FieldTypes.YesNo:
        return (
          <Select
            name={`custom_fields.${field.id}`}
            register={register}
            value={watch(`custom_fields.${field.id}`)}
            useDefault>
            <Select.Item value="Yes">Yes</Select.Item>
            <Select.Item value="No">No</Select.Item>
          </Select>
        );
      case FieldTypes.Date:
        return (
          <Controller
            control={control}
            name={`custom_fields.${field.id}`}
            rules={{ required: field.required }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                outputFormat="YYYY-MM-DD"
                value={value}
                onChange={onChange}
                useDefaultToday={!!field.options?.useDefault}
              />
            )}
          />
        );
      case FieldTypes.PhoneNumber:
        return (
          <Controller
            key={field.id}
            control={control}
            name={`custom_fields.${field.id}`}
            rules={{ required: field.required }}
            render={({ field: { onChange, value, ref } }) => (
              <PhoneNumberPicker value={value} className={styles.phone} onChange={onChange} />
            )}
          />
        );
    }
  };

  return (
    <div className="u-width-100">
      {fields?.map(field => (
        <InputGroup title={field.text}>{renderField(field)}</InputGroup>
      ))}
    </div>
  );
};
