import { useState, useEffect } from 'react';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { useLazyGetAdminLogsQuery, useLazyDownloadAdminLogsQuery } from 'api/admin-logs-api';
import { useGetAllCompaniesListQuery } from 'api/company-api';
import { Table } from 'components/Table/Table';
import Moment from 'react-moment';
import { IconButton } from 'components/IconButton/IconButton';
import { Button } from 'components/FormComponents';
import { KeyValueModal } from 'components/KeyValueModal/KeyValueModal';
import styles from './AdminLogs.module.scss';
import { useDocumentTitle } from 'hooks/document-title';

const Actions = [
  { name: 'All', value: 'all' },
  { name: 'User Login', value: 'user.login' },
  { name: 'Check Update', value: 'check.update' },
  { name: 'Check Cancel', value: 'check.cancel' },
  { name: 'Check RESULT', value: 'check.download' },
  { name: 'Check Background', value: 'check.background' },
  { name: 'Check Type Enable', value: 'check.type.enable' },
  { name: 'Check Type Disable', value: 'check.type.disable' },
  { name: 'Check Type Credits', value: 'check.type.credits' },
  { name: 'Company Update', value: 'company.update' },
  { name: 'Candiate Update', value: 'candidate.update' },
  { name: 'Candidate Cancel', value: 'candidate.cancel' },
];

const AdminLogs = () => {
  const [getAdminLogs] = useLazyGetAdminLogsQuery();
  const [downloadAdminLogs] = useLazyDownloadAdminLogsQuery();

  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    action: 'all',
    search: '',
    company: 'all',
    date_from: '',
    date_to: '',
  });

  const [pagination, setPagination] = useState({ page: 1, total_pages: 1 });
  const [showDataModal, setShowDataModal] = useState(false);
  const [logData, setLogData] = useState({});
  const { data: companiesData } = useGetAllCompaniesListQuery();

  useDocumentTitle('Admin Logs');

  useEffect(() => {
    if (!companiesData) return;

    setCompaniesOptions([
      { name: 'All', value: 'all' },
      ...companiesData.result.map(c => ({ name: c.name, value: c.id })),
    ]);
  }, [companiesData]);

  const openLogData = data => {
    setLogData(data);
    setShowDataModal(true);
  };

  const fetchLogs = async (params = {}) => {
    setLoading(true);
    const { result } = await getAdminLogs(params).unwrap();
    setPagination(result.pagination);
    setLogs(result.admin_logs);
    setLoading(false);
    setQueryParams(params);
  };

  const download = async () => {
    setLoading(true);
    await downloadAdminLogs({ queryParams }).unwrap();
    setLoading(false);
  };

  return (
    <AdminPage loading={loading} title="Admin Logs">
      <KeyValueModal
        visible={showDataModal}
        onClose={() => setShowDataModal(false)}
        title="Data"
        data={logData}
      />
      <Table.Context
        onQueryUpdate={fetchLogs}
        defaultQuery={{
          page: 1,
          action: 'all',
          search: '',
          company: 'all',
          date_from: '',
          date_to: '',
        }}>
        <Table.TableContent className="u-margin--large">
          <Table.Filters>
            <Table.LeftFilters>
              <Table.Search placeholder="Search for a Actor..." />
            </Table.LeftFilters>
            <Table.RightFilters>
              <Table.Filter title="Company" filterName="company" options={companiesOptions} />
              <Table.Filter title="Action" filterName="action" options={Actions} />
              <Table.DateFilter title="Date From" filterName="date_from" />
              <Table.DateFilter title="Date To" filterName="date_to" />
              <div className={styles.filters}>
                <Button icon="download" onClick={download}>
                  Download
                </Button>
              </div>
            </Table.RightFilters>
          </Table.Filters>
          <Table className="u-margin--large">
            <Table.Head>
              <Table.Row>
                <Table.Header>Datetime</Table.Header>
                <Table.Header>Company Name</Table.Header>
                <Table.Header>Actor</Table.Header>
                <Table.Header>Entity Type</Table.Header>
                <Table.Header>Action</Table.Header>
                <Table.Header>IP</Table.Header>
                <Table.Header>Details</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {logs.map(adminLog => (
                <Table.Row key={adminLog.id}>
                  <Table.Col>
                    <Moment format="MMM Do @h:mm:ss a">{adminLog.created_at}</Moment>
                  </Table.Col>
                  <Table.Col>{adminLog.company_name}</Table.Col>
                  <Table.Col>{adminLog.user_email}</Table.Col>
                  <Table.Col>{adminLog.entity_type}</Table.Col>
                  <Table.Col>
                    <b>{adminLog.action}</b>
                  </Table.Col>
                  <Table.Col>{adminLog.ip}</Table.Col>
                  <Table.Col>
                    {!Array.isArray(adminLog.data) && (
                      <IconButton icon="file-text" onClick={() => openLogData(adminLog.data)} />
                    )}
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Pagination {...pagination} />
          </Table>
        </Table.TableContent>
      </Table.Context>
    </AdminPage>
  );
};

export default AdminLogs;
