import styles from './NewUserModal.module.scss';
import { AnimatedModal } from '../../../../components/AnimatedModal/AnimatedModal';
import { Input, InputGroup, Button, Select } from 'components/FormComponents';
import { useForm, useWatch } from 'react-hook-form';

export const NewUserModal = ({
  visible,
  roles,
  teams,
  onSubmit = () => {},
  onClose = () => {},
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const teamIdValue = useWatch({ control, name: 'team_id' });
  const roleIdValue = useWatch({ control, name: 'role_id' });

  return (
    <AnimatedModal visible={visible} onClose={onClose} className={styles.root} title="Invite User">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup title="First Name">
          <Input
            name="first_name"
            placeholder="John"
            register={register}
            validators={{ required: true }}
            error={errors?.first_name?.type}
            inputProps={{ tabIndex: 1 }}
          />
        </InputGroup>
        <InputGroup title="Last Name">
          <Input
            name="last_name"
            placeholder="Doe"
            register={register}
            validators={{ required: true }}
            error={errors?.last_name?.type}
            inputProps={{ tabIndex: 2 }}
          />
        </InputGroup>
        <InputGroup title="Email">
          <Input
            name="email"
            placeholder="john@checkmate.tech"
            register={register}
            validators={{ required: true }}
            error={errors?.email?.type}
            inputProps={{ tabIndex: 3 }}
          />
        </InputGroup>
        <InputGroup title="Contact Number">
          <Input
            name="contact_number"
            placeholder="+64 021 026-052"
            register={register}
            inputProps={{ tabIndex: 4 }}
          />
        </InputGroup>
        <InputGroup title="Add to team">
          <Select
            name="team_id"
            placeholder="Product"
            register={register}
            value={teamIdValue}
            useDefault>
            {teams.map(team => (
              <Select.Item key={team.id} value={team.id}>
                {team.name}
              </Select.Item>
            ))}
          </Select>
        </InputGroup>
        <InputGroup title="Add to role">
          <Select
            name="role_id"
            placeholder="Manager"
            register={register}
            value={roleIdValue}
            useDefault>
            {roles.map(role => (
              <Select.Item key={role.id} value={role.id}>
                {role.name}
              </Select.Item>
            ))}
          </Select>
        </InputGroup>
        <Button submit disabled={!isValid} className={styles.button}>
          Invite
        </Button>
      </form>
    </AnimatedModal>
  );
};
