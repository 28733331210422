import { useCallback } from 'react';

export const useAccessControl = (permissions, options = { notPermissionPolicy: 'forbidden' }) =>
  useCallback(
    permissionKey => {
      if (!permissions && options.notPermissionPolicy === 'allowed') return true;

      return permissions.includes(permissionKey);
    },
    [permissions?.length],
  );
