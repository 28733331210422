import styles from './Toggle.module.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const Toggle = ({ name, className, inputProps, disabled = false, register = () => {} }) => {
  return (
    <div className={cn(styles.slider, { [className]: className })}>
      <input disabled={disabled} type="checkbox" {...register(name)} {...inputProps} />
      <span></span>
    </div>
  );
};

Toggle.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  inputProps: PropTypes.object,
};
