import { Button } from 'components/FormComponents';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { BillingAddressForm } from './components/BillingAddressForm/BillingAddressForm';
import styles from './Billing.module.scss';
import cn from 'classnames';
import Moment from 'react-moment';
import { Tabs, Tab } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useLazyGetBillingInfoQuery, useUpdateBillingInfoMutation } from 'api/billing-api';
import { useGetUploadsQuery } from 'api/upload-api';
import { useGetPurchasesQuery } from 'api/purchases-api';
import { useSelector } from 'react-redux';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { selectCompany } from 'api/company-slice';
import { useLocale } from 'hooks/locale';

export const Billing = ({ setLoading = () => {} }) => {
  const [getBillingInfo] = useLazyGetBillingInfoQuery();
  const [updateBillingInfo] = useUpdateBillingInfoMutation();

  const { data: purchases } = useGetPurchasesQuery();
  const { data: uploads } = useGetUploadsQuery({ billing: true });
  const companyChecks = useSelector(selectCompanyChecks);
  const company = useSelector(selectCompany);

  const { dateSlashFormat } = useLocale(company?.locale);

  const [billingInfo, setBillingInfo] = useState({});

  const [showUpdateBillingModal, setShowUpdateBillingModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    getBillingInfo()
      .unwrap()
      .then(({ result }) => {
        setBillingInfo(result);
        setLoading(false);
      });
  }, []);

  const handleUpdateBillingAddress = async billingInfoData => {
    setShowUpdateBillingModal(false);
    setLoading(true);
    const { data: response } = await updateBillingInfo(billingInfoData);
    setBillingInfo(response.result);
    setLoading(false);
  };

  return (
    <div className={cn(styles.root)}>
      <AnimatedModal
        title="Update Billing Information"
        visible={showUpdateBillingModal}
        onClose={() => setShowUpdateBillingModal(false)}>
        <BillingAddressForm
          billingInfo={billingInfo}
          onClose={() => setShowUpdateBillingModal(false)}
          handleUpdate={handleUpdateBillingAddress}
        />
      </AnimatedModal>
      <div className={cn('card--medium', 'card', 'card-with-border')}>
        <div className="u-margin--large d-flex justify-content-between align-items-start">
          <div>
            <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
              <h1 className="title-2">Billing</h1>
            </div>
            <p className="t-small">Review your billing information and history.</p>
          </div>
          <Button onClick={() => setShowUpdateBillingModal(true)}>Update billing info</Button>
        </div>
        <Tabs defaultActiveKey="events" className="tabs padding-small">
          <Tab eventKey="events" title="Events">
            <>
              {purchases?.map(purchase => {
                const companyCheck = companyChecks.find(
                  companyCheck => companyCheck.type === purchase.check_type,
                );
                return (
                  <div className="u-padding-y--small t-small">
                    <Moment format={dateSlashFormat}>{purchase.created_at}</Moment>
                    {purchase.units < 0 ? (
                      <span className="u-margin-left--small">
                        <b>Unlimited</b>{' '}
                        {companyCheck ? companyCheck.check_type.title : purchase.check_type} credits{' '}
                      </span>
                    ) : (
                      <span className="u-margin-left--small">
                        <b>({purchase.units})</b>{' '}
                        {companyCheck ? companyCheck.check_type.title : purchase.check_type} credits
                        purchased by @{purchase.user_name}
                      </span>
                    )}
                  </div>
                );
              })}
              <div className="t-tiny u-padding-y--small">
                * Billing history began on 01/02/2024. Please contact{' '}
                <a className="u-link dark" href="mailto:support@checkmate.tech">
                  support
                </a>{' '}
                if you need details from before
              </div>
            </>
          </Tab>
          <Tab eventKey="reports" title="Billing reports">
            {uploads?.map(upload => (
              <div className="u-padding-y--small t-small">
                <Moment format={dateSlashFormat}>{upload.created_at}</Moment>
                <a target="_blank" className="u-margin-left--small u-link dark" href={upload.url}>
                  {upload.file_name}
                </a>
              </div>
            ))}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
