import { useEffect, useState } from 'react';
import { saveCandidateChecksValues } from 'api/candidate-application-slice';
import { CustomButton } from '../components/CustomButton/CustomButton';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { MenuCard } from './components/MenuCard/MenuCard';
import { SignReviewModal } from './components/SignReviewModal/SignReviewModal';
import styles from './CandidateMenu.module.scss';
import { FormMessage, MessageType } from 'components/FormComponents/FormMessage/FormMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCandidateApplication } from 'api/candidate-application-slice';
import { useTranslation } from 'react-i18next';

const CandidateMenu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { token } = useParams();

  const dispatch = useDispatch();

  const {
    application: { candidate, brand, candidate_checks, forms, check_values },
    ids,
    documentUploads,
    signatureRequired,
    candidatePhoto,
    details,
  } = useSelector(selectCandidateApplication);

  const [loading, setLoading] = useState(false);

  const [showSignModal, setShowSignModal] = useState(false);
  const [readyToSign, setReadyToSign] = useState(false);

  const [checkTypesReady, setCheckTypesReady] = useState(false);

  const [reference, setReference] = useState(null);
  const [customReference, setCustomReference] = useState(null);
  const [acc, setAcc] = useState(null);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [idOrAmlVerification, setIdOrAmlVerification] = useState(null);
  const [elVerification, setElVerification] = useState(null);
  const [documentSigning, setDocumentSigning] = useState(null);
  const [backyCheck, setBackyCheck] = useState(null);
  const [afpCandidatePaysCheck, setAfpCandidatePaysCheck] = useState(null);

  // Check types
  useEffect(() => {
    if (candidate_checks.length < 1) return;

    setReference(candidate_checks.find(c => c.type === 'reference'));
    setCustomReference(candidate_checks.find(c => c.type === 'custom_reference'));
    setQuestionnaire(candidate_checks.find(c => c.type === 'candidate_questionnaire'));
    setAcc(candidate_checks.find(c => c.type === 'acc'));
    setIdOrAmlVerification(
      candidate_checks.find(c => c.type === 'id_verification' || c.type === 'aml_verification'),
    );
    setElVerification(candidate_checks.find(c => c.type === 'el_verification'));
    setBackyCheck(candidate_checks.find(c => c.type === 'afp_backy'));
    setDocumentSigning(candidate_checks.find(c => c.type === 'document_signing'));
    setAfpCandidatePaysCheck(candidate_checks.find(c => c.type === 'afp_candidate_pays'));

    setCheckTypesReady(true);
  }, [candidate_checks.length]);

  useEffect(() => {
    if (!checkTypesReady || !signatureRequired || check_values?.rejected || candidate.submitted_at)
      return;

    setReadyToSign(
      (!reference || reference.submitted_at) &&
        (!customReference || customReference.submitted_at) &&
        (!questionnaire || questionnaire.submitted_at) &&
        (!details || details.completed) &&
        (!ids || ids.completed) &&
        (!idOrAmlVerification || idOrAmlVerification.submitted_at) &&
        (!elVerification || elVerification.submitted_at) &&
        (!acc || acc.submitted_at) &&
        (!afpCandidatePaysCheck || afpCandidatePaysCheck.submitted_at) &&
        (!documentSigning || documentSigning.submitted_at) &&
        (!candidatePhoto || candidatePhoto.completed) &&
        (!documentUploads || documentUploads.valid),
    );
  }, [checkTypesReady]);

  useEffect(() => {
    if (readyToSign) setShowSignModal(true);
  }, [readyToSign]);

  const fixRejections = async () => {
    setLoading(true);
    await dispatch(
      saveCandidateChecksValues({ token, data: { rejected: false, rejected_fixes: null } }),
    );
    setLoading(false);
  };

  return (
    <CandidatePage loading={loading} token={token}>
      <SignReviewModal
        visible={showSignModal}
        brand={brand}
        token={token}
        onClose={() => setShowSignModal(false)}
      />
      <CandidatePage.View>
        <CandidatePage.Card className={styles.card_root}>
          <CandidatePage.Header
            brand={brand}
            candidateName={candidate?.first_name}
            subtitle={!candidate.submitted_at ? <p>{t('candidate.menu.getStarted')}</p> : null}
          />

          {check_values?.rejected && (
            <FormMessage
              message={check_values?.rejected_values?.reason || t('candidate.menu.verifyDetails')}
              type={MessageType.Error}
            />
          )}

          <div className={styles.cardsWrapper}>
            {backyCheck && (
              <MenuCard
                token={token}
                title={t('candidate.menu.australianPoliceCheck')}
                icon="assets/images/icons/police_badge_icon.svg"
                linkTo={CandidateCheckScreens.BACKY_PORTAL}
                completed={backyCheck.submitted_at}
                blockOnComplete
              />
            )}

            {reference && (
              <MenuCard
                token={token}
                title={t('common.yourReferees')}
                icon="assets/images/icons/reference_icon.svg"
                linkTo={CandidateCheckScreens.REFERENCE}
                completed={reference.submitted_at}
                blockOnComplete
              />
            )}

            {questionnaire && (
              <MenuCard
                token={token}
                title={forms.find(form => form.id === questionnaire.details.form_id)?.name}
                icon="assets/images/icons/questionnaire_icon.svg"
                linkTo={CandidateCheckScreens.QUESTIONNAIRE}
                completed={questionnaire.submitted_at}
                blockOnComplete
              />
            )}

            {idOrAmlVerification && (
              <MenuCard
                token={token}
                title={t('candidate.menu.onlineIdentification')}
                icon="assets/images/icons/online_id_icon.svg"
                linkTo={CandidateCheckScreens.ID_VERIFICATION_CHECK}
                completed={idOrAmlVerification.submitted_at}
                blockOnComplete
              />
            )}

            {elVerification && (
              <MenuCard
                token={token}
                title={t('candidate.menu.amlElectronicVerification')}
                icon="assets/images/icons/online_id_icon.svg"
                linkTo={CandidateCheckScreens.EL_VERIFICATION}
                completed={elVerification.submitted_at}
                blockOnComplete
              />
            )}

            {customReference && (
              <MenuCard
                token={token}
                title={t('common.yourReferees')}
                icon="assets/images/icons/reference_icon.svg"
                completed={customReference.submitted_at}
                linkTo={CandidateCheckScreens.CUSTOM_REFERENCE}
                blockOnComplete
              />
            )}

            {documentSigning && (
              <MenuCard
                token={token}
                title={t('common.signDocument')}
                icon="assets/images/icons/signature_icon.svg"
                linkTo={CandidateCheckScreens.DOCUMENT_SIGNING}
                completed={documentSigning.submitted_at}
                blockOnComplete
              />
            )}

            {candidatePhoto && (
              <MenuCard
                token={token}
                title={t('candidate.menu.virtualIdVerification')}
                icon="assets/images/icons/webcam_icon.svg"
                linkTo={CandidateCheckScreens.VIRTUAL_ID}
                completed={candidatePhoto?.completed}
                blockOnComplete
              />
            )}

            {details && (
              <MenuCard
                token={token}
                title={t('common.yourDetails')}
                icon="assets/images/icons/id_icon.svg"
                linkTo={CandidateCheckScreens.DETAILS}
                completed={details.completed}
                rejected={
                  check_values?.rejected && check_values?.rejected_values?.values.length > 0
                }
                fixed={check_values?.rejected && check_values?.rejected_fixes?.values}
              />
            )}

            {ids && !check_values?.rejected && (
              <MenuCard
                token={token}
                title={t('common.uploadId')}
                icon="assets/images/icons/online_id_icon.svg"
                linkTo={CandidateCheckScreens.IDENTITY}
                completed={ids.completed}
              />
            )}
            {check_values?.rejected && check_values?.rejected_values?.files.length > 0 && (
              <MenuCard
                token={token}
                title={t('common.uploadId')}
                icon="assets/images/icons/online_id_icon.svg"
                linkTo={CandidateCheckScreens.REJECTED_IDS}
                rejected={true}
                fixed={check_values?.rejected_fixes?.ids}
              />
            )}

            {documentUploads && (
              <MenuCard
                token={token}
                title={t('common.documents')}
                icon="assets/images/icons/documents.svg"
                linkTo={CandidateCheckScreens.DOCUMENT_UPLOADS}
                completed={documentUploads.completed}
              />
            )}

            {acc && (
              <MenuCard
                token={token}
                title={t('candidate.menu.accReport')}
                icon="https://checkmate-prod.s3.ap-southeast-2.amazonaws.com/assets/acc_logo.svg"
                linkTo={CandidateCheckScreens.ACC}
                completed={acc.submitted_at}
                blockOnComplete
              />
            )}

            {afpCandidatePaysCheck && (
              <MenuCard
                token={token}
                title={t('candidate.menu.afpReport')}
                icon="https://checkmate-prod.s3.ap-southeast-2.amazonaws.com/assets/afp_logo.svg"
                linkTo={CandidateCheckScreens.AFP}
                completed={afpCandidatePaysCheck.submitted_at}
                blockOnComplete
              />
            )}
          </div>

          {!check_values?.rejected && signatureRequired && !candidate.submitted_at && (
            <CustomButton
              brand={brand}
              disabled={!readyToSign}
              icon="edit-3"
              className="u-margin-top--large"
              onClick={() =>
                navigate(`/form_submission/candidate/${CandidateCheckScreens.SIGN}/${token}`)
              }
              small>
              {t('candidate.menu.lastStepReviewAndSign')}
            </CustomButton>
          )}

          {check_values?.rejected && (
            <CustomButton
              brand={brand}
              className="u-margin-top--large"
              onClick={fixRejections}
              small>
              {t('common.submit')}
            </CustomButton>
          )}
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CandidateMenu;
