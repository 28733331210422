import { useRef } from 'react';
import { useClickOutside } from '../../hooks/click-outside';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './ConfirmationModal.module.scss';
import { Button } from 'components/FormComponents/Button/Button';
import { Input, InputGroup } from 'components/FormComponents';
import { useForm } from 'react-hook-form';
import { equals } from 'helpers/form-validators';

export const ConfirmationModal = ({
  visible,
  title,
  subtitle,
  onClose,
  onConfirmation,
  confirmationKey,
  buttonTitle,
  customStyles = {
    backgroundColor: '#FFF',
    titleColor: '#333333',
  },
}) => {
  const wrapperRef = useRef(null);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm();

  useClickOutside(wrapperRef, onClose);

  const validateConfirmationKey = value => {
    if (!confirmationKey) return true;

    return equals(value, confirmationKey);
  };

  return (
    <div className={cn(styles.root, { [styles.rootVisible]: visible })}>
      <div
        className={cn(styles.card, 'card', 'card-with-shadow')}
        ref={wrapperRef}
        style={{ backgroundColor: customStyles.backgroundColor }}>
        <div className={styles.header}>
          <div className={cn(styles.textDiv, 'd-flex flex-column')}>
            <h1 className={cn(styles.title, 'title-3')} style={{ color: customStyles.titleColor }}>
              {title}
            </h1>
            {subtitle && <p className={cn(styles.subTitle, 't-subtitle mt-2')}>{subtitle}</p>}
          </div>
          <form className={cn(styles.form, 'u-width-100')} onSubmit={handleSubmit(onConfirmation)}>
            {confirmationKey && (
              <InputGroup
                className={styles.label}
                title={
                  <p>
                    Confirm by typing <span>{confirmationKey}</span> below.
                  </p>
                }>
                <Input
                  name="key"
                  placeholder={confirmationKey}
                  register={register}
                  validators={{ required: true, validate: { validateConfirmationKey } }}
                  inputProps={{ tabIndex: 1 }}
                  className={styles.input}
                />
              </InputGroup>
            )}
            <div className={cn(styles.buttonDiv, 'u-margin-y--large')}>
              <Button
                onClick={() => {
                  onClose();
                }}
                type="secondary"
                className={styles.closeBtn}>
                Cancel
              </Button>
              <Button submit type="delete" disabled={!isValid} className={styles.confirmBtn}>
                {buttonTitle}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.any,
  confirmationKey: PropTypes.string,
  onClose: PropTypes.func,
  onConfirmation: PropTypes.func,
};
