import { useFieldArray } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Input } from 'components/FormComponents';
import styles from './OptionsConfig.module.scss';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import cn from 'classnames';

export const OptionsConfig = ({ register, name, control }) => {
  const { fields, append, remove, move } = useFieldArray({ control, name: `${name}.options` });

  const addOption = () => {
    append({ value: '' });
  };

  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return;
    move(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="options">
        {({ innerRef, droppableProps, placeholder }) => (
          <div
            className="background-secondary card card-with-border u-padding"
            {...droppableProps}
            ref={innerRef}>
            {fields.map((option, index) => (
              <Option
                key={option.id}
                index={index}
                name={`${name}.options.${index}`}
                register={register}
                option={option}
                onRemove={() => remove(index)}
                onEnter={addOption}
              />
            ))}
            {placeholder}
            <Button type="link" onClick={addOption}>
              + Add another option
            </Button>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Option = ({ name, register, onRemove, option, index, onEnter }) => {
  const handleKeyDown = e => {
    switch (e.code) {
      case 'Enter':
        e.preventDefault();
        onEnter();
        break;
      case 'Backspace':
        if (e.target.value === '') {
          e.preventDefault();
          onRemove();
        }
        break;
    }
  };

  return (
    <Draggable draggableId={option.id} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          className={cn(styles.option, 'd-flex', 'align-items-center', 'margin-top')}
          {...draggableProps}
          {...dragHandleProps}
          ref={innerRef}>
          <FeatherIcon icon="move" className={cn('margin-right--small', styles.icon)} />
          <Input
            register={register}
            name={`${name}.value`}
            className={styles.input}
            placeholder="Enter option..."
            actionIcon="trash-2"
            onActionClick={onRemove}
            inputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
      )}
    </Draggable>
  );
};
