import { useState } from 'react';
import { List } from 'components/List/List';
import { TeamConfig } from './components/TeamConfig/TeamConfig';

export const TeamsTab = ({
  users,
  teams,
  createTeam = () => {},
  updateTeam = () => {},
  setLoading = () => {},
}) => {
  const [team, setTeam] = useState();

  const onCreateTeam = async () => {
    setLoading(true);
    const { payload: newTeam } = await createTeam({ name: 'New Team' });
    setTeam(newTeam);
    setLoading(false);
  };

  const onUpdateTeam = async (id, data) => {
    setLoading(true);
    await updateTeam(id, data);
    setLoading(false);
  };

  return (
    <div className="d-flex">
      <List
        title="Teams"
        subtitle="Create teams and assign users."
        className="u-margin-right--large"
        onNew={onCreateTeam}>
        <List.Category title="Teams">
          {teams.map(team => (
            <List.Item key={team.id} title={team.name} value={team} onClick={setTeam} />
          ))}
        </List.Category>
      </List>
      {team && (
        <TeamConfig
          team={team}
          users={users}
          onClose={() => setTeam(null)}
          onUpdate={onUpdateTeam}
        />
      )}
    </div>
  );
};
