import { useEffect, useState } from 'react';
import styles from './CandidateCheckDetails.module.scss';
import { RowDetails } from 'components/RowDetails/RowDetails';
import { IconButton } from 'components/IconButton/IconButton';
import { Badge } from 'react-bootstrap';
import Moment from 'react-moment';
import cn from 'classnames';
import { now } from 'helpers/date';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { KeyValueModal } from 'components/KeyValueModal/KeyValueModal';
import { CandidateCompletionModal } from '../CandidateCompletionModal/CandidateCompletionModal';
import Alert from 'components/Alert/Alert';
import {
  useUpdateSubCheckMutation,
  useDeleteSubCheckMutation,
  useCreateSubCheckMutation,
} from 'api/sub-checks-api';
import {
  useLazyGetProviderFormQuery,
  useSendToProviderMutation,
  useUpdateCandidateCheckMutation,
  useCancelCandidateCheckMutation,
  useRestoreCandidateCheckMutation,
  useRegenerateSubChecksMutation,
} from 'api/candidate-checks-api';
import { SubCheckCompletionModal } from '../SubCheckCompletionModal/SubCheckCompletionModal';
import { NewSubCheckModal } from '../NewSubCheckModal/NewSubCheckModal';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';

export const CandidateCheckDetails = ({
  candidate,
  check,
  checkType,
  setLoading = () => {},
  addOrUpdateCheck = () => {},
}) => {
  const [getProviderForm] = useLazyGetProviderFormQuery();
  const [sendToProvider] = useSendToProviderMutation();
  const [updateCandidateCheck] = useUpdateCandidateCheckMutation();
  const [cancelCandidateCheck] = useCancelCandidateCheckMutation();
  const [regenerateSubChecks] = useRegenerateSubChecksMutation();
  const [updateSubCheck] = useUpdateSubCheckMutation();
  const [createSubCheck] = useCreateSubCheckMutation();
  const [deleteSubCheck] = useDeleteSubCheckMutation();
  const [restoreCandidateCheck] = useRestoreCandidateCheckMutation();
  const [showClientInputValues, setShowClientInputValues] = useState(false);
  const [showExtraDetails, setShowExtraDetails] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [completionModal, setCompletionModal] = useState(false);
  const [processTooltip, setProcessTooltip] = useState('');
  const [restoreConfirmation, setRestoreConfirmation] = useState(false);
  const [showNewSubCheckModal, setShowNewSubCheckModal] = useState(false);

  // Set check params
  useEffect(() => {
    // Process tooltip
    if (check.bot_message) setProcessTooltip(check.bot_message);
    else if (check.job_at) setProcessTooltip('Waiting on provider');
    else setProcessTooltip('Waiting to be launched');
  }, [check]);

  // Launch process
  const handleCheckProcess = async () => {
    setLoading(true);
    const { data: result } = await sendToProvider(check.id);
    addOrUpdateCheck(result);
    setLoading(false);
  };

  // Set in progress
  const setInProgress = async () => {
    handleUpdate({ job_at: now() });
  };

  // Update check
  const handleUpdate = async data => {
    setLoading(true);
    const { data: result } = await updateCandidateCheck({ id: check.id, data });
    addOrUpdateCheck(result);
    setLoading(false);
  };

  // Download form
  const handleDownloadProviderForm = async () => {
    setLoading(true);
    await getProviderForm({ check, candidate });
    setLoading(false);
  };

  // Cancel
  const handleCancel = async () => {
    setCancelConfirmation(false);
    setLoading(true);
    const { data: result } = await cancelCandidateCheck(check.id);
    addOrUpdateCheck(result);
    setLoading(false);
  };

  // Restore
  const handleRestore = async () => {
    setRestoreConfirmation(false);
    setLoading(true);
    const { data: result } = await restoreCandidateCheck(check.id);
    addOrUpdateCheck(result);
    setLoading(false);
  };

  // Regenerate sub-checks
  const handleRegenerateSubChecks = async () => {
    setLoading(true);
    const { data: result } = await regenerateSubChecks(check.id);
    addOrUpdateCheck(result);
    setLoading(false);
  };

  // Create sub-check
  const handleCreateSubCheck = async subCheckData => {
    setLoading(true);
    setShowNewSubCheckModal(false);
    const { data: newSubCheck } = await createSubCheck(subCheckData);
    addOrUpdateCheck({ ...check, sub_checks: [...check.sub_checks, newSubCheck] });
    setLoading(false);
  };

  return (
    <div
      className={cn('card', 'card-with-border', 'u-width-100', 'u-padding', 'position-relative', {
        [styles.disabled]: check.dummy,
      })}>
      {check.details && (
        <KeyValueModal
          visible={showClientInputValues}
          onClose={() => setShowClientInputValues(false)}
          title="Client Input Values"
          data={check.details}
        />
      )}

      {check.extra_data && (
        <KeyValueModal
          visible={showExtraDetails}
          onClose={() => setShowExtraDetails(false)}
          title="More Details"
          data={check.extra_data}
        />
      )}

      <NewSubCheckModal
        check={check}
        visible={showNewSubCheckModal}
        onCreate={handleCreateSubCheck}
        onClose={() => setShowNewSubCheckModal(false)}
      />

      <Alert
        show={cancelConfirmation}
        title={`Are you sure you want to cancel ${candidate?.first_name}'s ${checkType?.title}?`}
        ok="Yes"
        cancel="No"
        onOk={handleCancel}
        onCancel={() => setCancelConfirmation(false)}
      />

      <Alert
        show={restoreConfirmation}
        title={`Are you sure you want to restore ${candidate?.first_name}'s ${checkType?.title}?`}
        ok="Yes"
        cancel="No"
        onOk={handleRestore}
        onCancel={() => setRestoreConfirmation(false)}
      />

      <CandidateCompletionModal
        check={check}
        checkType={checkType}
        visible={completionModal}
        onUpdate={handleUpdate}
        onClose={() => setCompletionModal(false)}
      />

      {checkType?.is_manual && check.submitted_at && !check.completed_at && (
        <OverlayTrigger placement="top" overlay={<Tooltip>{processTooltip}</Tooltip>}>
          <div
            className={cn(styles.adminState, {
              [styles.pending]: check.job_at,
              [styles.error]: check.bot_message,
            })}></div>
        </OverlayTrigger>
      )}

      <div className={cn('d-flex', 'justify-content-between', 'align-items-center')}>
        <Badge className={`u-status-${check.status}`}>{check.status}</Badge>
        <div className={cn('d-flex')}>
          <IconButton
            prefix="fa"
            className={cn('u-margin-right', {
              [styles.processSuccedd]: check.job_at && !check.completed_at,
              [styles.processFailed]: check.bot_message && !check.completed_at,
            })}
            icon="rocket"
            tip="Launch background process"
            disabled={!checkType?.has_job || check.job_at}
            onClick={handleCheckProcess}
          />
          <IconButton
            prefix="fa"
            className="u-margin-right"
            icon="spinner"
            tip="Set in progress"
            disabled={check.job_at || !check.submitted_at || check.completed_at}
            onClick={setInProgress}
          />
          <IconButton
            prefix="fa"
            className="u-margin-right"
            icon="check"
            tip="Complete"
            disabled={check.cancel || !check.submitted_at}
            onClick={() => setCompletionModal(true)}
          />
          <ActionsMenu>
            <ActionsMenu.Item
              icon="file"
              title="Client input values"
              disabled={!check?.details || check?.details?.length === 0}
              onClick={() => setShowClientInputValues(true)}
            />
            <ActionsMenu.Item
              icon="file-text"
              title="More details"
              disabled={!check?.extra_data || check?.extra_data?.length === 0}
              onClick={() => setShowExtraDetails(true)}
            />
            <ActionsMenu.Item
              icon="x-circle"
              title="Cancel"
              disabled={check.canceled}
              onClick={() => setCancelConfirmation(true)}
            />
            <ActionsMenu.Item
              icon="rotate-ccw"
              title="Restore"
              disabled={!check.canceled}
              onClick={() => setRestoreConfirmation(true)}
            />
            <ActionsMenu.Item
              icon="download"
              title="Download Form"
              disabled={!checkType?.provider_form_class || !check.submitted_at}
              onClick={handleDownloadProviderForm}
            />
            <ActionsMenu.Item
              icon="refresh-cw"
              title="Regenerate sub-checks"
              disabled={!checkType?.has_subchecks}
              onClick={handleRegenerateSubChecks}
            />
            <ActionsMenu.Item
              icon="plus-square"
              title="Add sub-checks"
              disabled={!checkType?.has_subchecks}
              onClick={() => setShowNewSubCheckModal(true)}
            />
          </ActionsMenu>
        </div>
      </div>
      <div className="d-flex align-items-center u-margin-y--small">
        <img src={checkType?.logo} className={cn(styles.logo, 'u-margin-right--small')} />
        <h1 className="title-4">{checkType?.title}</h1>
      </div>
      <div className={cn(styles.candidateCheckRow)}>
        <RowDetails>
          <RowDetails.Column title="ID" data={check?.id} />
          <RowDetails.Column
            title="Created on"
            data={<Moment format="DD/MM/YYYY">{check.created_at}</Moment>}
          />
          <RowDetails.Column
            title="Submitted at"
            data={<Moment format="DD/MM/YYYY">{check.submitted_at}</Moment>}
          />
          <RowDetails.Column
            title="Completed on"
            data={
              check.completed_at ? <Moment format="DD/MM/YYYY">{check.completed_at}</Moment> : '-'
            }
          />
        </RowDetails>
      </div>

      <div>
        {check.sub_checks?.map(subCheck => (
          <SubCheckDetails
            key={subCheck.id}
            subCheck={subCheck}
            check={check}
            addOrUpdateCheck={addOrUpdateCheck}
            updateSubCheck={updateSubCheck}
            deleteSubCheck={deleteSubCheck}
            setLoading={setLoading}
          />
        ))}
      </div>
    </div>
  );
};

const SubCheckDetails = ({
  check,
  subCheck,
  checkType,
  addOrUpdateCheck = () => {},
  updateSubCheck = () => {},
  deleteSubCheck = () => {},
  setLoading = () => {},
}) => {
  const [showCandidateDetails, setShowCandidateDetails] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const setInProgress = () => {
    handleUpdate({ processed_at: now() });
  };

  const handleUpdate = async params => {
    setLoading(true);
    const { data: updatedSubCheck } = await updateSubCheck({ id: subCheck.id, params });
    const subChecks = check.sub_checks.map(subCheck =>
      subCheck.id === updatedSubCheck.id ? updatedSubCheck : subCheck,
    );
    addOrUpdateCheck({ ...check, sub_checks: subChecks });
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    await deleteSubCheck(subCheck.id);
    addOrUpdateCheck({
      ...check,
      sub_checks: check.sub_checks.filter(sc => sc.id !== subCheck.id),
    });
    setLoading(false);
  };

  return (
    <div className="card card-with-border u-padding u-margin-top--large d-flex align-items-center justify-content-between">
      <Alert
        show={showDeleteConfirmationModal}
        title={`Are you sure you want to delete ${subCheck.title}?`}
        ok="Yes"
        cancel="No"
        onOk={handleDelete}
        onCancel={() => setShowDeleteConfirmationModal(false)}
      />
      <KeyValueModal
        visible={showCandidateDetails}
        onClose={() => setShowCandidateDetails(false)}
        title="Client Input Values"
        data={subCheck.details}
      />

      <SubCheckCompletionModal
        check={check}
        subCheck={subCheck}
        checkType={checkType}
        visible={showCompletionModal}
        onUpdate={handleUpdate}
        onClose={() => setShowCompletionModal(false)}
      />

      <div className="d-flex">
        <Badge className={cn(`u-status-${subCheck.status}`, 'u-margin-right')}>
          {subCheck.status}
        </Badge>
        <p className="title-5">{subCheck.title}</p>
      </div>
      <div>
        <IconButton
          className="u-margin-right"
          icon="trash-2"
          tip="Delete"
          small
          onClick={() => setShowDeleteConfirmationModal(true)}
        />
        <IconButton
          prefix="fa"
          className="u-margin-right"
          icon="chalkboard-teacher"
          tip="Candidate input values"
          small
          disabled={check.canceled}
          onClick={() => setShowCandidateDetails(true)}
        />
        <IconButton
          prefix="fa"
          className="u-margin-right"
          icon="spinner"
          tip="Set in progress"
          small
          disabled={subCheck.processed_at || subCheck.canceled || subCheck.completed_at}
          onClick={setInProgress}
        />
        <IconButton
          prefix="fa"
          className="u-margin-right"
          icon="check"
          tip="Complete"
          small
          disabled={subCheck.canceled}
          onClick={() => setShowCompletionModal(true)}
        />
      </div>
    </div>
  );
};
